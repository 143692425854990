import { Component, Host, Prop, h } from '@stencil/core';

import { IgcDockingIndicatorPosition } from '../dockmanager.public-interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-root-docking-indicator-component',
  styleUrl: 'root-docking-indicator-component.scss',
  shadow: false,
  scoped: true
})
export class IgcRootDockingIndicatorComponent {
  @Prop() position: IgcDockingIndicatorPosition;

  render() {
    return (
      <Host part="root-docking-indicator">
        <igc-icon-component
          name="first_page"
          style={{
            transform: this.position === IgcDockingIndicatorPosition.top ? 'rotate(90deg)' :
              this.position === IgcDockingIndicatorPosition.right ? 'rotate(180deg)' :
                this.position === IgcDockingIndicatorPosition.bottom ? 'rotate(270deg)' : 'rotate(0deg)'
          }}
        />
      </Host>
    );
  }
}
