%button-display {
  position: static;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  min-width: 10px;
  padding: 2px 4px;
  border-radius: 2px;
  color: $button-text;
  cursor: pointer;
  outline-style: none;
  font-size: 14px;
  font-family: inherit;
  background: transparent;
  border: none;
  -webkit-tap-highlight-color: transparent;
  opacity: .7;
  transition: background .25s ease-out,
    opacity .25s ease-out,
    box-shadow .25s ease-out;
}

:host([disabled]) {
  pointer-events: none;
}

:host {
  display: flex;
}

button {
  @include ellipsis();
  @extend %button-display;

  &:hover {
    opacity: 1;
  }

  &:focus {
    background: $accent-color;
    box-shadow: inset 0 0 0 1px $active-color;
    opacity: 1;
  }

  &[disabled] {
    opacity: .54;
  }
}

