:host {
  height: 100%;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
  border: none;
  overflow: auto;
}

