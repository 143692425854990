:host {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  font-size: 12px;
  padding: 4px;
  margin-right: 4px;
  color: $pinned-header-text;
  background: $pinned-header-background;
  cursor: pointer;
  outline-style: none;
}

:host([part~='vertical']) {
  writing-mode: tb-rl;
  margin-right: unset;
  margin-bottom: 4px;
}

:host([part~='active']) {
  color: $active-color;
  box-shadow: inset 0 -2px 0 0 $active-color;
}

:host([part~='vertical'][part~='active']) {
  box-shadow: inset 2px 0 0 0 $active-color;
}

:host([part~='disabled']) {
  pointer-events: none;
  color: $disabled-color;
}
