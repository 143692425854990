import { Component, Element, Event, EventEmitter, Fragment, Host, Prop, Watch, h } from '@stencil/core';

import { Utils } from '../../../utils/utils';
import { IGNORE_DRAG, IgcDragEventArguments, IgcDragMoveEventArguments, IgcDragService, IgcDragStartEventArguments } from '../../drag-drop/drag.service';
import { IgcContentPane, IgcDockManagerResourceStrings } from '../dockmanager.public-interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-pane-header-component',
  styleUrl: 'pane-header-component.scss',
  shadow: true,
  scoped: false
})
export class IgcPaneHeaderComponent {
  @Prop({ mutable: true }) dragService: IgcDragService;

  @Element() element: HTMLIgcPaneHeaderComponentElement;

  @Prop() pinned: boolean;
  @Prop() maximized: boolean;
  @Prop() isFloating: boolean;
  @Prop() forcedDrag: boolean;
  @Prop() isFloatingPaneHeader: boolean;
  @Prop() allowClose = true;
  @Prop() allowMaximize = true;
  @Prop() allowPinning = true;
  @Prop() pane: IgcContentPane;
  @Prop() isActive: boolean;
  @Prop() disabled = false;
  @Prop() resourceStrings: IgcDockManagerResourceStrings;

  @Event() pinToggle: EventEmitter;
  @Event({ bubbles: false }) maximize: EventEmitter;
  @Event() close: EventEmitter;
  @Event() dragStarted: EventEmitter<IgcDragStartEventArguments>;
  @Event() dragEnded: EventEmitter<IgcDragEventArguments>;
  @Event() dragMoved: EventEmitter<IgcDragMoveEventArguments>;
  @Event() elementConnected: EventEmitter<HTMLIgcPaneHeaderComponentElement>;
  @Event() elementDisconnected: EventEmitter<HTMLIgcPaneHeaderComponentElement>;

  connectedCallback() {
    this.dragService = new IgcDragService(this.element);
    this.dragService.dragEdgeTolerance = 3;
    this.dragService.dragStart = args => {
      this.dragStarted.emit(args);
    };

    this.dragService.dragEnd = args => {
      this.dragEnded.emit(args);
    };

    this.dragService.dragMove = args => {
      this.dragMoved.emit(args);
    };

    this.forceDragging();
    this.elementConnected.emit(this.element);
  }

  disconnectedCallback() {
    if (this.dragService) {
      this.dragService.destroy();
    }
    this.elementDisconnected.emit(this.element);
  }

  @Watch('forcedDrag')
  forcedDragChanged() {
    this.forceDragging();
  }

  private forceDragging() {
    if (this.forcedDrag) {
      this.dragService.forceDragging();
    }
  }

  private pinButtonClick = () => {
    this.pinToggle.emit();
  }

  private maximizeButtonClick = () => {
    this.maximize.emit();
  }

  private closeButtonClick = () => {
    this.close.emit();
  }

  private renderCloseButton() {
    return (
      <slot name="paneHeaderCloseButton">
        <igc-button-component part="pane-header-close-button">
          <igc-icon-component name="close" aria-label={this.resourceStrings.close} title={this.resourceStrings.close} />
        </igc-button-component>
      </slot>
    );
  }

  private renderMaximizeButton() {
    return (
      <slot name="paneHeaderMaximizeButton">
        <igc-button-component part="pane-header-maximize-button">
          <igc-icon-component
            name="maximize"
            aria-label={this.resourceStrings.maximize}
            title={this.resourceStrings.maximize}
          />
        </igc-button-component>
      </slot>
    );
  }

  private renderMinimizeButton() {
    return (
      <div>
        <slot name="paneHeaderMinimizeButton">
          <igc-button-component part="pane-header-minimize-button">
            <igc-icon-component
              name="minimize"
              aria-label={this.resourceStrings.minimize}
              title={this.resourceStrings.minimize}
            />
          </igc-button-component>
        </slot>
      </div>
    );
  }

  private renderPinButton() {
    return (
      <Fragment>
        {!this.isFloating && <slot name="paneHeaderPinButton">
          <igc-button-component part="pane-header-pin-button" style={{ display: this.isFloating ? 'none' : 'flex' }}>
            <igc-icon-component
              name="pin"
              aria-label={this.resourceStrings.pin}
              title={this.resourceStrings.pin}
            />
          </igc-button-component> </slot>}
      </Fragment>
    );
  }

  private renderUnpinButton() {
    return (
      <Fragment>
        {!this.isFloating && <slot name="paneHeaderUnpinButton">
          <igc-button-component part="pane-header-unpin-button" style={{ display: this.isFloating ? 'none' : 'flex' }}>
            <igc-icon-component
              name="unpin"
              aria-label={this.resourceStrings.unpin}
              title={this.resourceStrings.unpin}
            />
          </igc-button-component>
        </slot>}
      </Fragment>
    );
  }

  render() {
    const commonParts = {
      active: this.isActive,
      disabled: this.disabled,
      floating: this.isFloating,
      window: this.isFloatingPaneHeader,
    };

    const paneHeaderParts = Utils.partNameMap({ 'pane-header': true, ...commonParts });
    const paneHeaderContentParts = Utils.partNameMap({ 'pane-header-content': true, ...commonParts });
    const paneHeaderActionsParts = Utils.partNameMap({ 'pane-header-actions': true, ...commonParts });

    const exportParts = Utils.partNameMap({
      'pane-header': true,
      'pane-header-actions': true,
      'pane-header-content': true,
      'pane-header-close-button': true,
      'pane-header-maximize-button': true,
      'pane-header-minimize-button': true,
      'pane-header-pin-button': true,
      'pane-header-unpin-button': true,
      ...commonParts
    }, ',');

    return (
      <Host
        part={paneHeaderParts}
        exportparts={exportParts}
      >
        <div part={paneHeaderContentParts} class="header-text">
          <slot></slot>
        </div>
        <div part={paneHeaderActionsParts} class="header-actions" {...{ [IGNORE_DRAG]: true }}>
          {this.allowPinning &&
            <div onClick={this.pinButtonClick}>
              {this.pinned ? this.renderUnpinButton() : this.renderPinButton()}
            </div>
          }
          {this.allowMaximize &&
            <div onClick={this.maximizeButtonClick}>
              {this.maximized ? this.renderMinimizeButton() : this.renderMaximizeButton()}
            </div>
          }
          {this.allowClose &&
            <div onClick={this.closeButtonClick}>
              {this.renderCloseButton()}
            </div>
          }
        </div>
      </Host>
    );
  }
}
