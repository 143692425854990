import { IgcSplitPane, IgcSplitPaneOrientation } from '..';
import { PartNameInfo } from '../components/dockmanager/dockmanager.interfaces';
import { IgcDockingIndicatorPosition } from '../components/dockmanager/dockmanager.public-interfaces';

/**
 * @hidden
 */
export class Utils {
  static isDockingIndicatorVertical(position: IgcDockingIndicatorPosition): boolean {
    return position === IgcDockingIndicatorPosition.top ||
      position === IgcDockingIndicatorPosition.bottom ||
      position === IgcDockingIndicatorPosition.outerTop ||
      position === IgcDockingIndicatorPosition.outerBottom;
  }

  static isDockingIndicatorBefore(position: IgcDockingIndicatorPosition): boolean {
    return position === IgcDockingIndicatorPosition.top ||
      position === IgcDockingIndicatorPosition.left ||
      position === IgcDockingIndicatorPosition.outerTop ||
      position === IgcDockingIndicatorPosition.outerLeft;
  }

  static isDockingIndicatorBeforeRTL(position: IgcDockingIndicatorPosition): boolean {
    return position === IgcDockingIndicatorPosition.top ||
      position === IgcDockingIndicatorPosition.right ||
      position === IgcDockingIndicatorPosition.outerTop ||
      position === IgcDockingIndicatorPosition.outerRight;
  }

  static isDockingIndicatorOuter(position: IgcDockingIndicatorPosition): boolean {
    return position === IgcDockingIndicatorPosition.outerLeft ||
      position === IgcDockingIndicatorPosition.outerRight ||
      position === IgcDockingIndicatorPosition.outerTop ||
      position === IgcDockingIndicatorPosition.outerBottom;
  }

  static isSplitPaneVertical(splitPane: IgcSplitPane): boolean {
    return splitPane.orientation === IgcSplitPaneOrientation.vertical;
  }

  static isAltPressed(event: KeyboardEvent): boolean {
    return event.altKey || event.getModifierState('Alt') || event.getModifierState('AltGraph');
  }

  static isControlOrMetaPressed(event: KeyboardEvent): boolean {
    return event.ctrlKey || event.metaKey || event.getModifierState('Meta') || event.getModifierState('OS');
  }

  static partNameMap(partNameInfo: PartNameInfo, delimiter = ' ') {
    return Object.keys(partNameInfo).filter(key => partNameInfo[key]).join(delimiter);
  }

  static getDirection(element: any): string {
    let direction = '';
    if (element.dir !== '') {
      direction = element.dir;
    } else {
      let parent = element.parentElement;
      while (parent) {
        if (parent.dir !== '') {
          direction = parent.dir;
          break;
        }
        parent = parent.parentElement;
      }
    }
    return direction;
  }
}
