import { Component, Host, Prop, h } from '@stencil/core';

import { IGC_DEFAULT_PANE_SIZE } from '../dockmanager.interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-document-host-component',
  styleUrl: 'document-host-component.css',
  shadow: false,
  scoped: true
})
export class IgcDocumentHostComponent {
  @Prop() size: number;

  render() {
    const size = this.size || this.size === 0 ? this.size : IGC_DEFAULT_PANE_SIZE;

    return (
      <Host
        role="group"
        style={{
          flex: `${size} 1 ${size}px`
        }}
      >
      </Host>
    );
  }

}
