:host {
  display: block;
  box-sizing: border-box;
  overflow: auto;
  flex-grow: 1;
  height: 100%;
  color: $pane-content-text;
  background-color: $pane-content-background;
}

:host([part~='disabled']) {
  pointer-events: none;
  color: $disabled-color;
}
