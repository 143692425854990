import { Component, Element, Host, Prop, h } from '@stencil/core';
import { Utils } from '../../../utils/utils';

import { IgcDropTargetPaneInfo } from '../dockmanager.interfaces';
import { IgcDockManagerPaneType, IgcDockingIndicatorPosition } from '../dockmanager.public-interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-joystick-indicator-component',
  styleUrl: 'joystick-indicator-component.scss',
  shadow: false,
  scoped: true
})
export class IgcJoystickIndicatorComponent {
  private isDocHost: boolean;

  @Element() element: HTMLElement;

  @Prop() dropTargetPaneInfo: IgcDropTargetPaneInfo;

  @Prop() documentOnlyDrag: boolean;

  @Prop() allowCenterDock: boolean;

  private isEmptyCenter(position: IgcDockingIndicatorPosition) {
    return this.documentOnlyDrag
      && position === IgcDockingIndicatorPosition.center
      && this.dropTargetPaneInfo.floatingPaneWithoutDocHost;
  }

  private isEmptyEdge(position: IgcDockingIndicatorPosition) {
    return this.documentOnlyDrag
      && position !== IgcDockingIndicatorPosition.center
      && this.dropTargetPaneInfo.pane.type === IgcDockManagerPaneType.documentHost;
  }

  private closestElement(selector: string, el: any): HTMLElement {
    return (
      (el && el.closest(selector)) ||
        this.closestElement(selector, el.getRootNode().host)
    );
  }
 
  private shouldAllowCenterDock() {
    let targetPane = this.dropTargetPaneInfo.pane;
    targetPane = targetPane.type === IgcDockManagerPaneType.tabGroupPane && targetPane.selectedIndex > -1 ? targetPane.panes[targetPane.selectedIndex] : targetPane;
    return targetPane.type === IgcDockManagerPaneType.contentPane && targetPane.acceptsInnerDock !== undefined && this.allowCenterDock
            ? targetPane.acceptsInnerDock
            : this.allowCenterDock;
  }


  private renderBlankIndicator(position: IgcDockingIndicatorPosition) {
    return (
      <igc-joystick-icon-component
        isDocHost={this.isDocHost}
        position={position}
        direction={Utils.getDirection(this.closestElement('igc-dockmanager', this.element))}
        empty
      />);
  }

  private renderIndicator(position: IgcDockingIndicatorPosition) {
    return (
      <igc-joystick-icon-component
        isDocHost={this.isDocHost}
        position={position}
        direction={Utils.getDirection(this.closestElement('igc-dockmanager', this.element))}
        empty={this.isEmptyCenter(position) || this.isEmptyEdge(position)}
      />);
  }

  render() {
    this.isDocHost = !!this.dropTargetPaneInfo.docHost;

    return (
      <Host
        style={{
          top: `${this.dropTargetPaneInfo.targetRect.y}px`,
          left: `${this.dropTargetPaneInfo.targetRect.x}px`,
          width: `${this.dropTargetPaneInfo.targetRect.width}px`,
          height: `${this.dropTargetPaneInfo.targetRect.height}px`,
        }}
      >
        <div
          class={{
            'indicators-container': true,
            'indicators-container--doc-host': this.isDocHost
          }}
        >
          {this.isDocHost && !this.documentOnlyDrag && this.renderIndicator(IgcDockingIndicatorPosition.outerLeft)}

          {this.renderIndicator(IgcDockingIndicatorPosition.left)}

          {this.isDocHost && !this.documentOnlyDrag && this.renderIndicator(IgcDockingIndicatorPosition.outerTop)}

          {this.renderIndicator(IgcDockingIndicatorPosition.top)}

          {!this.shouldAllowCenterDock() && this.renderBlankIndicator(IgcDockingIndicatorPosition.center)}

          {this.shouldAllowCenterDock() && this.renderIndicator(IgcDockingIndicatorPosition.center)}

          {this.renderIndicator(IgcDockingIndicatorPosition.right)}

          {this.isDocHost && !this.documentOnlyDrag && this.renderIndicator(IgcDockingIndicatorPosition.outerRight)}

          {this.renderIndicator(IgcDockingIndicatorPosition.bottom)}

          {this.isDocHost && !this.documentOnlyDrag && this.renderIndicator(IgcDockingIndicatorPosition.outerBottom)}
        </div>
      </Host>
    );
  }
}
