import { Component, Host, Prop, h } from '@stencil/core';

/**
 * @hidden
 */
@Component({
  tag: 'igc-button-component',
  styleUrl: 'button-component.scss',
  shadow: true,
})
export class IgcButtonComponent {
  @Prop() disabled: boolean;
  @Prop({ reflect: true }) name: string;
  @Prop({ reflect: true }) type: string;
  @Prop({ reflect: true }) value: string;

  render() {
    return (
      <Host
        disabled={this.disabled}
      >
        <button
          name={this.name}
          type={this.type}
          value={this.value}
          aria-disabled={this.disabled ? 'true' : null}
        >
          <slot />
        </button>
      </Host>
    );
  }
}
