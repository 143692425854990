import { Component, Element, Event, EventEmitter, Host, Prop, State, h } from '@stencil/core';

import { IgcDragEventArguments, IgcDragMoveEventArguments, IgcDragService, IgcDragStartEventArguments } from '../../drag-drop/drag.service';
import { IgcResizerLocation } from '../dockmanager.public-interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-resizer-component',
  styleUrl: 'resizer-component.scss',
  scoped: true
})
export class IgcResizerComponent {
  private dragService: IgcDragService;

  @Element() element: HTMLElement;

  @State() dragOffsetX: number;
  @State() dragOffsetY: number;

  @Prop() orientation: IgcResizerLocation;

  @Event() resizerMoved: EventEmitter<IgcDragMoveEventArguments>;
  @Event() resizerDragStart: EventEmitter<IgcDragStartEventArguments>;
  @Event() resizerDragEnd: EventEmitter<IgcDragEventArguments>;

  private get isCornerResizer(): boolean {
    return this.orientation === IgcResizerLocation.bottomLeft || this.orientation === IgcResizerLocation.bottomRight ||
      this.orientation === IgcResizerLocation.topLeft || this.orientation === IgcResizerLocation.topRight;
  }

  connectedCallback() {
    this.dragService = new IgcDragService(this.element);
    this.dragService.dragStart = this.dragStart;
    this.dragService.dragEnd = this.dragEnd;
    this.dragService.dragMove = this.dragMove;
  }

  disconnectedCallback() {
    this.dragService.destroy();
  }

  private dragStart = (args: IgcDragStartEventArguments) => {
    this.resizerDragStart.emit(args);
  }

  private dragEnd = (args: IgcDragEventArguments) => {
    this.resizerDragEnd.emit(args);
  }

  private dragMove = (args: IgcDragMoveEventArguments) => {
    this.resizerMoved.emit(args);
  }

  render() {
    return (
      <Host
        style={{
          position: 'absolute',
          pointerEvents: 'all',
          height: (this.orientation === IgcResizerLocation.left || this.orientation === IgcResizerLocation.right) ?
            '100%' : this.isCornerResizer ? '10px' : '5px',
          width: (this.orientation === IgcResizerLocation.left || this.orientation === IgcResizerLocation.right) ? '5px' :
            this.isCornerResizer ? '10px' : '100%',
          cursor: this.orientation === IgcResizerLocation.left || this.orientation === IgcResizerLocation.right ?
            'ew-resize' : (this.orientation === IgcResizerLocation.bottomLeft || this.orientation === IgcResizerLocation.topRight) ?
              'ne-resize' : (this.orientation === IgcResizerLocation.bottomRight || this.orientation === IgcResizerLocation.topLeft) ?
                'nw-resize' : 'ns-resize',
          left: this.orientation === IgcResizerLocation.left || this.orientation === IgcResizerLocation.topLeft ||
            this.orientation === IgcResizerLocation.bottomLeft ? '-3px' : null,
          top: this.orientation === IgcResizerLocation.top || this.orientation === IgcResizerLocation.topLeft ||
            this.orientation === IgcResizerLocation.topRight ? '0px' : null,
          right: this.orientation === IgcResizerLocation.right || this.orientation === IgcResizerLocation.bottomRight ||
            this.orientation === IgcResizerLocation.topRight ? '-3px' : null,
          bottom: this.orientation === IgcResizerLocation.bottom || this.orientation === IgcResizerLocation.bottomRight ||
            this.orientation === IgcResizerLocation.bottomLeft ? '-3px' : null,
          zIndex: '10002'
        }}
      >
      </Host>
    );
  }
}
