import { Component, Element, Event, EventEmitter, Fragment, Host, Prop, h } from '@stencil/core';

import { IgcDragEventArguments, IgcDragMoveEventArguments, IgcDragResizeEventArguments, IgcDragStartEventArguments } from '../../drag-drop/drag.service';
import { IGC_DEFAULT_PANE_SIZE } from '../dockmanager.interfaces';
import { IgcDockManagerPoint, IgcResizerLocation } from '../dockmanager.public-interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-floating-pane-component',
  styleUrl: 'floating-pane-component.scss',
  shadow: true
})
export class IgcFloatingPaneComponent {
  @Prop() allowResize = true;
  @Prop() floatingLocation: IgcDockManagerPoint;
  @Prop() floatingWidth: number;
  @Prop() floatingHeight: number;
  @Prop() hasHeader: boolean;
  @Prop() maximized: boolean;
  @Prop() floatingMinHeight = IGC_DEFAULT_PANE_SIZE;
  @Prop() floatingMinWidth = IGC_DEFAULT_PANE_SIZE;
  @Prop() floatingId: string;

  @Event() wndResizeStart: EventEmitter<IgcDragResizeEventArguments>;
  @Event() wndResizeMove: EventEmitter<IgcDragResizeEventArguments>;
  @Event() wndResizeEnd: EventEmitter<IgcDragResizeEventArguments>;
  @Element() elem: HTMLElement;

  private handleResizerMove(event: CustomEvent<IgcDragMoveEventArguments>) {
    const el = event.target as HTMLIgcResizerComponentElement;
    this.wndResizeMove.emit({
      dragMoveArgs: event.detail,
      resizerLocation: el.orientation
    });

  }
  private handleResizeStart(event: CustomEvent<IgcDragStartEventArguments>) {
    const el = event.target as HTMLIgcResizerComponentElement;
    const args = {
      dragMoveArgs: { ...event.detail, offsetX: 0, offsetY: 0 },
      resizerLocation: el.orientation
    };
    this.wndResizeStart.emit(args);

    if (args.dragMoveArgs.cancel) {
      event.detail.cancel = true;
    }
  }
  private handleResizeEnd(event: CustomEvent<IgcDragEventArguments>) {
    const el = event.target as HTMLIgcResizerComponentElement;
    this.wndResizeEnd.emit({
      dragMoveArgs: event.detail,
      resizerLocation: el.orientation
    });
  }

  render() {
    const styles = {
      position: 'absolute',
      left: this.floatingLocation.x + 'px',
      top: this.floatingLocation.y + 'px',
      minWidth: this.floatingMinWidth + 'px',
      minHeight: this.floatingMinHeight + 'px',
      width: this.floatingWidth + 'px',
      height: this.floatingHeight + 'px'
    };

    return (
      <Host
        style={this.maximized ? {} : styles}
        role="dialog"
        aria-label="dialog"
        part="floating-window"
      >
        {this.allowResize &&
        <Fragment>
          <igc-resizer-component orientation={IgcResizerLocation.left} onResizerDragStart={this.handleResizeStart.bind(this)} onResizerMoved={this.handleResizerMove.bind(this)} onResizerDragEnd={this.handleResizeEnd.bind(this)}/>
          <igc-resizer-component orientation={IgcResizerLocation.top} onResizerDragStart={this.handleResizeStart.bind(this)} onResizerMoved={this.handleResizerMove.bind(this)} onResizerDragEnd={this.handleResizeEnd.bind(this)}/>
          <igc-resizer-component orientation={IgcResizerLocation.right} onResizerDragStart={this.handleResizeStart.bind(this)} onResizerMoved={this.handleResizerMove.bind(this)} onResizerDragEnd={this.handleResizeEnd.bind(this)}/>
          <igc-resizer-component orientation={IgcResizerLocation.bottom} onResizerDragStart={this.handleResizeStart.bind(this)} onResizerMoved={this.handleResizerMove.bind(this)} onResizerDragEnd={this.handleResizeEnd.bind(this)}/>
        </Fragment>}
        <slot name="header">
        </slot>
          <slot></slot>
        {this.allowResize &&
        <Fragment>
          <igc-resizer-component orientation={IgcResizerLocation.topRight} onResizerDragStart={this.handleResizeStart.bind(this)} onResizerMoved={this.handleResizerMove.bind(this)} onResizerDragEnd={this.handleResizeEnd.bind(this)}/>
          <igc-resizer-component orientation={IgcResizerLocation.topLeft} onResizerDragStart={this.handleResizeStart.bind(this)} onResizerMoved={this.handleResizerMove.bind(this)} onResizerDragEnd={this.handleResizeEnd.bind(this)}/>
          <igc-resizer-component orientation={IgcResizerLocation.bottomRight} onResizerDragStart={this.handleResizeStart.bind(this)} onResizerMoved={this.handleResizerMove.bind(this)} onResizerDragEnd={this.handleResizeEnd.bind(this)}/>
          <igc-resizer-component orientation={IgcResizerLocation.bottomLeft} onResizerDragStart={this.handleResizeStart.bind(this)} onResizerMoved={this.handleResizerMove.bind(this)} onResizerDragEnd={this.handleResizeEnd.bind(this)}/>
        </Fragment>}
      </Host>
    );
  }
}
