import { Component, Event, EventEmitter, Host, Prop, h } from '@stencil/core';

import { Utils } from '../../../utils/utils';
import { IGC_DEFAULT_PANE_SIZE, IGC_DEFAULT_UNPIN_PANE_SIZE } from '../dockmanager.interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-content-pane-component',
  styleUrl: 'content-pane-component.scss',
  shadow: true,
  scoped: false
})
export class IgcContentPaneComponent {
  @Prop() size: number;
  @Prop() isFlyout: boolean;
  @Prop() unpinnedSize: number;
  @Prop() header: string;
  @Prop() disabled = false;
  @Prop() isSingleFloating = false;
  @Prop() contentId: string;

  @Event() rendered: EventEmitter<HTMLIgcContentPaneComponentElement>;

  componentDidRender() {
    this.rendered.emit();
  }

  render() {
    const size = this.size || this.size === 0 ? this.size : IGC_DEFAULT_PANE_SIZE;
    const unpinnedSize = this.unpinnedSize ? this.unpinnedSize : IGC_DEFAULT_UNPIN_PANE_SIZE;

    const parts = Utils.partNameMap({
      'content-pane': true,
      [this.contentId]: true,
      disabled: this.disabled,
      'single-floating': this.isSingleFloating
    });
    return (
      <Host
        role="group"
        aria-label={this.header}
        aria-disabled={this.disabled ? 'true' : 'false'}
        tabindex={this.disabled ? -1 : 0}
        style={{
          flex: this.isFlyout ? `0 1 ${unpinnedSize}px` : `${size} 1 ${size}px`
        }}
        part={parts}
      >
        <slot name="header">
        </slot>
        <slot></slot>
      </Host>
    );
  }
}
