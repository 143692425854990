import { Component, Event, EventEmitter, Host, Prop, h } from '@stencil/core';

import { IGC_DEFAULT_PANE_SIZE } from '../dockmanager.interfaces';
import { IgcSplitPaneOrientation } from '../dockmanager.public-interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-split-pane-component',
  styleUrl: 'split-pane-component.scss',
  shadow: false,
  scoped: true
})
export class IgcSplitPaneComponent {
  @Prop() orientation: IgcSplitPaneOrientation;
  @Prop() size: number;
  @Event() rendered: EventEmitter<HTMLIgcSplitPaneComponentElement>;

  componentDidRender() {
    this.rendered.emit();
  }

  render() {
    const size = this.size || this.size === 0 ? this.size : IGC_DEFAULT_PANE_SIZE;

    return (
      <Host
        role="group"
        style={{
          flexDirection: this.orientation === IgcSplitPaneOrientation.horizontal ? 'row' : 'column',
          flex: `${size} 1 ${size}px`
        }}
        part="split-pane"
      />
    );
  }

}
