:host,
:host * {
  box-sizing: border-box;
}

:host {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10002;
}

.pane-navigator {
  display: flex;
  flex-direction: column;
  background: $pane-content-background;
  width: 350px;
  height: auto;
  max-height: 336px;
  overflow: hidden;
  line-height: 24px;
  z-index: 10002;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.08),
              0 24px 38px 3px rgba(0, 0, 0, 0.12),
              0 11px 15px -7px rgba(0, 0, 0, 0.26);

  &:focus {
    outline-style: none;
  }
}

header {
  background: $background-color;
  min-height: 40px;
  height: 40px;

  h3 {
    @include ellipsis();
    margin: unset;
    font-size: em(14px);
    padding: 8px;
  }
}

.body {
  display: flex;
  overflow: auto;
}

.group {
  display: flex;
  flex: 1 0 50%;
  flex-direction: column;
  overflow: hidden;
  padding: 16px;
}

.title {
  @include ellipsis();
  position: sticky;
  top: 0;
  background: $pane-content-background;
  font-size: em(14px);
  margin: unset;
  flex-shrink: 0;
}

.items {
  overflow: auto;
  scrollbar-width: none;

  // This will not work in Firefox
  &::-webkit-scrollbar {
    display: none;
  }
}

[part~='item'] {
  @include ellipsis();
  user-select: none;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 0;

  &[part~='selected'] {
    color: $active-color;
  }

  &[part~='disabled'] {
    pointer-events: none;
    color: $disabled-color;
  }
}
