:host {
  --min-pane-size: 30px;

  display: flex;
  flex-direction: column;
  overflow: visible;
  min-width: var(--min-pane-size);
  min-height: var(--min-pane-size);
  box-sizing: border-box;
  color: $dock-text;
  background: $dock-background;
  pointer-events: all;
  border: 1px solid $floating-pane-border-color;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24);
  z-index: 0;
}
