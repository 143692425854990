:host {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10002;
  pointer-events: none;

  igc-icon-component svg {
    width: 17px;
    height: 17px;
  }

  igc-icon-component {
    margin-left: 8px;
  }
}

[part='menu-content'] {
  position: absolute;
  visibility: hidden;
  color: $context-menu-color;
  background: $context-menu-background;
  border: 1px solid $context-menu-background;
  pointer-events: all;
  box-shadow: 0 5px 22px rgba(0, 0, 0, .08),
  0 12px 17px 2px rgba(0, 0, 0, .12),
  0 7px 8px -4px rgba(0, 0, 0, .26);
  box-sizing: border-box;
}

[part~='menu-item'] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  font-size: em(12px);
  line-height: 1.7;
  cursor: pointer;
  transition: background .25s ease-out, color .25s ease-out;
  outline-style: none;

  &:hover {
    background: $context-menu-background-active;
    color: $context-menu-color-active;
  }

  &:focus {
    background: $context-menu-background-active;
    color: $context-menu-color-active;
  }
}

[part~='disabled'] {
  pointer-events: none;
  color: $disabled-color;
}
