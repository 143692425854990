import { Utils } from '../../utils/utils';
import { IgcDragMoveEventArguments, IgcDragResizeEventArguments } from '../drag-drop/drag.service';

import {
  IGC_DEFAULT_PANE_SIZE, IGC_DEFAULT_UNPIN_PANE_SIZE, IGC_DRAG_FLYOUT_THRESHOLD, IgcContextMenuItem, IgcDockManagerComponentBase,
  IgcPinBehavior, IgcTabRectsInfo
} from './dockmanager.interfaces';
import {
  IgcContentPane, IgcDockManagerPane, IgcDockManagerPaneType, IgcDockManagerPoint, IgcDockingIndicator, IgcDockingIndicatorPosition,
  IgcDocumentHost, IgcFloatPaneAction, IgcFloatingPaneResizeEventArgs, IgcFloatingPaneResizeMoveEventArgs, IgcMoveTabAction, IgcPaneCloseEventArgs, IgcPaneDragActionType,
  IgcPanePinnedEventArgs, IgcResizerLocation, IgcSplitPane, IgcSplitPaneOrientation, IgcTabGroupPane, IgcUnpinnedLocation
} from './dockmanager.public-interfaces';

/**
 * @hidden
 */
export class IgcDockManagerService {
  private paneParentMap: Map<IgcDockManagerPane, IgcDockManagerPane>;
  private initialFloatingPaneLocation: IgcDockManagerPoint;
  private initialFloatingPaneWidth: number;
  private initialFloatingPaneHeight: number;
  private initialTabHeaderClickOffset: IgcDockManagerPoint;
  private initialDragClientPoint: IgcDockManagerPoint;
  private draggedPanes: IgcContentPane[];
  private draggedTab: IgcContentPane;
  private shiftLeftThreshold: number;
  private shiftRightThreshold: number;
  private documentsCache: IgcContentPane[];
  private contentPanesCache: IgcContentPane[];

  visibleDocuments: IgcContentPane[];
  visibleContentPanes: IgcContentPane[];
  documentHosts: IgcDocumentHost[];
  clientContentPanesMap: Map<string, IgcContentPane>;
  dropTargetParentRect: DOMRect;
  dockManagerRect: DOMRect;
  dockingIndicator: IgcDockingIndicator;
  forceDragPane: IgcSplitPane;
  forceDragTabHeader: IgcContentPane;
  unpinnedLocationMap = new Map<IgcContentPane, IgcUnpinnedLocation>();
  keyboardDockPane: IgcContentPane = null;
  proximityDockPosition: IgcDockingIndicatorPosition;

  constructor(public dockManager: IgcDockManagerComponentBase) {

  }

  getContent(contentId: string): IgcDockManagerPane {
    return this.clientContentPanesMap.get(contentId);
  }

  private generateUuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private populatePaneParents(splitPane: IgcSplitPane, isRoot: boolean, isInDocHost: boolean) {
    if (!splitPane) {
      return;
    }

    if (!splitPane.id) {
      splitPane.id = this.generateUuid();
    }

    for (const pane of splitPane.panes) {
      this.paneParentMap.set(pane, splitPane);

      if (!pane.id) {
        pane.id = this.generateUuid();
      }

      if (pane.type === IgcDockManagerPaneType.contentPane && !pane.hidden) {
        this.clientContentPanesMap.set(pane.contentId, pane);
        if (isInDocHost) {
          this.visibleDocuments.push(pane);
        } else {
          this.visibleContentPanes.push(pane);
        }
      } else if (pane.type === IgcDockManagerPaneType.splitPane) {
        this.populatePaneParents(pane, isRoot, isInDocHost);
      } else if (pane.type === IgcDockManagerPaneType.tabGroupPane) {
        for (const cp of pane.panes) {
          if (!cp.id) {
            cp.id = this.generateUuid();
          }
          this.paneParentMap.set(cp, pane);

          if (!cp.hidden) {
            this.clientContentPanesMap.set(cp.contentId, cp);
            if (isInDocHost) {
              this.visibleDocuments.push(cp);
            } else {
              this.visibleContentPanes.push(cp);
            }
          }
        }
      } else if (pane.type === IgcDockManagerPaneType.documentHost) {
        if (isRoot) {
          this.documentHosts.push(pane);
        }
        this.paneParentMap.set(pane.rootPane, pane);
        this.populatePaneParents(pane.rootPane, isRoot, true);
      }
    }
  }

  private populatePinLocations(splitPane: IgcSplitPane) {
    if (!splitPane) {
      return;
    }

    for (const pane of splitPane.panes) {
      if (pane.type === IgcDockManagerPaneType.splitPane) {
        this.populatePinLocations(pane);
      } else if (pane.type === IgcDockManagerPaneType.tabGroupPane) {
        for (const cp of pane.panes) {
          this.addContentPanePinLocation(cp);
          this.paneParentMap.set(cp, pane);
        }
      } else if (pane.type === IgcDockManagerPaneType.contentPane) {
        this.addContentPanePinLocation(pane);
      }
    }
  }

  private resolvePaneUnpinLocation(pane: IgcContentPane): IgcUnpinnedLocation {
    let unpinnedLocation: IgcUnpinnedLocation;

    if (pane.unpinnedLocation) {
      unpinnedLocation = pane.unpinnedLocation;
    } else {
      const documentHost = this.findClosestDocumentHost(pane);
      // TODO Implement an auto-detect algorithm for unpinned location when there is no document host
      unpinnedLocation = documentHost ? this.findPaneUnpinLocation(pane, documentHost) : IgcUnpinnedLocation.left;
    }

    return unpinnedLocation;
  }

  private addContentPanePinLocation(pane: IgcContentPane) {
    if (pane.isPinned === false) {
      const unpinnedLocation = this.resolvePaneUnpinLocation(pane);
      this.unpinnedLocationMap.set(pane, unpinnedLocation);
    }
  }

  private findClosestDocumentHost(pane: IgcContentPane): IgcDocumentHost {
    const panePath = this.getPanePath(pane);
    let maxCommonParents = -1;
    let closestDocHost: IgcDocumentHost;

    for (const docHost of this.documentHosts) {
      const docHostPath = this.getPanePath(docHost);
      let i: number;

      for (i = 0; i < panePath.length; i++) {
        const paneParent = panePath[i];
        const docHostParent = docHostPath[i];

        if (paneParent !== docHostParent) {
          break;
        }
      }

      if (i > maxCommonParents) {
        maxCommonParents = i;
        closestDocHost = docHost;
      }
    }

    return closestDocHost;
  }

  private findPaneUnpinLocation(pane: IgcContentPane, docHost: IgcDocumentHost): IgcUnpinnedLocation {
    const panePath = this.getPanePath(pane);
    const docHostPath = this.getPanePath(docHost);
    let commonParentIndex = -1;

    for (let i = 0; i < panePath.length; i++) {
      const paneParent = panePath[i];
      const docHostParent = docHostPath[i];

      if (paneParent === docHostParent) {
        commonParentIndex = i;
      } else {
        break;
      }
    }

    if (commonParentIndex >= 0) {
      const commonParent = panePath[commonParentIndex] as IgcSplitPane;
      const paneIndex = commonParent.panes.indexOf(panePath[commonParentIndex + 1]);
      const docHostIndex = commonParent.panes.indexOf(docHostPath[commonParentIndex + 1]);

      return commonParent.orientation === IgcSplitPaneOrientation.horizontal ?
        paneIndex < docHostIndex ? IgcUnpinnedLocation.left : IgcUnpinnedLocation.right :
        paneIndex < docHostIndex ? IgcUnpinnedLocation.top : IgcUnpinnedLocation.bottom;
    }

    return IgcUnpinnedLocation.left;
  }

  removePane(pane: IgcDockManagerPane) {
    if (pane === this.dockManager.maximizedPane) {
      this.dockManager.maximizedPane = null;
    }

    const parent = this.paneParentMap.get(pane);

    if (!parent) {
      if (this.dockManager.layout.floatingPanes) {
        const floatingIndex = this.dockManager.layout.floatingPanes.indexOf(pane as IgcSplitPane);
        if (floatingIndex > -1) {
          this.dockManager.layout.floatingPanes.splice(floatingIndex, 1);
        }
      }
      return;
    }

    if (parent.type === IgcDockManagerPaneType.splitPane ||
      parent.type === IgcDockManagerPaneType.tabGroupPane) {
      const index = parent.panes.indexOf(pane as IgcContentPane);
      parent.panes.splice(index, 1);

      if (parent.panes.length === 0) {
        if (parent.allowEmpty) {
          const rootParent = this.getRootParent(pane);

          if (this.isFloatingPane(rootParent)) {
            this.removeFloatingPaneIfEmpty(rootParent);
          }
        } else {
          this.removePane(parent);
        }
      } else if (parent.type === IgcDockManagerPaneType.splitPane) {
        const rootParent = this.getRootParent(parent);
        if (this.isFloatingPane(rootParent)) {
          this.removeFloatingPaneIfEmpty(parent);
        }
      }
    } else if (parent.type === IgcDockManagerPaneType.documentHost) {
      parent.rootPane = null;
      this.removePane(parent);
    }
  }

  private removeFloatingPaneIfEmpty(pane: IgcSplitPane) {
    const childPanes = this.getChildContentPanes(pane);

    if (childPanes.length === 0) {
      this.removePane(pane);
    }
  }

  isFloatingPane(pane: IgcSplitPane) {
    if (!this.dockManager.layout.floatingPanes) {
      return false;
    }
    return this.dockManager.layout.floatingPanes.indexOf(pane) > -1;
  }

  private addFloatingPane(content: IgcDockManagerPane, location: IgcDockManagerPoint, width: number, height: number) {
    const floatingPane: IgcSplitPane = {
      type: IgcDockManagerPaneType.splitPane,
      orientation: IgcSplitPaneOrientation.vertical,
      panes: [content]
    };

    floatingPane.floatingLocation = location;
    floatingPane.floatingWidth = width;
    floatingPane.floatingHeight = height;

    if (!this.dockManager.layout.floatingPanes) {
      this.dockManager.layout.floatingPanes = [];
    }
    this.dockManager.layout.floatingPanes.push(floatingPane);
    this.dockManager.draggedPane = floatingPane;
    this.initialFloatingPaneLocation = floatingPane.floatingLocation;
    this.forceDragPane = floatingPane;
  }

  getChildContentPanes(pane: IgcSplitPane | IgcTabGroupPane): IgcContentPane[] {
    const panes: IgcContentPane[] = [];
    this.getChildContentPanesRecursive(pane, panes);

    return panes;
  }

  private getChildContentPanesRecursive(pane: IgcSplitPane | IgcTabGroupPane, children: IgcContentPane[]) {
    for (const child of pane.panes) {
      if (child.type === IgcDockManagerPaneType.contentPane) {
        children.push(child);
      } else if (child.type === IgcDockManagerPaneType.tabGroupPane ||
        child.type === IgcDockManagerPaneType.splitPane) {
        this.getChildContentPanesRecursive(child, children);
      } else if (child.type === IgcDockManagerPaneType.documentHost) {
        this.getChildContentPanesRecursive(child.rootPane, children);
      }
    }
  }

  getChildDocHostRecursive(pane: IgcSplitPane): IgcDocumentHost {
    for (const child of pane.panes) {
      if (child.type === IgcDockManagerPaneType.documentHost) {
        return child;
      } else if (child.type === IgcDockManagerPaneType.splitPane) {
        const result = this.getChildDocHostRecursive(child);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  getVisibleContentPanes(parent: IgcTabGroupPane) {
    return parent.panes.filter(t => this.isContentPaneVisible(t));
  }

  private getPaneToDock(draggedPane: IgcDockManagerPane) {
    return draggedPane.type === IgcDockManagerPaneType.contentPane ? draggedPane :
      draggedPane.type === IgcDockManagerPaneType.tabGroupPane || draggedPane.type === IgcDockManagerPaneType.splitPane ?
        draggedPane.panes.length === 1 ?
          draggedPane.panes[0] :
          draggedPane :
        draggedPane;
  }

  private removeDocumentHost(pane: IgcSplitPane) {
    const docHost = this.getChildDocHostRecursive(pane);
    if (docHost) {
      const parent = this.getParent(docHost) as IgcSplitPane;
      const index = parent.panes.indexOf(docHost);

      parent.panes[index] = docHost.rootPane.panes.length === 1 ?
        docHost.rootPane.panes[0] :
        docHost.rootPane;
    }
  }

  private dockToCenter(targetPane: IgcDockManagerPane, draggedPane: IgcSplitPane | IgcTabGroupPane | IgcContentPane) {
    let targetContainer: IgcTabGroupPane | IgcSplitPane;

    if (targetPane.type === IgcDockManagerPaneType.tabGroupPane) {
      targetContainer = targetPane;
    } else if (targetPane.type === IgcDockManagerPaneType.contentPane) {
      targetContainer = {
        type: IgcDockManagerPaneType.tabGroupPane,
        size: targetPane.size,
        panes: [targetPane]
      };
      const targetParent = this.paneParentMap.get(targetPane) as IgcSplitPane;
      const index = targetParent.panes.indexOf(targetPane);
      targetParent.panes[index] = targetContainer;
    } else if (targetPane.type === IgcDockManagerPaneType.splitPane) {
      targetContainer = targetPane;
    } else if (targetPane.type === IgcDockManagerPaneType.documentHost) {
      targetContainer = targetPane.rootPane;
    }

    if (targetContainer.type === IgcDockManagerPaneType.tabGroupPane) {
      const panesToDock = (draggedPane.type === IgcDockManagerPaneType.contentPane) ?
        [draggedPane] :
        this.getChildContentPanes(draggedPane);
      targetContainer.panes.push(...panesToDock);
    } else if (targetContainer.type === IgcDockManagerPaneType.splitPane) {
      let paneToDock = draggedPane;

      if (draggedPane?.type === IgcDockManagerPaneType.splitPane && this.dockManager.documentOnlyDrag) {
        this.removeDocumentHost(draggedPane);
        const childPanes = this.getChildContentPanes(draggedPane);
        paneToDock = childPanes.length === 1 ? childPanes[0] : paneToDock;
      }

      targetContainer.panes.push(paneToDock);
    }
  }

  private dockToEdge(targetPane: IgcDockManagerPane, position: IgcDockingIndicatorPosition) {
    const targetParent = this.getParent(targetPane) as IgcSplitPane;
    if (!targetParent) {
      return this.dockToCenter(targetPane, this.dockManager.draggedPane);
    }

    const paneIndex = targetParent.panes.indexOf(targetPane);
    const isIndicatorVertical = Utils.isDockingIndicatorVertical(position);
    const isSplitPaneVertical = Utils.isSplitPaneVertical(targetParent);
    const isRTL = this.dockManager.proximityDock
      ? this.dockManager.direction === 'rtl'
      : this.dockingIndicator && this.dockingIndicator.direction === 'rtl';
    let draggedPane: IgcDockManagerPane = this.dockManager.draggedPane;

    if (draggedPane?.type === IgcDockManagerPaneType.splitPane &&
      this.dockManager.documentOnlyDrag &&
      !this.dockManager.dropTargetPaneInfo.floatingPaneWithoutDocHost) {
      this.removeDocumentHost(draggedPane);
    } else if (draggedPane?.type === IgcDockManagerPaneType.contentPane &&
      this.dockManager.documentOnlyDrag &&
      this.dockManager.dropTargetPaneInfo.floatingPaneWithoutDocHost) {
      draggedPane = {
        type: IgcDockManagerPaneType.documentHost,
        rootPane: {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.horizontal,
          panes: [draggedPane]
        }
      };
    }

    const paneToDock = draggedPane ? this.getPaneToDock(draggedPane) : this.dockManager.activePane;

    if ((isIndicatorVertical && isSplitPaneVertical) || (!isIndicatorVertical && !isSplitPaneVertical)) {
      const insertIndex = Utils.isDockingIndicatorBefore(position) && !isRTL ||
        Utils.isDockingIndicatorBeforeRTL(position) && isRTL ?
        paneIndex :
        paneIndex + 1;
      targetParent.panes.splice(insertIndex, 0, paneToDock);
    } else {
      const newSplitPane: IgcSplitPane = {
        type: IgcDockManagerPaneType.splitPane,
        orientation: isSplitPaneVertical ? IgcSplitPaneOrientation.horizontal : IgcSplitPaneOrientation.vertical,
        panes: Utils.isDockingIndicatorBefore(position) && !isRTL ||
          Utils.isDockingIndicatorBeforeRTL(position) && isRTL ?
          [paneToDock, targetPane] :
          [targetPane, paneToDock],
        size: targetPane.size
      };

      targetPane.size = undefined;
      paneToDock.size = undefined;
      targetParent.panes[paneIndex] = newSplitPane;
    }
  }

  private updateLayout() {
    this.dockManager.layout = { ...this.dockManager.layout };
  }

  private hasMaximizedProp(pane: IgcDockManagerPane): pane is IgcContentPane | IgcSplitPane | IgcTabGroupPane {
    return 'isMaximized' in pane;
  }

  getLastMaximizedPane(): IgcContentPane | IgcSplitPane | IgcTabGroupPane {
    const keys = Array.from(this.paneParentMap.keys());
    const maximizedPane = keys.filter(this.hasMaximizedProp).filter(i => i.isMaximized)[0];
    return maximizedPane;
  }

  processLayout() {
    this.paneParentMap = new Map<IgcDockManagerPane, IgcDockManagerPane>();
    this.unpinnedLocationMap = new Map<IgcContentPane, IgcUnpinnedLocation>();
    this.clientContentPanesMap = new Map<string, IgcContentPane>();
    this.documentHosts = [];
    this.visibleContentPanes = [];
    this.visibleDocuments = [];

    const layout = this.dockManager.layout;

    if (layout) {
      this.populatePaneParents(layout.rootPane, true, false);
      if (layout.floatingPanes) {
        const indicesToDelete = new Map<IgcSplitPane, number>(this.dockManager.floatingPaneZIndicesMap);

        for (let i = 0; i < layout.floatingPanes.length; i++) {
          const pane = layout.floatingPanes[i];
          this.populatePaneParents(pane, false, false);

          if (this.dockManager.floatingPaneZIndicesMap.has(pane)) {
            indicesToDelete.delete(pane);
          } else {
            this.dockManager.floatingPaneZIndicesMap.set(pane, i);
          }
        }

        for (const key of indicesToDelete.keys()) {
          this.dockManager.floatingPaneZIndicesMap.delete(key);
        }
      }
      this.populatePinLocations(layout.rootPane);

      if (this.dockManager.flyoutPane && !this.unpinnedLocationMap.has(this.dockManager.flyoutPane)) {
        this.dockManager.flyoutPane = null;
      }
    }
  }

  getParent(pane: IgcDockManagerPane): IgcDockManagerPane {
    return this.paneParentMap.get(pane);
  }

  getRootParent(pane: IgcDockManagerPane): IgcSplitPane {
    return this.getPanePath(pane)[0] as IgcSplitPane;
  }

  getPanePath(pane: IgcDockManagerPane): IgcDockManagerPane[] {
    const path: IgcDockManagerPane[] = [];
    let currentPane = pane;

    while (currentPane) {
      path.splice(0, 0, currentPane);
      currentPane = this.paneParentMap.get(currentPane);
    }

    return path;
  }

  getDocHostParent(pane: IgcDockManagerPane): IgcDocumentHost {
    let parent = pane;

    do {
      parent = this.paneParentMap.get(parent);
    } while (parent && parent.type !== IgcDockManagerPaneType.documentHost);

    return parent as IgcDocumentHost;
  }

  resizeFlyoutPane(delta: number) {
    const pane = this.dockManager.flyoutPane;
    const location = this.unpinnedLocationMap.get(pane);
    const paneSize = pane.unpinnedSize ? pane.unpinnedSize : IGC_DEFAULT_UNPIN_PANE_SIZE;

    const newSize = location === IgcUnpinnedLocation.left || location === IgcUnpinnedLocation.top ?
      paneSize + delta :
      paneSize - delta;
    pane.unpinnedSize = newSize;

    this.updateLayout();
  }

  resizePane(pane: IgcDockManagerPane, deltaPercentage: number) {
    const parentPane = this.getParent(pane) as IgcSplitPane;
    const childPanes = this.getSplitPaneVisibleChildren(parentPane);
    const paneIndex = childPanes.indexOf(pane);

    const paneSizes = childPanes.map(p => p.size || p.size === 0 ? p.size : IGC_DEFAULT_PANE_SIZE);
    const sizeSum = paneSizes.reduce((p, c) => c + p, 0);
    const calcDelta = deltaPercentage * sizeSum;

    childPanes[paneIndex - 1].size = paneSizes[paneIndex - 1] + calcDelta;
    childPanes[paneIndex].size = paneSizes[paneIndex] - calcDelta;

    this.updateLayout();
  }

  togglePin(pane: IgcContentPane, pinBehavior = IgcPinBehavior.allPanes) {
    const newValue = !this.getActualIsPinned(pane);
    const parent = this.paneParentMap.get(pane);
    const panes = parent.type === IgcDockManagerPaneType.tabGroupPane && pinBehavior === IgcPinBehavior.allPanes ?
      parent.panes.filter(p => p.allowPinning !== false) :
      [pane];
    const location = this.resolvePaneUnpinLocation(pane);

    const args: IgcPanePinnedEventArgs = {
      sourcePane: pane,
      panes,
      newValue,
      location
    };
    const event = this.dockManager.panePinnedToggle.emit(args);

    if (!event.defaultPrevented) {
      for (const cp of args.panes) {
        cp.isPinned = newValue;
      }

      if (this.dockManager.maximizedPane) {
        const targetPanes: (IgcContentPane | IgcTabGroupPane)[] = parent && parent.type === IgcDockManagerPaneType.tabGroupPane && this.dockManager.maximizedPane === parent
          ? [parent]
          : args.panes;

        this.dockManager.maximizedPane = null;

        for (const cp of targetPanes.filter(this.hasMaximizedProp)) {
          cp.isMaximized = false;
        }

        newValue ?
          this.dockManager.flyoutPane = null :
          this.flyoutPane(pane);
      } else if (newValue) {
        this.dockManager.flyoutPane = null;
      }

      this.updateLayout();
    }
  }

  closePane(pane: IgcContentPane) {
    let paneToRemove: IgcDockManagerPane = pane;

    if (pane.type === IgcDockManagerPaneType.contentPane && pane !== this.dockManager.flyoutPane) {
      const parent = this.paneParentMap.get(pane);

      if (parent.type === IgcDockManagerPaneType.tabGroupPane) {
        paneToRemove = parent;
      }
    }

    const paneRemoved = paneToRemove.type === IgcDockManagerPaneType.tabGroupPane ?
      this.removeAllowedPanes(pane, paneToRemove) :
      this.emitPaneClose(pane, [paneToRemove]);

    if ((paneRemoved || pane.hidden) && pane === this.dockManager.flyoutPane) {
      this.dockManager.flyoutPane = null;
    }

    this.updateLayout();
  }

  closeFloatingPane(pane: IgcSplitPane) {
    this.removeAllowedPanes(pane, pane);
    this.updateLayout();
  }

  private emitPaneClose(sourcePane: IgcDockManagerPane, panes: IgcContentPane[]): boolean {
    let paneRemoved = false;
    const args: IgcPaneCloseEventArgs = { sourcePane, panes };
    const event = this.dockManager.paneClose.emit(args);

    if (!event.defaultPrevented) {
      for (const pane of args.panes) {
        this.removePane(pane);
        paneRemoved = true;
      }
    }

    return paneRemoved;
  }

  private removeAllowedPanes(sourcePane: IgcDockManagerPane, pane: IgcSplitPane | IgcTabGroupPane): boolean {
    let paneRemoved = false;

    const panes = this.getChildContentPanes(pane)
        .filter(p => this.getActualAllowClose(p))
        .filter(p => pane.type === IgcDockManagerPaneType.tabGroupPane && sourcePane.type === IgcDockManagerPaneType.contentPane
            ? p.isPinned !== false
            : true);

    paneRemoved = this.emitPaneClose(sourcePane, panes);

    return paneRemoved;
  }

  flyoutPane(pane: IgcContentPane) {
    this.dockManager.flyoutPane = this.dockManager.flyoutPane === pane ? null : pane;
  }

  maximizePane(pane: any) {
    const parent = this.getParent(pane);
    const targetPane = parent && parent.type === IgcDockManagerPaneType.tabGroupPane && pane !== this.dockManager.flyoutPane ? parent : pane;
    this.dockManager.maximizedPane = this.dockManager.maximizedPane === targetPane ? null : targetPane;

    if (this.dockManager.maximizedPane) {
      targetPane.isMaximized = true;
    } else {
      targetPane.isMaximized = false;
    }
  }

  moveFloatingPane(pane: IgcSplitPane, location: IgcDockManagerPoint) {
    pane.floatingLocation = location;
    this.updateLayout();
  }

  resizeFloatingPaneStart(pane: IgcSplitPane, resizerLocation: IgcResizerLocation): boolean {
    const resizeArgs: IgcFloatingPaneResizeEventArgs = {
      sourcePane: pane,
      resizerLocation
    };

    const resizeStartEvent = this.dockManager.floatingPaneResizeStart.emit(resizeArgs);
    if (resizeStartEvent.defaultPrevented) {
      return false;
    }

    this.initialFloatingPaneLocation = pane.floatingLocation ?? { x: 0, y: 0 };
    this.initialFloatingPaneWidth = pane.floatingWidth ?? IGC_DEFAULT_PANE_SIZE;
    this.initialFloatingPaneHeight = pane.floatingHeight ?? IGC_DEFAULT_PANE_SIZE;

    return true;
  }

  resizeFloatingPane(pane: IgcSplitPane, args: IgcDragResizeEventArguments) {
    const currX = pane.floatingLocation ? pane.floatingLocation.x : 0;
    const currY = pane.floatingLocation ? pane.floatingLocation.y : 0;
    const currW = pane.floatingWidth ? pane.floatingWidth : IGC_DEFAULT_PANE_SIZE;
    const currH = pane.floatingHeight ? pane.floatingHeight : IGC_DEFAULT_PANE_SIZE;
    const minW = IGC_DEFAULT_PANE_SIZE;
    const minH = IGC_DEFAULT_PANE_SIZE;
    const initialWidth = this.initialFloatingPaneWidth;
    const initialHeight = this.initialFloatingPaneHeight;
    const initialLocation = this.initialFloatingPaneLocation;

    let totalOffsetY = args.dragMoveArgs.totalOffsetY;
    let totalOffsetX = args.dragMoveArgs.totalOffsetX;
    let newX = currX;
    let newY = currY;
    let newWidth = currW;
    let newHeight = currH;
    const maxHeight = document.documentElement.clientHeight;
    const maxWidth = document.documentElement.clientWidth;

    switch (args.resizerLocation) {
      case (IgcResizerLocation.top):
      case (IgcResizerLocation.topLeft):
      case (IgcResizerLocation.topRight):
        newHeight = initialHeight - totalOffsetY;
        if (newHeight < minH) {
          totalOffsetY = initialHeight - minH;
          newHeight = minH;
        }
        newY = initialLocation.y + totalOffsetY;
        if (newY < 0) {
          newHeight += newY;
          newY = 0;
        } else if (newY >= maxHeight - 1) {
          newY = maxHeight - 1;
        }
        break;
      case (IgcResizerLocation.bottom):
      case (IgcResizerLocation.bottomLeft):
      case (IgcResizerLocation.bottomRight):
        newHeight = initialHeight + totalOffsetY;
        if (newHeight < minH) {
          newHeight = minH;
        }
        if (newY + newHeight > maxHeight) {
          newHeight = maxHeight - newY;
        }
        break;
    }

    switch (args.resizerLocation) {
      case (IgcResizerLocation.left):
      case (IgcResizerLocation.topLeft):
      case (IgcResizerLocation.bottomLeft):
        newWidth = initialWidth - totalOffsetX;
        if (newWidth < minW) {
          totalOffsetX = initialWidth - minW;
          newWidth = minW;
        }
        newX = initialLocation.x + totalOffsetX;
        if (newX < 0) {
          newWidth += newX;
          newX = 0;
        } else if (newX >= maxWidth) {
          newX = maxWidth;
        }
        break;
      case (IgcResizerLocation.right):
      case (IgcResizerLocation.topRight):
      case (IgcResizerLocation.bottomRight):
        newWidth = initialWidth + totalOffsetX;
        if (newWidth < minW) {
          newWidth = minW;
        }
        if (newX + newWidth > maxWidth) {
          newWidth = maxWidth - newX;
        }
        break;
    }

    if (currX === newX && currY === newY && currW === newWidth && currH === newHeight) {
      return;
    }

    const resizingArgs: IgcFloatingPaneResizeMoveEventArgs = {
      sourcePane: pane,
      oldWidth: currW,
      newWidth,
      oldHeight: currH,
      newHeight,
      oldLocation: { x: currX, y: currY },
      newLocation: { x: newX, y: newY },
      resizerLocation: args.resizerLocation
    };

    const resizingEvent = this.dockManager.floatingPaneResizeMove.emit(resizingArgs);
    if (resizingEvent.defaultPrevented) {
      args.dragMoveArgs.cancel = true;
      return;
    } else {
      pane.floatingLocation = resizingArgs.newLocation;
      pane.floatingWidth = resizingArgs.newWidth;
      pane.floatingHeight = resizingArgs.newHeight;
      this.updateLayout();
    }
  }

  resizeFloatingPaneEnd(pane: IgcSplitPane, resizerLocation: IgcResizerLocation) {
    this.dockManager.floatingPaneResizeEnd.emit({
      sourcePane: pane,
      resizerLocation });

    this.dockManager.layoutChange.emit();
  }

  floatPane(pane: IgcContentPane, x: number, y: number, width: number, height: number) {
    let panesToRemove: IgcDockManagerPane[] = [pane];
    let paneToAdd: IgcDockManagerPane = pane;
    const parent = this.paneParentMap.get(pane);

    if (pane !== this.dockManager.flyoutPane && parent.type === IgcDockManagerPaneType.tabGroupPane) {
      if (parent.allowEmpty) {
        const panes = [...parent.panes];
        panesToRemove = panes;
        paneToAdd = parent.panes.length === 1 ?
          pane :
          {
            type: IgcDockManagerPaneType.tabGroupPane,
            panes,
            selectedIndex: parent.selectedIndex
          };
      } else {
        panesToRemove = [parent];
        paneToAdd = parent;
      }
    }

    panesToRemove.forEach(p => this.removePane(p));
    this.addFloatingPane(paneToAdd,
      { x, y },
      width,
      height);

    if (pane === this.dockManager.flyoutPane) {
      pane.isPinned = true;
      this.dockManager.flyoutPane = null;
    }

    this.updateLayout();
  }

  floatTab(pane: IgcContentPane, x: number, y: number, width: number, height: number) {
    this.removePane(pane);

    const isInDocHost = !!this.getDocHostParent(pane);
    let content: IgcDockManagerPane;

    if (isInDocHost && pane.documentOnly) {
      const docHost: IgcDocumentHost = {
        type: IgcDockManagerPaneType.documentHost,
        rootPane: {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.vertical,
          panes: [pane]
        }
      };

      content = docHost;
    } else {
      content = pane;
    }

    this.addFloatingPane(content, { x, y }, width, height);

    this.updateLayout();
  }

  hasFloatingPaneHeader(pane: IgcSplitPane): boolean {
    const panes = this.getSplitPaneVisibleChildren(pane);

    if (panes && panes.length) {
      if (panes.length > 1) {
        return true;
      }

      const childPane = panes[0];

      if (childPane.type === IgcDockManagerPaneType.splitPane) {
        return this.hasFloatingPaneHeader(childPane);
      } else if (childPane.type === IgcDockManagerPaneType.documentHost) {
        return true;
      }
    }

    return false;
  }

  rootDockPane(position: IgcDockingIndicatorPosition) {
    const layout = this.dockManager.layout;
    const rootPane = layout.rootPane;
    const paneToDock = this.dockManager.draggedPane ? this.getPaneToDock(this.dockManager.draggedPane) : this.dockManager.activePane;

    this.removePane(paneToDock);

    const isIndicatorVertical = position === IgcDockingIndicatorPosition.top || position === IgcDockingIndicatorPosition.bottom;
    const isSplitPaneVertical = Utils.isSplitPaneVertical(rootPane);
    const isRTL = this.dockingIndicator && this.dockingIndicator.direction === 'rtl';

    if ((isIndicatorVertical && isSplitPaneVertical) || (!isIndicatorVertical && !isSplitPaneVertical)) {
      const insertIndex = position === IgcDockingIndicatorPosition.left && !isRTL ||
        position === IgcDockingIndicatorPosition.right && isRTL ||
        position === IgcDockingIndicatorPosition.top ?
        0 :
        rootPane.panes.length;
      rootPane.panes.splice(insertIndex, 0, paneToDock);
    } else {
      const newRootPane: IgcSplitPane = {
        type: IgcDockManagerPaneType.splitPane,
        orientation: isSplitPaneVertical ? IgcSplitPaneOrientation.horizontal : IgcSplitPaneOrientation.vertical,
        panes: position === IgcDockingIndicatorPosition.left && !isRTL ||
          position === IgcDockingIndicatorPosition.right && isRTL ||
          position === IgcDockingIndicatorPosition.top ?
          [paneToDock, rootPane] :
          [rootPane, paneToDock],
      };
      rootPane.size = undefined;
      paneToDock.size = undefined;

      layout.rootPane = newRootPane;
    }

    this.updateLayout();
  }

  dockPane(position: IgcDockingIndicatorPosition) {
    const draggedFloatingPane = this.dockManager.draggedPane ?? this.dockManager.activePane;
    const dropTargetPaneInfo = this.dockManager.dropTargetPaneInfo;

    this.removePane(draggedFloatingPane);

    const targetPane = this.dockManager.draggedPane ? dropTargetPaneInfo.pane : this.getParent(this.dockManager.activePane);

    switch (position) {
      case IgcDockingIndicatorPosition.center:
        this.dockToCenter(targetPane, draggedFloatingPane);
        break;
      case IgcDockingIndicatorPosition.left:
      case IgcDockingIndicatorPosition.top:
      case IgcDockingIndicatorPosition.right:
      case IgcDockingIndicatorPosition.bottom:
        this.dockToEdge(targetPane, position);
        break;
      case IgcDockingIndicatorPosition.outerLeft:
      case IgcDockingIndicatorPosition.outerTop:
      case IgcDockingIndicatorPosition.outerRight:
      case IgcDockingIndicatorPosition.outerBottom:
        const docHost = this.dockManager.dropTargetPaneInfo.docHost;
        this.dockToEdge(docHost, position);
        break;
    }

    this.updateLayout();
  }

  getActualIsPinned(pane: IgcContentPane): boolean {
    return pane.isPinned !== false;
  }

  getActualAllowClose(pane: IgcContentPane): boolean {
    return pane.allowClose !== false;
  }

  isContentPaneVisible(pane: IgcContentPane) {
    return pane.isPinned !== false && pane.hidden !== true;
  }

  getSplitPaneVisibleChildren(pane: IgcSplitPane): IgcDockManagerPane[] {
    return pane.panes.filter(p => {
      if (p.type === IgcDockManagerPaneType.contentPane) {
        return this.isContentPaneVisible(p);
      } else if (p.type === IgcDockManagerPaneType.splitPane) {
        return p.allowEmpty || this.getSplitPaneVisibleChildren(p).length;
      } else if (p.type === IgcDockManagerPaneType.tabGroupPane) {
        return p.allowEmpty || p.panes.some(cp => this.isContentPaneVisible(cp));
      } else if (p.type === IgcDockManagerPaneType.documentHost) {
        return p.rootPane.allowEmpty || this.getSplitPaneVisibleChildren(p.rootPane).length;
      }
    });
  }

  closeTabPane(pane: IgcContentPane) {
    this.emitPaneClose(pane, [pane]);
    this.updateLayout();
  }

  bringFloatingPaneOnTop(pane: IgcSplitPane) {
    const floatingPanes = this.dockManager.layout.floatingPanes;
    const floatingPaneZIndicesMap = this.dockManager.floatingPaneZIndicesMap;
    const oldZIndex = floatingPaneZIndicesMap.get(pane);

    for (const p of floatingPanes) {
      const zIndex = floatingPaneZIndicesMap.get(p);

      if (zIndex < oldZIndex) {
        continue;
      } else if (zIndex > oldZIndex) {
        floatingPaneZIndicesMap.set(p, zIndex - 1);
      } else {
        floatingPaneZIndicesMap.set(p, floatingPanes.length - 1);
      }
    }

    this.dockManager.floatingPaneZIndicesMap = new Map<IgcSplitPane, number>(floatingPaneZIndicesMap);
  }

  createContextMenuItems(pane: IgcContentPane): IgcContextMenuItem[] {
    const items: IgcContextMenuItem[] = [];

    if (this.getActualAllowClose(pane)) {
      items.push({ displayText: this.dockManager.resourceStrings.close, iconName: 'close', clickHandler: () => this.closeTabPane(pane) });
    }

    if (pane.allowPinning !== false) {
      items.push({ displayText: this.dockManager.resourceStrings.unpin, iconName: 'unpin', clickHandler: () => this.togglePin(pane, IgcPinBehavior.selectedPane) });
    }

    return items;
  }

  dragPaneStart(pane: IgcSplitPane | IgcContentPane, rect: DOMRect, clientX: number, clientY: number): boolean {
    if (this.dockManager.maximizedPane) {
      return false;
    }

    let panes: IgcContentPane[];
    let parent: IgcDockManagerPane;

    if (pane.type === IgcDockManagerPaneType.contentPane) {
      parent = this.getParent(pane);
      panes = parent.type === IgcDockManagerPaneType.tabGroupPane && pane !== this.dockManager.flyoutPane ?
        parent.panes :
        [pane];
    } else {
      if (this.dockManager.draggedPane === pane) {
        return true;
      }
      panes = this.getChildContentPanes(pane);
    }

    const disableDockingAndFloating = panes.some(p => p.allowDocking === false) && panes.some(p => p.allowFloating === false);

    if (pane.type === IgcDockManagerPaneType.contentPane && disableDockingAndFloating) {
      return false;
    }

    const dragStartEvent = this.dockManager.paneDragStart.emit({ sourcePane: pane, panes });

    if (dragStartEvent.defaultPrevented) {
      return false;
    }

    this.draggedPanes = panes;
    this.initialDragClientPoint = {
      x: clientX,
      y: clientY
    };

    if (pane.type === IgcDockManagerPaneType.splitPane) {
      this.dockManager.draggedPane = pane;
      this.initialFloatingPaneLocation = pane.floatingLocation ?? { x: 0, y: 0 };
    } else {
      if (this.draggedPanes.some(p => p.allowFloating === false)) {
        this.dockManager.draggedPane = panes.length > 1 ? parent as IgcTabGroupPane : pane;
        return true;
      }

      const action: IgcFloatPaneAction = {
        type: IgcPaneDragActionType.floatPane,
        location: { x: rect.x, y: rect.y },
        width: rect.width,
        height: rect.height
      };
      const dragOverEvent = this.dockManager.paneDragOver.emit({
        sourcePane: pane,
        panes,
        action,
        isValid: true
      });

      if (dragOverEvent.defaultPrevented) {
        return false;
      }

      if (!dragOverEvent.detail.isValid) {
        this.dockManager.draggedPane = panes.length > 1 ? parent as IgcTabGroupPane : pane;
        return true;
      }

      this.floatPane(pane,
        rect.x,
        rect.y,
        action.width,
        action.height);

      return false;
    }

    return true;
  }

  private pointInBoundaries(clientX: number, clientY: number, location: IgcDockManagerPoint, pane: IgcSplitPane): IgcDockManagerPoint {
    const minDragPosition = {
      x: this.dockManagerRect.x,
      y: this.dockManagerRect.y
    };
    const maxDragPosition = {
      x: this.dockManagerRect.right - pane.floatingWidth,
      y: this.dockManagerRect.bottom - pane.floatingHeight
    };

    if (this.initialFloatingPaneLocation.x + clientX - this.initialDragClientPoint.x <= this.dockManagerRect.x) {
      location.x = minDragPosition.x;
    } else if (this.initialFloatingPaneLocation.x + clientX + pane.floatingWidth - this.initialDragClientPoint.x >= this.dockManagerRect.right) {
      location.x = maxDragPosition.x;
    }

    if (this.initialFloatingPaneLocation.y + clientY - this.initialDragClientPoint.y <= this.dockManagerRect.y) {
      location.y = minDragPosition.y;
    } else if (this.initialFloatingPaneLocation.y + clientY + pane.floatingHeight - this.initialDragClientPoint.y >= this.dockManagerRect.bottom) {
      location.y = maxDragPosition.y;
    }

    return location;
  }

  dragPaneMove(clientX: number, clientY: number): boolean {
    const draggedPane = this.dockManager.draggedPane;

    if (draggedPane.type === IgcDockManagerPaneType.splitPane) {
      const oldLocation = draggedPane.floatingLocation ? draggedPane.floatingLocation : { x: 0, y: 0 };

      let newLocation: IgcDockManagerPoint;
      
      newLocation = {
        x: this.initialFloatingPaneLocation.x + clientX - this.initialDragClientPoint.x,
        y: this.initialFloatingPaneLocation.y + clientY - this.initialDragClientPoint.y,
      };
      
      if (this.dockManager.containedInBoundaries) {
        newLocation = this.pointInBoundaries(clientX, clientY, newLocation, draggedPane);
      }

      const dragOverEvent = this.dockManager.paneDragOver.emit({
        sourcePane: draggedPane,
        panes: this.draggedPanes,
        action: {
          type: IgcPaneDragActionType.moveFloatingPane,
          oldLocation,
          newLocation
        },
        isValid: true
      });

      if (dragOverEvent.defaultPrevented) {
        return false;
      } else {
        if (dragOverEvent.detail.isValid) {
          this.moveFloatingPane(draggedPane, newLocation);
        }
        this.dockManager.isValidDrop = dragOverEvent.detail.isValid;
      }
    }

    return this.dragOver();
  }

  dragPaneEnd(): boolean {
    const dockingIndicator = this.dockingIndicator;
    const draggedPane = this.dockManager.draggedPane ?? this.draggedTab;
    let docked = false;

    this.dockManager.paneDragEnd.emit({
      sourcePane: draggedPane,
      panes: this.draggedPanes
    });

    if (dockingIndicator && this.dockManager.isValidDrop) {
      if (draggedPane.type === IgcDockManagerPaneType.contentPane) {
        if (draggedPane.isPinned === false) {
          draggedPane.isPinned = true;

          if (draggedPane === this.dockManager.flyoutPane) {
            this.dockManager.flyoutPane = null;
          }
        }
      }

      if (dockingIndicator.isRoot) {
        this.rootDockPane(dockingIndicator.position);
      } else {
        this.dockPane(dockingIndicator.position);
      }

      docked = true;
    } else if (this.dockManager.proximityDock && this.dockManager.dropShadowRect) {
      this.dockPane(this.proximityDockPosition);
      docked = true;
    }

    this.draggedPanes = null;
    this.draggedTab = null;
    this.dockManager.draggedPane = null;
    this.dockManager.dropTargetPaneInfo = null;
    this.dockManager.dropShadowRect = null;
    this.dockManager.isValidDrop = true;

    this.dockManager.layoutChange.emit();

    return docked;
  }

  private emitDragOverEvent(dockingIndicator: any) {
    return this.dockManager.paneDragOver.emit({
      sourcePane: this.dockManager.draggedPane,
      panes: this.draggedPanes,
      action: {
        type: IgcPaneDragActionType.dockPane,
        dockingIndicator: dockingIndicator,
        targetPane: this.dockManager.dropTargetPaneInfo?.pane
      },
      isValid: true
    });
  }

  private dragOver(): boolean {
    let dragOverEvent;

    if (this.dockingIndicator) {
      dragOverEvent = this.emitDragOverEvent(this.dockingIndicator);

      if (dragOverEvent.defaultPrevented) {
        return false;
      } else {
        this.dockManager.isValidDrop = dragOverEvent.detail.isValid;

        this.dockManager.dropShadowRect = this.dockManager.isValidDrop ?
          this.dockingIndicator.isRoot ? this.getDropShadowRectRoot() : this.getDropShadowRect() :
          null;
      }
    } else if (this.dockManager.proximityDock) {
      const disableDocking = this.draggedPanes.some(p => p.allowDocking !== undefined && !p.allowDocking);

      const dockingIndicator = {
        position: this.proximityDockPosition,
        isRoot: false,
        direction: this.dockManager.direction
      };

      dragOverEvent = this.emitDragOverEvent(dockingIndicator);

      if (dragOverEvent.defaultPrevented) {
        return false;
      } else {
        this.dockManager.isValidDrop = dragOverEvent.detail.isValid;

        this.dockManager.dropShadowRect = this.proximityDockPosition && this.dockManager.isValidDrop && !disableDocking
          ? this.getDropShadowRect(this.proximityDockPosition, this.dockManager.direction)
          : null;
      }
    } else {
      this.dockManager.dropShadowRect = null;

      if (this.dockManager.draggedPane.type !== IgcDockManagerPaneType.splitPane) {
        this.dockManager.isValidDrop = false;
      }
    }

    return true;
  }

  dragTabStart(pane: IgcContentPane): boolean {
    this.shiftLeftThreshold = Number.MAX_VALUE;
    this.shiftRightThreshold = 0;

    if (this.dockManager.draggedPane === pane) {
      return true;
    }

    const dragStartEvent = this.dockManager.paneDragStart.emit({
      sourcePane: pane,
      panes: [pane]
    });

    if (dragStartEvent.defaultPrevented) {
      return false;
    }

    this.initialTabHeaderClickOffset = null;
    this.draggedTab = pane;
    this.draggedPanes = [pane];

    return true;
  }

  dragTabMove(pane: IgcContentPane, args: IgcDragMoveEventArguments, rects: IgcTabRectsInfo): boolean {
    const clientX = args.clientX;
    const clientY = args.clientY;
    const offsetX = args.offsetX;
    const totalOffsetX = args.totalOffsetX;
    const totalOffsetY = args.totalOffsetY;

    const headerRect = rects.headerRect;
    const prevHeaderRect = rects.prevHeaderRect;
    const nextHeaderRect = rects.nextHeaderRect;
    const lastVisibleHeaderRect = rects.lastVisibleHeaderRect;
    const tabsRect = rects.tabsRect;

    if (!this.initialTabHeaderClickOffset) {
      const clickOffsetX = clientX - totalOffsetX - headerRect.left;
      const clickOffsetY = clientY - totalOffsetY - headerRect.top;
      this.initialTabHeaderClickOffset = { x: clickOffsetX, y: clickOffsetY };
    }

    if (pane.allowFloating === false
      && (clientX < tabsRect.left || clientX > tabsRect.right || clientY < headerRect.top || clientY > headerRect.bottom)) {
      this.dockManager.draggedPane = pane;
      return this.dragOver();
    }

    this.dockManager.draggedPane = null;
    this.dockManager.isValidDrop = true;

    const paneIndex = (this.getParent(pane) as IgcTabGroupPane).panes.indexOf(pane);

    if (prevHeaderRect
        && clientX < headerRect.left
        && clientX < this.shiftLeftThreshold
        && offsetX < 0) {
      this.shiftLeftThreshold = prevHeaderRect.left;
      const action: IgcMoveTabAction = {
        type: IgcPaneDragActionType.moveTab,
        oldIndex: paneIndex,
        newIndex: paneIndex - 1
      };
      const dragOverEvent = this.dockManager.paneDragOver.emit({
        sourcePane: pane,
        panes: [pane],
        action,
        isValid: true
      });

      if (dragOverEvent.defaultPrevented) {
        return false;
      } else if (!dragOverEvent.detail.isValid) {
        return true;
      }
      this.shiftTabLeft(pane);
    } else if (nextHeaderRect
                && clientX > headerRect.right
                && clientX > this.shiftRightThreshold
                && offsetX > 0) {
      this.shiftRightThreshold = nextHeaderRect.right;
      if (headerRect.top === nextHeaderRect.top) {
        const action: IgcMoveTabAction = {
          type: IgcPaneDragActionType.moveTab,
          oldIndex: paneIndex,
          newIndex: paneIndex + 1
        };
        const dragOverEvent = this.dockManager.paneDragOver.emit({
          sourcePane: pane,
          panes: [pane],
          action,
          isValid: true
        });

        if (dragOverEvent.defaultPrevented) {
          return false;
        } else if (!dragOverEvent.detail.isValid) {
          return true;
        }
        this.shiftTabRight(pane);
      }
    }

    if (Math.abs(totalOffsetY) > IGC_DRAG_FLYOUT_THRESHOLD
        || tabsRect.left - clientX > IGC_DRAG_FLYOUT_THRESHOLD
        || clientX - lastVisibleHeaderRect.right > IGC_DRAG_FLYOUT_THRESHOLD) {
      if (pane.allowFloating !== false && !this.dockManager.maximizedPane) {
        return this.floatTabHeader(pane, clientX , clientY , tabsRect);
      }
    }

    return true;
  }

  private floatTabHeader(pane: IgcContentPane, clientX: number, clientY: number, tabsRect: DOMRect): boolean {
    const rect = new DOMRect(
      clientX - this.initialTabHeaderClickOffset.x,
      clientY - this.initialTabHeaderClickOffset.y,
      tabsRect.width,
      tabsRect.height);

    let panes: IgcContentPane[];
    let parent: IgcDockManagerPane;
    if (pane.type === IgcDockManagerPaneType.contentPane) {
      parent = this.getParent(pane);
      panes = parent.type === IgcDockManagerPaneType.tabGroupPane && pane !== this.dockManager.flyoutPane ?
        parent.panes :
        [pane];
    }

    this.draggedTab = null;

    const action: IgcFloatPaneAction = {
      type: IgcPaneDragActionType.floatPane,
      location: { x: rect.x, y: rect.y },
      width: rect.width,
      height: rect.height
    };
    const dragOverEvent = this.dockManager.paneDragOver.emit({
      sourcePane: pane,
      panes,
      action,
      isValid: true
    });

    if (dragOverEvent.defaultPrevented) {
      return false;
    } else if (!dragOverEvent.detail.isValid) {
      return true;
    }

    this.initialDragClientPoint = {
      x: clientX,
      y: clientY
    };

    this.floatTab(pane,
      rect.x,
      rect.y,
      action.width,
      action.height);

    return false;
  }

  private resolveChildPanesAllowMaximize(pane: IgcSplitPane | IgcTabGroupPane) {
    const panes = this.getChildContentPanes(pane);
    const visiblePanes = panes.filter(p => this.isContentPaneVisible(p));

    return visiblePanes.every(p => p.allowMaximize ?? this.dockManager.allowMaximize);
  }

  normalizeMaximizedPane(pane: IgcContentPane) {
    if (this.dockManager.maximizedPane) {
      const panes =
        this.dockManager.maximizedPane.type === IgcDockManagerPaneType.splitPane || this.dockManager.maximizedPane.type === IgcDockManagerPaneType.tabGroupPane
          ? this.getChildContentPanes(this.dockManager.maximizedPane)
          : [this.dockManager.maximizedPane];

      if (panes.every(p => p !== pane)) {
        this.maximizePane(this.dockManager.maximizedPane);
      }
    }
  }

  resolveAllowMaximize(pane: IgcDockManagerPane) {
    if (pane.type === IgcDockManagerPaneType.splitPane || pane.type === IgcDockManagerPaneType.tabGroupPane) {
      return this.resolveChildPanesAllowMaximize(pane);
    } else if (pane.type === IgcDockManagerPaneType.contentPane) {
      if (!this.getActualIsPinned(pane)) {
        return pane.allowMaximize ?? this.dockManager.allowMaximize;
      }

      const parent = this.getParent(pane);
      return parent.type === IgcDockManagerPaneType.tabGroupPane
        ? this.resolveChildPanesAllowMaximize(parent)
        : pane.allowMaximize ?? this.dockManager.allowMaximize;
    }
  }

  getDropShadowRect(dockingPosition?: IgcDockingIndicatorPosition, dockingDirection?: string) {
    const shadowRect = new DOMRect();
    dockingPosition = dockingPosition ?? this.dockingIndicator.position;
    dockingDirection = dockingDirection ?? this.dockingIndicator.direction;

    const parentRect = this.dropTargetParentRect;
    const dropTargetPaneInfo = this.dockManager.dropTargetPaneInfo;
    if (dropTargetPaneInfo && !parentRect) {
      const targetRect = dropTargetPaneInfo.targetRect;
      shadowRect.x = targetRect.x;
      shadowRect.y = targetRect.y;
      shadowRect.width = targetRect.width;
      shadowRect.height = targetRect.height;
      return shadowRect;
    } else if (!dropTargetPaneInfo || !parentRect) {
      return;
    }

    const draggedPaneSize = this.getPaneToDock(this.dockManager.draggedPane).size ?? IGC_DEFAULT_PANE_SIZE;
    const isOuter = Utils.isDockingIndicatorOuter(dockingPosition);
    const baseRect = isOuter ? parentRect : dropTargetPaneInfo.targetRect;

    if (dockingPosition === IgcDockingIndicatorPosition.center) {
      shadowRect.x = baseRect.x;
      shadowRect.y = baseRect.y;
      shadowRect.width = baseRect.width;
      shadowRect.height = baseRect.height;
      return shadowRect;
    }

    const targetPane = isOuter ? dropTargetPaneInfo.docHost : dropTargetPaneInfo.pane;
    const targetParent = this.getParent(targetPane) as IgcSplitPane;
    const panes = this.getSplitPaneVisibleChildren(targetParent);
    const draggedPaneIndex = panes.indexOf(this.dockManager.draggedPane);

    if (draggedPaneIndex > -1) {
      panes.splice(draggedPaneIndex, 1);
    }
    const dropTargetIndex = panes.indexOf(targetPane);

    const isIndicatorVertical = Utils.isDockingIndicatorVertical(dockingPosition);
    const isSplitPaneVertical = Utils.isSplitPaneVertical(targetParent);
    const isSameSplitPane = ((isIndicatorVertical && isSplitPaneVertical) || (!isIndicatorVertical && !isSplitPaneVertical));
    const isRTL = dockingDirection === 'rtl';

    const panesTotalSize = panes.reduce((a, b) => a + (b.size || IGC_DEFAULT_PANE_SIZE), 0);
    const beforePanesTotalSize = !isRTL || isIndicatorVertical
      ? panes.slice(0, dropTargetIndex).reduce((a, b) => a + (b.size || IGC_DEFAULT_PANE_SIZE), 0)
      : panes.slice(dropTargetIndex, panes.length).reduce((a, b) => a + (b.size || IGC_DEFAULT_PANE_SIZE), 0);
    const afterPanesTotalSize = !isRTL || isIndicatorVertical
      ? panes.slice(0, dropTargetIndex + 1).reduce((a, b) => a + (b.size || IGC_DEFAULT_PANE_SIZE), 0)
      : panes.slice(dropTargetIndex + 1, panes.length).reduce((a, b) => a + (b.size || IGC_DEFAULT_PANE_SIZE), 0);

    switch (dockingPosition) {
      case IgcDockingIndicatorPosition.left:
      case IgcDockingIndicatorPosition.outerLeft:
        shadowRect.x = baseRect.x;
        shadowRect.y = baseRect.y;
        shadowRect.height = baseRect.height;
        if (isSameSplitPane) {
          shadowRect.width = (draggedPaneSize / (draggedPaneSize + panesTotalSize)) * parentRect.width;
          !isRTL
            ? shadowRect.x = parentRect.left + beforePanesTotalSize / (draggedPaneSize + panesTotalSize) * parentRect.width
            : shadowRect.x = parentRect.left + afterPanesTotalSize / (draggedPaneSize + panesTotalSize) * parentRect.width;
        } else {
          shadowRect.width = parentRect.width / 2;
        }
        break;
      case IgcDockingIndicatorPosition.right:
      case IgcDockingIndicatorPosition.outerRight:
        shadowRect.y = baseRect.y;
        shadowRect.height = baseRect.height;
        if (isSameSplitPane) {
          shadowRect.width = (draggedPaneSize / (draggedPaneSize + panesTotalSize)) * parentRect.width;
          !isRTL
            ? shadowRect.x = parentRect.left + afterPanesTotalSize / (draggedPaneSize + panesTotalSize) * parentRect.width
            : shadowRect.x = parentRect.left + beforePanesTotalSize / (draggedPaneSize + panesTotalSize) * parentRect.width;
        } else {
          shadowRect.width = parentRect.width / 2;
          shadowRect.x = parentRect.right - shadowRect.width;
        }
        break;
      case IgcDockingIndicatorPosition.top:
      case IgcDockingIndicatorPosition.outerTop:
        shadowRect.x = baseRect.x;
        shadowRect.y = baseRect.y;
        shadowRect.width = baseRect.width;
        if (isSameSplitPane) {
          shadowRect.height = (draggedPaneSize / (draggedPaneSize + panesTotalSize)) * parentRect.height;
          shadowRect.y = parentRect.top + beforePanesTotalSize / (draggedPaneSize + panesTotalSize) * parentRect.height;
        } else {
          shadowRect.height = parentRect.height / 2;
        }
        break;
      case IgcDockingIndicatorPosition.bottom:
      case IgcDockingIndicatorPosition.outerBottom:
        shadowRect.x = baseRect.x;
        shadowRect.width = baseRect.width;
        if (isSameSplitPane) {
          shadowRect.height = (draggedPaneSize / (draggedPaneSize + panesTotalSize)) * parentRect.height;
          shadowRect.y = parentRect.top + afterPanesTotalSize / (draggedPaneSize + panesTotalSize) * parentRect.height;
        } else {
          shadowRect.height = parentRect.height / 2;
          shadowRect.y = parentRect.bottom - shadowRect.height;
        }
        break;
    }

    return shadowRect;
  }

  getDropShadowRectRoot() {
    const rootRect = this.dropTargetParentRect;
    const shadowRect = new DOMRect();
    const draggedPaneSize = this.getPaneToDock(this.dockManager.draggedPane).size ?? IGC_DEFAULT_PANE_SIZE;
    const panes = this.getSplitPaneVisibleChildren(this.dockManager.layout.rootPane);

    const draggedPaneIndex = panes.indexOf(this.dockManager.draggedPane);

    if (draggedPaneIndex > -1) {
      panes.splice(draggedPaneIndex, 1);
    }

    const panesTotalSize = panes.reduce((a, b) => a + (b.size || IGC_DEFAULT_PANE_SIZE), 0);

    const isIndicatorVertical = Utils.isDockingIndicatorVertical(this.dockingIndicator.position);
    const isSplitPaneVertical = Utils.isSplitPaneVertical(this.dockManager.layout.rootPane);
    const isSameSplitPane = (isIndicatorVertical && isSplitPaneVertical) || (!isIndicatorVertical && !isSplitPaneVertical);
    const isEmptyDockManager = panes.length === 0;

    switch (this.dockingIndicator.position) {
      case IgcDockingIndicatorPosition.left:
        shadowRect.x = rootRect.x;
        shadowRect.y = rootRect.y;
        shadowRect.height = rootRect.height;
        shadowRect.width = isSameSplitPane || isEmptyDockManager ? (draggedPaneSize / (draggedPaneSize + panesTotalSize) * rootRect.width) : (rootRect.width / 2);
        break;
      case IgcDockingIndicatorPosition.right:
        shadowRect.y = rootRect.y;
        shadowRect.height = rootRect.height;
        shadowRect.width = isSameSplitPane || isEmptyDockManager ? (shadowRect.width = draggedPaneSize / (draggedPaneSize + panesTotalSize) * rootRect.width) : (rootRect.width / 2);
        shadowRect.x = rootRect.right - shadowRect.width;
        break;
      case IgcDockingIndicatorPosition.top:
        shadowRect.x = rootRect.x;
        shadowRect.y = rootRect.y;
        shadowRect.width = rootRect.width;
        shadowRect.height = isSameSplitPane || isEmptyDockManager ? (draggedPaneSize / (draggedPaneSize + panesTotalSize) * rootRect.height) : (rootRect.height / 2);
        break;
      case IgcDockingIndicatorPosition.bottom:
        shadowRect.x = rootRect.x;
        shadowRect.width = rootRect.width;
        shadowRect.height = isSameSplitPane || isEmptyDockManager ? (draggedPaneSize / (draggedPaneSize + panesTotalSize) * rootRect.height) : (rootRect.height / 2);
        shadowRect.y = rootRect.bottom - shadowRect.height;
        break;
    }

    return shadowRect;
  }

  selectHiddenTab(tabGroup: IgcTabGroupPane, pane: IgcContentPane) {
    const index = tabGroup.panes.indexOf(pane);
    tabGroup.panes.splice(index, 1);
    tabGroup.panes.splice(0, 0, pane);
    tabGroup.selectedIndex = 0;
    this.updateLayout();
  }

  shiftTabLeft(pane: IgcContentPane) {
    const tabGroup = this.getParent(pane) as IgcTabGroupPane;
    const draggedIndex = tabGroup.panes.indexOf(pane);

    if (draggedIndex > 0) {
      tabGroup.panes.splice(draggedIndex, 1);
      tabGroup.panes.splice(draggedIndex - 1, 0, pane);
      tabGroup.selectedIndex = draggedIndex - 1;
      this.updateLayout();
    }

    this.forceDragTabHeader = pane;
  }

  shiftTabRight(pane: IgcContentPane) {
    const tabGroup = this.getParent(pane) as IgcTabGroupPane;
    const draggedIndex = tabGroup.panes.indexOf(pane);

    if (draggedIndex < tabGroup.panes.length - 1) {
      tabGroup.panes.splice(draggedIndex, 1);
      tabGroup.panes.splice(draggedIndex + 1, 0, pane);
      tabGroup.selectedIndex = draggedIndex + 1;
      this.updateLayout();
    }

    this.forceDragTabHeader = pane;
  }

  cacheDocumentsOrder() {
    this.documentsCache = this.visibleDocuments.filter(c => !c.disabled);
  }

  cacheContentPanesOrder() {
    this.contentPanesCache = this.visibleContentPanes.filter(c => !c.disabled);
  }

  focusPrevContentPane(isInDocHost: boolean) {
    const contentPanesCache = isInDocHost ? this.documentsCache : this.contentPanesCache;
    const activePaneIndex = contentPanesCache.indexOf(this.dockManager.activePane);
    const prevPane = activePaneIndex > 0 ?
      contentPanesCache[activePaneIndex - 1] :
      contentPanesCache[contentPanesCache.length - 1];

    this.normalizeMaximizedPane(prevPane);
    this.dockManager.activePane = prevPane;
  }

  focusNextContentPane(isInDocHost: boolean) {
    const contentPanesCache = isInDocHost ? this.documentsCache : this.contentPanesCache;
    const activePaneIndex = contentPanesCache.indexOf(this.dockManager.activePane);
    const nextPane = (activePaneIndex >= 0 && activePaneIndex < contentPanesCache.length - 1) ?
      contentPanesCache[activePaneIndex + 1] :
      contentPanesCache[0];

    this.normalizeMaximizedPane(nextPane);
    this.dockManager.activePane = nextPane;
  }
}
