import { Component, Host, Prop, h } from '@stencil/core';

import { Utils } from '../../../utils/utils';
import { IgcUnpinnedLocation } from '../dockmanager.public-interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-unpinned-pane-header-component',
  styleUrl: 'unpinned-pane-header-component.scss',
  shadow: true,
  scoped: false
})
export class IgcUnpinnedPaneHeaderComponent {
  @Prop() location: IgcUnpinnedLocation;
  @Prop() isActive: boolean;
  @Prop() disabled = false;

  render() {
    const isHorizontal = this.location === IgcUnpinnedLocation.top || this.location === IgcUnpinnedLocation.bottom;
    const isLeft = this.location === IgcUnpinnedLocation.left;
    const isRight = this.location === IgcUnpinnedLocation.right;
    const isTop = this.location === IgcUnpinnedLocation.top;
    const isBottom = this.location === IgcUnpinnedLocation.bottom;

    const parts = Utils.partNameMap({
      'unpinned-pane-header': true,
      horizontal: isHorizontal,
      vertical: !isHorizontal,
      start: isLeft || isTop,
      end: isRight || isBottom,
      active: this.isActive,
      disabled: this.disabled,
    });

    return (
      <Host
        part={parts}
        role="tab"
        aria-disabled={this.disabled ? 'true' : 'false'}
        tabindex={this.disabled ? -1 : 0}
      >
        <slot></slot>
      </Host>
    );
  }
}
