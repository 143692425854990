/**
 * @hidden
 */
export class TwoWayMap<K, V> {
  private map = new Map<K, V>();
  private reversedMap = new Map<V, K>();

  set(key: K, value: V) {
    this.map.set(key, value);
    this.reversedMap.set(value, key);
  }

  get(key: K): V | undefined {
    return this.map.get(key);
  }

  getByValue(value: V): K | undefined {
    return this.reversedMap.get(value);
  }

  delete(key: K) {
    const value = this.map.get(key);

    this.map.delete(key);
    this.reversedMap.delete(value);
  }

  has(key: K): boolean {
    return this.map.has(key);
  }

  hasValue(value: V): boolean {
    return this.reversedMap.has(value);
  }
}
