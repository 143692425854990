:host {
  position: relative;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
  background: $dock-background;
  color: $dock-text;
  font-family: $font-family;
  outline-style: none;
  --unpinned-tab-area-size: 30px;

  igc-icon-component svg {
    width: 17px;
    height: 17px;
  }
}

%pane-container {
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.pane-container {
  @extend %pane-container;

  &--horizontal {
    @extend %pane-container;
  }

  &--vertical {
    @extend %pane-container;
    flex-direction: column;
  }
}

.unpinned-tab-area {
  display: flex;
  flex-direction: column;
  width: var(--unpinned-tab-area-size);
  height: auto;

  &--left {
    padding: 8px 8px 8px 0;
  }

  &--right {
    padding: 8px 0 8px 8px;
  }

  &--bottom {
    padding-top: 8px;
  }

  &--horizontal {
    flex-direction: row;
    width: auto;
    height: var(--unpinned-tab-area-size);
  }

  &--hidden {
    display: none;
  }

}

.flyout-pane {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;

  igc-content-pane-component {
    box-shadow: 0 12px 8px $flyout-shadow-color;
  }

  &--right {
    flex-direction: row-reverse;

    igc-content-pane-component {
      box-shadow: -12px 0px 8px $flyout-shadow-color;
    }
  }

  &--bottom {
    flex-direction: column-reverse;

    igc-content-pane-component {
      box-shadow: 0 -12px 8px $flyout-shadow-color;
    }
  }

  &--left {
    flex-direction: row;

    igc-content-pane-component {
      box-shadow: 12px 0px 8px $flyout-shadow-color;
    }
  }
}

.floating-panes {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 10000;
}

.docking-indicators-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10004
}

.content {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  color: $pane-content-text;
  background: $pane-content-background;
  position: relative;
}

.maximized {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10002;
}

.drop-shadow {
  background-color: $drop-shadow-background;
  position: fixed;
  z-index: 10003;
  display: block;
  pointer-events: none;
}

.hidden {
  height: 0;
  opacity: 0;
  padding: 0;
  pointer-events: none;
  min-height: 0 !important;
  transition: opacity 0.5s ease;
  position: absolute;
}

.transitioned {
  overflow: hidden;
  transition: opacity 0.5s ease;
  position: absolute;
  z-index: 999;
  width: 100%;
}
