%truncate-text {
  ::slotted(span) {
    max-width: calc(100% - 18px);
    @include ellipsis();
  }
}

:host {
  @include ellipsis();
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  padding: em(8px);
  user-select: none;
  color: $tab-text;
  background: $tab-background;
  border: {
    style: solid;
    color: $tab-border-color;
    top-width: 1px;
    left-width: 1px;
    bottom-width: 0;
    right-width: 1px;
  };
  font-size: em(12px);
  line-height: 2;
  outline-style: none;

  igc-icon-component svg {
    width: 17px;
    height: 17px;
  }

  * + igc-button-component {
    margin-left: em(8px);
  }
}

:host([part~='selected']) {
  color: $tab-text-active;
  background: $tab-background-active;
  border-color: $tab-border-color-active;
}

:host([part~='bottom']) {
  border: {
    top-width: 0;
    bottom-width: 1px;
  }

  &:first-of-type {
    border-left: none;
  }
}

:host([part~='active']) {
  box-shadow: inset 0 -2px 0 0 $active-color;
  color: $active-color;

  igc-icon-component {
    color: var(--igc-icon-active-color, $active-color);
  }
}

:host([part~='hover-preview-close']:hover) {
  @extend %truncate-text;
}

:host([part~='hover-preview-close'][part~='selected']) {
  @extend %truncate-text;
}

:host([part~='disabled']) {
  pointer-events: none;
  color: $disabled-color;
}

:host(:hover) {
  [part*='floating'] {
    opacity: 1;
  }
}

[part*='floating'] {
  position: absolute;
  inset-inline-end: 0;
  background: inherit;
  opacity: 0;
  transition: opacity .1s ease-out;
}

[part*='selected'] {
  opacity: 1;
}
