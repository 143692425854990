:host {
  position: relative;
  display: flex;
  flex: 0 0 4px;
  box-sizing: border-box;
  pointer-events: all;
}

.splitter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: ew-resize;
  background: $splitter-background;
  --resize-handle-width: 2px;
  --resize-handle-height: 80px;
  outline-style: none;

  &:not(.splitter--custom-handle)::after {
    position: absolute;
    content: '';
    background: $splitter-handle;
    top: 50%;
    left: 50%;
    width: var(--resize-handle-width);
    height: var(--resize-handle-height);
    max-height: 100%;
    max-width: 100%;
    border-radius: 1px;
    transform: translateX(-50%) translateY(-50%);
  }

  &:focus-within {
    background-color: $active-color;
  }

  &--vertical {
    cursor: ns-resize;

    &:not(.splitter--custom-handle)::after {
      width: var(--resize-handle-height);
      height: var(--resize-handle-width);
    }
  }
}

.splitter-ghost {
  position: absolute;
  cursor: ew-resize;
  opacity: 0.3;
  background: black;
  width: 100%;
  height: 100%;
  z-index: 5;

  &--vertical {
    cursor: ns-resize;
  }
}
