:host {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  color: $joystick-icon-color;
  background: $joystick-background;
  // TODO try fixing the animation to not apply initially
  // transition: color .25s ease-out, background .25s ease-out;
  border: 1px solid $joystick-border-color;

  &.inner-top {
    border-bottom: none;
  }

  &.inner-start {
    border-right: none;
  }

  &.inner-end {
    border-left: none;
  }

  &.inner-bottom {
    border-top: none;
  }

  &.inner-center {
    border: 1px solid $joystick-background;
  }

  &:hover {
    background: $joystick-background-active;
    color: $joystick-icon-color-active;
  }
}
