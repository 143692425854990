import { Component, Element, Event, EventEmitter, Host, Listen, Prop, Watch, h } from '@stencil/core';

import { Utils } from '../../utils/utils';
import { IgcTabHeadersPosition } from '../dockmanager/dockmanager.interfaces';
import { IgcDockManagerResourceStrings } from '../dockmanager/dockmanager.public-interfaces';
import { IGNORE_DRAG, IgcDragEventArguments, IgcDragMoveEventArguments, IgcDragService, IgcDragStartEventArguments } from '../drag-drop/drag.service';

/**
 * @hidden
 */
@Component({
  tag: 'igc-tab-header-component',
  styleUrl: 'tab-header-component.scss',
  shadow: true,
  scoped: false
})
export class IgcTabHeaderComponent {
  @Prop({ mutable: true }) dragService: IgcDragService;

  @Element() element: HTMLIgcTabHeaderComponentElement;

  @Prop() selected = false;
  @Prop() hovered = false;
  @Prop() position = IgcTabHeadersPosition.top;
  @Prop() iconName: string;
  @Prop() header: string;
  @Prop() isActive: boolean;
  @Prop() resourceStrings: IgcDockManagerResourceStrings;
  @Prop() forcedDrag: boolean;
  @Prop() disabled = false;
  @Prop() showHeaderIconOnHover: 'closeOnly' | 'moreOptionsOnly' | 'all';

  @Event() dragStarted: EventEmitter<IgcDragStartEventArguments>;
  @Event() dragMoved: EventEmitter<IgcDragMoveEventArguments>;
  @Event() dragEnded: EventEmitter<IgcDragEventArguments>;
  @Event() tabMouseDown: EventEmitter;
  @Event() iconClicked: EventEmitter<any>;
  @Event() iconKeyDown: EventEmitter<KeyboardEvent>;
  @Event() elementConnected: EventEmitter<HTMLIgcTabHeaderComponentElement>;
  @Event() elementDisconnected: EventEmitter<HTMLIgcTabHeaderComponentElement>;

  connectedCallback() {
    this.dragService = new IgcDragService(this.element);

    this.dragService.dragStart = args => {
      this.dragStarted.emit(args);
    };
    this.dragService.dragMove = args => {
      this.dragMoved.emit(args);
    };
    this.dragService.dragEnd = args => {
      this.dragEnded.emit(args);
    };

    this.forceDragging();
    this.elementConnected.emit(this.element);
  }

  disconnectedCallback() {
    if (this.dragService) {
      this.dragService.destroy();
    }
    this.elementDisconnected.emit(this.element);
  }

  @Listen('mousedown')
  handleMouseDown(ev: MouseEvent) {
    const iconSlot = this.element.shadowRoot.querySelector('div[part*="tab-header-more-options"]');
    const isIconClicked = ev.composedPath().filter(p => p === iconSlot).length > 0;
    this.tabMouseDown.emit({ showHeaderIconOnHover: this.showHeaderIconOnHover, isIconClicked });
  }

  @Listen('mouseenter')
  handleMouseEnter() {
    this.hovered = true;
  }

  @Listen('mouseleave')
  handleMouseLeave() {
    this.hovered = false;
  }

  @Watch('forcedDrag')
  forcedDragChanged() {
    this.forceDragging();
  }

  private forceDragging() {
    if (this.forcedDrag) {
      this.dragService.forceDragging();
    }
  }

  @Watch('isActive')
  @Watch('selected')
  @Watch('hovered')
  activeChanged() {
    const queryButton = 'tab-header-close-button';
    const button = this.element.querySelector(`[part^=${queryButton}]`);
    const parts = Utils.partNameMap({
      [queryButton]: true,
      active: this.isActive,
      selected: this.selected,
      hovered: this.hovered
    });

    button?.setAttribute('part', parts);
  }

  private iconClick = (ev: MouseEvent) => {
    this.iconClicked.emit(ev);
  }

  private keyDown = (ev: KeyboardEvent) => {
    this.iconKeyDown.emit(ev);
  }

  private renderCloseButton() {
    const showHeaderIconOnHover = this.showHeaderIconOnHover && (this.showHeaderIconOnHover === 'closeOnly' || this.showHeaderIconOnHover === 'all');
    const shouldShowButton = this.selected || showHeaderIconOnHover;
    const pointerEvents = (this.selected && !this.disabled) || this.showHeaderIconOnHover ? 'all' : 'none';

    return (
      shouldShowButton && <slot name="tabHeaderCloseButton" >
        <igc-button-component
          part="tab-header-close-button"
          style={{
            pointerEvents
          }}
        >
          <igc-icon-component
            name={this.iconName}
            aria-label={this.resourceStrings.close}
            title={this.resourceStrings.close}
          />
        </igc-button-component>
      </slot>
    );
  }

  private renderMoreOptionsButton() {
    const showHeaderIconOnHover = this.showHeaderIconOnHover && (this.showHeaderIconOnHover === 'moreOptionsOnly' || this.showHeaderIconOnHover === 'all');
    const shouldShowButton = this.selected || showHeaderIconOnHover;
    const pointerEvents = (this.selected && !this.disabled) || this.showHeaderIconOnHover ? 'all' : 'none';

    return (
      shouldShowButton && <slot name="tabHeaderMoreOptionsButton">
        <igc-button-component
          part="tab-header-more-options-button"
          style={{
            pointerEvents
          }}
        >
          <igc-icon-component
            name={this.iconName}
            aria-label={this.resourceStrings.moreOptions}
            title={this.resourceStrings.moreOptions}
          />
        </igc-button-component>
      </slot>
    );
  }

  render() {
    const showCloseOnHover = this.iconName === 'close' && this.showHeaderIconOnHover && (this.showHeaderIconOnHover === 'closeOnly' || this.showHeaderIconOnHover === 'all');
    const showMoreOptionsOnHover = this.iconName !== 'close' && this.showHeaderIconOnHover && (this.showHeaderIconOnHover === 'moreOptionsOnly' || this.showHeaderIconOnHover === 'all');

    const parts = Utils.partNameMap({
      'tab-header': true,
      top: this.position === IgcTabHeadersPosition.top,
      active: this.isActive,
      selected: this.selected,
      disabled: this.disabled,
      'hover-preview-close': showCloseOnHover,
      'hover-preview-options': showMoreOptionsOnHover,
      bottom: this.position === IgcTabHeadersPosition.bottom,
    });

    const tabParts = Utils.partNameMap({
      'tab-header-more-options': true,
      'floating': !this.selected && (showCloseOnHover || showMoreOptionsOnHover),
      'selected': this.selected
    });

    const exportparts = Utils.partNameMap({
      'tab-header-close-button': true,
      'tab-header-more-options': true,
      'tab-header-more-options-button': true,
      'floating': true,
      'selected': true,
      'active': true
    }, ',');

    return (
      <Host
        role="tab"
        part={parts}
        exportparts={exportparts}
        aria-label={this.header}
        aria-selected={this.selected ? 'true' : 'false'}
        aria-disabled={this.disabled ? 'true' : 'false'}
        tabIndex={!this.selected || this.disabled ? -1 : 0}
      >
        <slot></slot>
        {
          this.iconName &&
          <div part={tabParts} onClick={this.iconClick} onKeyDown={this.keyDown} {...{ [IGNORE_DRAG]: true }}>
            {this.iconName === 'close' ? this.renderCloseButton() : this.renderMoreOptionsButton()}
          </div>
        }
      </Host>);
  }
}
