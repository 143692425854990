:host {
  display: flex;
  box-sizing: border-box;
  align-self: center;
  pointer-events: all;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 30px;
  height: 30px;
  color: $joystick-icon-color;
  background: $joystick-background;
  transition: color .25s ease-out, background .25s ease-out;
  border: 1px solid $joystick-border-color;

  &:hover {
    color: $joystick-icon-color-active;
    background: $joystick-background-active;
  }
}
