import { IgcResizerLocation } from '../dockmanager/dockmanager.public-interfaces';

/**
 * @hidden
 */
export interface IgcDragStartEventArguments {
  cancel?: boolean;
  clientX: number;
  clientY: number;
}

/**
 * @hidden
 */
export interface IgcDragEventArguments {
  offsetX: number;
  offsetY: number;
  clientX: number;
  clientY: number;
}

/**
 * @hidden
 */
export interface IgcDragResizeEventArguments {
  dragMoveArgs: IgcDragMoveEventArguments;
  resizerLocation: IgcResizerLocation;
}

/**
 * @hidden
 */
export interface IgcDragMoveEventArguments extends IgcDragEventArguments {
  totalOffsetX?: number;
  totalOffsetY?: number;
  cancel?: boolean;
}

export const IGNORE_DRAG = 'data-ignore-drag';

/**
 * @hidden
 */
export class IgcDragService {
  private startX: number;
  private startY: number;
  private lastX: number;
  private lastY: number;
  private currentX: number;
  private currentY: number;
  private dragStarted: boolean;
  private forcedDrag: boolean;
  private _capturedElement: HTMLElement;

  private get captureTarget(): HTMLElement {
    return this._capturedElement || this.element;
  }
  private set captureTarget(val: HTMLElement) {
    this._capturedElement = val;
  }

  dragTolerance = 5;
  dragEdgeTolerance = 0;
  dragStart: (eventArgs: IgcDragStartEventArguments) => void;
  dragEnd: (eventArgs: IgcDragEventArguments) => void;
  dragMove: (eventArgs: IgcDragMoveEventArguments) => void;

  constructor(private element: HTMLElement) {
    this.element.addEventListener('pointerdown', this.handlePointerDown, false);
  }

  private triggerDragStart() {
    if (this.dragStart) {
      const args: IgcDragStartEventArguments = {
        clientX: this.startX,
        clientY: this.startY
      };

      this.dragStart(args);

      if (args.cancel) {
        this.unsubscribeDragEvents();
        return;
      }
    }

    this.dragStarted = true;
  }

  private subscribeDragEvents() {
    this.dragStarted = false;
    document.addEventListener('pointermove', this.handlePointerMove, false);
    document.addEventListener('pointerup', this.handlePointerUp, false);
    document.addEventListener('wheel', this.handleWheel, { passive: false });
  }

  private unsubscribeDragEvents() {
    document.removeEventListener('pointermove', this.handlePointerMove);
    document.removeEventListener('pointerup', this.handlePointerUp);
    document.removeEventListener('wheel', this.handleWheel);
  }

  private handleWheel = (event: WheelEvent) => {
    event.preventDefault();
  }

  private handlePointerDown = (event: PointerEvent) => {
    let targets = event.composedPath();
    targets = targets.slice(0, targets.indexOf(event.currentTarget));
    for (const target of targets) {
      if (target instanceof Element
        && target.hasAttribute(IGNORE_DRAG)) {
        return;
      }
    }
    // TODO Not sure if we need this
    // this.element.focus();
    // event.preventDefault();
    this.startX = event.clientX;
    this.startY = event.clientY;
    this.lastX = this.startX;
    this.lastY = this.startY;

    this.captureTarget = event.target as HTMLElement;
    this.captureTarget.setPointerCapture(event.pointerId);
    this.subscribeDragEvents();
  }

  private handlePointerMove = (event: PointerEvent) => {
    event.preventDefault();

    const maxHeight = document.documentElement.clientHeight;
    const maxWidth = document.documentElement.clientWidth;

    if (event.clientX < this.dragEdgeTolerance) {
      this.currentX = this.dragEdgeTolerance;
    } else if (event.clientX > maxWidth - this.dragEdgeTolerance) {
      this.currentX = maxWidth - this.dragEdgeTolerance;
    } else {
      this.currentX = event.clientX;
    }

    if (event.clientY < this.dragEdgeTolerance) {
      this.currentY = this.dragEdgeTolerance;
    } else if (event.clientY > maxHeight - this.dragEdgeTolerance) {
      this.currentY = maxHeight - this.dragEdgeTolerance;
    } else {
      this.currentY = event.clientY;
    }

    if (!this.dragStarted) {
      if (this.forcedDrag) {
        this.forcedDrag = false;
        this.startX = this.currentX;
        this.startY = this.currentY;
        this.lastX = this.startX;
        this.lastY = this.startY;
        this.captureTarget.setPointerCapture(event.pointerId);
        this.triggerDragStart();
      } else {
        const offsetX = this.currentX - this.lastX;
        const offsetY = this.currentY - this.lastY;

        if (Math.abs(offsetX) > this.dragTolerance || Math.abs(offsetY) > this.dragTolerance) {
          this.triggerDragStart();
        }
      }
    }

    if (!this.dragStarted) {
      return;
    }

    if (this.dragMove) {
      const args: IgcDragMoveEventArguments = {
        offsetX: this.currentX - this.lastX,
        offsetY: this.currentY - this.lastY,
        totalOffsetX: this.currentX - this.startX,
        totalOffsetY: this.currentY - this.startY,
        clientX: this.currentX,
        clientY: this.currentY
      };
      this.dragMove(args);

      if (args.cancel) {
        this.unsubscribeDragEvents();
      }
    }

    this.lastX = this.currentX;
    this.lastY = this.currentY;
  }

  private handlePointerUp = (event: PointerEvent) => {
    this.unsubscribeDragEvents();
    this.captureTarget.releasePointerCapture(event.pointerId);
    if (this.dragStarted && this.dragEnd) {
      this.dragEnd({
        offsetX: this.currentX - this.startX,
        offsetY: this.currentY - this.startY,
        clientX: event.clientX,
        clientY: event.clientY
      });
    }
  }

  destroy() {
    this.element.removeEventListener('pointerdown', this.handlePointerDown);
    this.unsubscribeDragEvents();
    this.element = null;
    this.dragStart = null;
    this.dragEnd = null;
    this.dragMove = null;
  }

  forceDragging() {
    this.forcedDrag = true;
    this.subscribeDragEvents();
  }
}
