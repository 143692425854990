import { Component, Element, Event, EventEmitter, Host, Prop, Watch, h } from '@stencil/core';

import { Utils } from '../../utils/utils';

/**
 * @hidden
 */
@Component({
  tag: 'igc-tab-panel-component',
  styleUrl: 'tab-panel-component.scss',
  shadow: true,
  scoped: false
})
export class IgcTabPanelComponent {
  private emitSelectedChanged = false;

  @Element() element: HTMLElement;

  @Prop() selected = false;
  @Prop() disabled = false;

  @Watch('selected')
  selectedPropChange() {
    this.emitSelectedChanged = true;
  }

  @Event()
  selectedChanged: EventEmitter<boolean>;

  componentDidUpdate() {
    if (this.emitSelectedChanged) {
      this.emitSelectedChanged = false;
      this.selectedChanged.emit(this.selected);
    }
  }

  render() {
    const parts = Utils.partNameMap({
      'tab-panel': true,
      selected: this.selected,
      disabled: this.disabled
    });

    return (
      <Host
        role="tabpanel"
        part={parts}
        style={{
          display: this.selected ? 'block' : 'none'
        }}
      >
        <slot></slot>
      </Host>
    );
  }

}
