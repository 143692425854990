import { Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, forceUpdate, h } from '@stencil/core';

import { Utils } from '../../utils/utils';
import { IGC_DEFAULT_PANE_SIZE, IgcContextMenuMetadata, IgcContextMenuOrientation, IgcTabHeadersPosition } from '../dockmanager/dockmanager.interfaces';
import { IgcDockManagerResourceStrings } from '../dockmanager/dockmanager.public-interfaces';

/**
 * @hidden
 */
let NEXT_TAB_ID = 0;

/**
 * @hidden
 */
@Component({
  tag: 'igc-tabs-component',
  styleUrl: 'tabs-component.scss',
  shadow: true,
  scoped: false
})
export class IgcTabsComponent {
  private forcedUpdate = false;
  private selectionOrder: string[] = [];

  @Element() el!: HTMLIgcTabsComponentElement;

  @State() hiddenTabsMenuMeta: IgcContextMenuMetadata;
  @State() hasHiddenTabs = false;

  @Prop() size: number;
  @Prop() maximized: boolean;
  @Prop() allowMaximize = true;
  @Prop() tabHeadersPosition: IgcTabHeadersPosition = IgcTabHeadersPosition.top;
  @Prop({ mutable: true }) selectedIndex: number;
  @Prop() hasHeaders = true;
  @Prop() showHiddenTabsMenu = true;
  @Prop() resourceStrings: IgcDockManagerResourceStrings;
  @Prop() contentIds: string[] = [];

  @Event() maximize: EventEmitter;
  @Event() maximizeMinimizeFocus: EventEmitter;

  @Watch('selectedIndex')
  selectedIndexPropertyChanged(newValue: number) {
    this.handleSelectedIndexChanged(newValue);
    this.selectedIndexChanged.emit(newValue);
  }

  @Event({ bubbles: false }) selectedIndexChanged: EventEmitter<number>;
  @Event({ bubbles: false }) hiddenTabSelected: EventEmitter<number>;
  @Event({ bubbles: false }) selectedTabOutOfView: EventEmitter<number>;
  @Event() rendered: EventEmitter<HTMLIgcTabsComponentElement>;

  componentWillLoad() {
    this.hiddenTabsMenuMeta = null;
    this.handleSelectedIndexChanged(this.selectedIndex);
    this.checkForActivePane();
  }

  componentWillUpdate() {
    this.updateSelection();
  }

  updateSelection() {
    const headers = this.tabHeaders;

    // if any header is removed - delete its id from selection order & update selected index
    const missingMatchIndex = this.selectionOrder.findIndex(s => headers.findIndex(h => h.id === s) < 0);
    if (missingMatchIndex > -1) {
      this.selectionOrder.splice(missingMatchIndex, 1);
      const newSelectedIndex = headers.findIndex(h => h.id === this.selectionOrder[this.selectionOrder.length - 1]);
      this.selectedIndex = newSelectedIndex > -1 ? newSelectedIndex : 0;
    }

    const currentSelectionId = headers[this.selectedIndex]?.id;
    const existingMatchIndex = this.selectionOrder.indexOf(currentSelectionId);
    if (existingMatchIndex > -1) {
      this.selectionOrder.splice(existingMatchIndex, 1);
    }

    if (currentSelectionId) {
      this.selectionOrder.push(currentSelectionId);
    }
  }

  componentDidRender() {
    this.rendered.emit();
  }

  componentDidLoad() {
    this.setTabsAttributes();

    this.tabHeadersDiv = this.el.shadowRoot.querySelector('div.tabs');

    if (this.tabHeadersDiv) {
      this.resizeObserver.observe(this.tabHeadersDiv);
    }
  }

  componentDidUpdate() {
    if (this.forcedUpdate) {
      this.forcedUpdate = false;
      this.checkForActivePane();
    }

    this.setHasHiddenTabs();
  }

  private setTabsAttributes() {
    const tabHeaders = this.tabHeaders;
    const tabPanels = this.tabPanels;

    for (let i = 0; i < tabHeaders.length; i++) {
      if (!tabHeaders[i].getAttribute('id')) {
        const tabHeaderId = `tab-item-${NEXT_TAB_ID}`;
        const tabPanelId = `tab-panel-${NEXT_TAB_ID++}`;

        tabPanels[i].setAttribute('id', tabPanelId);
        tabHeaders[i].setAttribute('id', tabHeaderId);

        tabPanels[i].setAttribute('aria-labelledby', tabHeaderId);
        tabHeaders[i].setAttribute('aria-controls', tabPanelId);
      }
    }
  }

  disconnectedCallback() {
    this.resizeObserver.disconnect();
  }

  private tabHeadersDiv: HTMLElement;
  private resizeObserver = new ResizeObserver(this.tabHeadersDivResized.bind(this));

  private slotChanged = () => {
    this.setTabsAttributes();
    this.updateSelection();
    this.handleSelectedIndexChanged(this.selectedIndex);
    this.forceUpdate();
  }

  private forceUpdate() {
    this.forcedUpdate = true;
    forceUpdate(this);
  }


  private get tabPanels() {
    return Array.from(this.el.querySelectorAll('igc-tab-panel-component'));
  }

  private get tabHeaders() {
    return Array.from(this.el.querySelectorAll('igc-tab-header-component'));
  }

  private get hiddenTabHeaders() {
    const containerTop = this.tabHeaders[0]?.offsetTop;
    return this.tabHeaders.filter(t => t.offsetTop !== containerTop);
  }

  private handleSelectedIndexChanged(newValue: number) {
    const tabHeaders = this.tabHeaders;
    const tabPanels = this.tabPanels;

    tabHeaders.forEach((t, i) => {
      t.selected = i === newValue;
    });

    tabPanels.forEach((t, i) => {
      t.selected = i === newValue;
    });
  }

  @Listen('tabMouseDown')
  handleTabMouseDown(ev: CustomEvent) {
    const tabHeader = ev.target as HTMLIgcTabHeaderComponentElement;
    const tabHeaders = this.tabHeaders;
    const headerIndex = tabHeaders.indexOf(tabHeader);
    if (headerIndex >= 0 && (!ev.detail.showHeaderIconOnHover || !ev.detail.isIconClicked)) {
      this.selectedIndex = headerIndex;
    }
  }

  private onTabKeyDown = (ev: KeyboardEvent) => {
    const tabHeaders = this.tabHeaders;
    if (Utils.isAltPressed(ev) || Utils.isControlOrMetaPressed(ev) || ev.shiftKey) {
      return;
    }

    if (ev.key === 'ArrowRight') {
      for (let i = this.selectedIndex; i < tabHeaders.length - this.hiddenTabHeaders.length - 1; i++) {
        if (!tabHeaders[i + 1].disabled) {
          this.selectedIndex = i + 1;
          tabHeaders[i + 1].focus();
          return;
        }
      }
    } else if (ev.key === 'ArrowLeft') {
      for (let i = this.selectedIndex; i > 0; i--) {
        if (!tabHeaders[i - 1].disabled) {
          this.selectedIndex = i - 1;
          tabHeaders[i - 1].focus();
          return;
        }
      }
    }
  }

  private tabHeadersDivResized() {
    const containerTop = this.tabHeaders[0]?.offsetTop;
    const selectedTabHeader = this.tabHeaders.filter(t => t.selected)[0];
    if (selectedTabHeader?.offsetTop !== containerTop) {
      const index = this.selectedIndex;
      this.selectedTabOutOfView.emit(index);
    }

    this.setHasHiddenTabs();
  }

  private setHasHiddenTabs() {
    this.hasHiddenTabs = this.hiddenTabHeaders.length > 0;
  }

  private checkForActivePane() {
    const activeTabHeader = this.tabHeaders.filter(t => t.isActive);
    if (activeTabHeader.length > 0) {
      const hiddenActiveTabIndex = this.hiddenTabHeaders.indexOf(activeTabHeader[0]);
      const activeTabIndex = this.tabHeaders.indexOf(activeTabHeader[0]);
      if (hiddenActiveTabIndex >= 0) {
        this.selectedTabOutOfView.emit(activeTabIndex);
      } else if (activeTabIndex >= 0) {
        this.selectedIndex = activeTabIndex;
      }
    }
  }

  private handleHiddenTabsMenuClick(ev: CustomEvent<KeyboardEvent>) {
    const button = ev.currentTarget as HTMLElement;

    const items = this.hiddenTabHeaders.map(header => ({
      displayText: header.header,
      disabled: header.disabled,
      iconName: null,
      clickHandler: () => {
        const index = this.tabHeaders.indexOf(header);
        this.hiddenTabSelected.emit(index);
      }
    }));

    this.hiddenTabsMenuMeta = { target: button, menuItems: items, position: 'end' };
  }

  private handleContextMenuClosed() {
    this.hiddenTabsMenuMeta = null;
  }

  private maximizeButtonClick = () => {
    this.maximize.emit();
  }

  private handleMaximizeMinimizeFocus = () => {
    this.maximizeMinimizeFocus.emit();
  }

  private renderHiddenTabsMenu() {
    return this.hiddenTabsMenuMeta && (
      <igc-context-menu-component
        onMenuClosed={this.handleContextMenuClosed.bind(this)}
        orientation={this.tabHeadersPosition === IgcTabHeadersPosition.top ? IgcContextMenuOrientation.bottom : IgcContextMenuOrientation.top}
        items={this.hiddenTabsMenuMeta.menuItems}
        target={this.hiddenTabsMenuMeta.target}
        position={this.hiddenTabsMenuMeta.position}
      >
      </igc-context-menu-component>);
  }

  private renderTabHeaders(top: boolean) {
    const classes = {
      'tabs': true,
      'tabs--top': top,
      'tabs--bottom': !top
    };

    const commontParts = { top, bottom: !top };

    const tabStripParts = Utils.partNameMap({ 'tab-strip-area': true, ...commontParts });
    const tabStripActionsParts = Utils.partNameMap({
      'tab-strip-actions': true,
      ...commontParts
    });

    return (
      <div
        part={tabStripParts}
        style={{
          display: this.hasHeaders ? 'flex' : 'none'
        }}
        class={classes}
        onKeyDown={this.onTabKeyDown}
      >
        <div
          class={{
            'tab-headers-container': true,
            'tab-headers-container--wrapped': this.showHiddenTabsMenu
          }}
        >
          <slot name="tabHeader"></slot>
        </div>
        <div part={tabStripActionsParts} class="tab-header-icon-container">
          {this.showHiddenTabsMenu && this.hasHiddenTabs &&
            <div onClick={this.handleHiddenTabsMenuClick.bind(this)}>
              {this.renderMoreTabsButton()}
            </div>}
           { !(this.showHiddenTabsMenu && this.hasHiddenTabs) &&
            <span style={{ width: '25px' }}></span>
          }
          {this.allowMaximize &&
            <div onClick={this.maximizeButtonClick} onFocusin={this.handleMaximizeMinimizeFocus}>
              {this.maximized ? this.renderMinimizeButton() : this.renderMaximizeButton()}
            </div>
          }
        </div>
      </div>
    );
  }

  private renderMoreTabsButton() {
    return (
      <span>
        <slot name="tabsMoreButton">
          <igc-button-component part="tabs-more-button">
            <igc-icon-component
              name="more"
              aria-label={this.resourceStrings.moreTabs}
              title={this.resourceStrings.moreTabs}
              style={{
                display: 'block'
              }}
            />
          </igc-button-component>
        </slot>
      </span>
    );
  }

  private renderMaximizeButton() {
    return (
      <div>
        <slot name="tabsMaximizeButton">
          <igc-button-component part="tabs-maximize-button">
            <igc-icon-component
              name="maximize"
              aria-label={this.resourceStrings.maximize}
              title={this.resourceStrings.maximize}
            />
          </igc-button-component>
        </slot>
      </div>
    );
  }

  private renderMinimizeButton() {
    return (
      <slot name="tabsMinimizeButton">
        <igc-button-component part="tabs-minimize-button">
          <igc-icon-component
            name="minimize"
            aria-label={this.resourceStrings.minimize}
            title={this.resourceStrings.minimize}
          />
        </igc-button-component>
      </slot>
    );
  }

  render() {
    const size = this.size || this.size === 0 ? this.size : IGC_DEFAULT_PANE_SIZE;
    const top = this.tabHeadersPosition === IgcTabHeadersPosition.top;
    const bottom = this.tabHeadersPosition === IgcTabHeadersPosition.bottom;
    const contentIds = this.contentIds.join(' ');
    const parts = "tabs-container " + contentIds;

    const tabsContentParts = Utils.partNameMap({ 'tabs-content': true, document: top });
    const exportParts = Utils.partNameMap({
      'tab-strip-area': true,
      'tab-strip-actions': true,
      'tabs-content': true,
      'tabs-more-button': true,
      'tabs-maximize-button': true,
      'tabs-minimize-button': true,
      'context-menu-content: tabs-more-menu-content': true,
      'context-menu-item: tabs-more-menu-item': true,
      document: top,
      top,
      bottom
    }, ',');

    return (
      <Host
        role="tablist"
        style={{
          flex: `${size} 1 ${size}px`
        }}
        exportparts={exportParts}
        part={parts}
      >
        {top && this.renderTabHeaders(top)}
        <div part={tabsContentParts} class="content">
          <slot onSlotchange={this.slotChanged.bind(this)}></slot>
        </div>
        {bottom && this.renderTabHeaders(top)}
        {this.renderHiddenTabsMenu()}
      </Host>
    );
  }
}
