:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* igc-dockmanager {
  background-color: gray;
}

igc-dockmanager::part(pane-header) {
  background-color: white;
}

igc-dockmanager::part(splitter) {
  flex: 0 0 15px;
  pointer-events: none;
  visibility: hidden;
}

igc-dockmanager::part(splitter-base) {
  background: transparent;
  pointer-events: none;
}

igc-dockmanager::part(splitter-base)::after {
  content: none;
}

igc-dockmanager::part(content-pane) {
  border-radius: 10px;
}

igc-dockmanager::part(context-menu) {
  background-color: wheat;
}

igc-dockmanager::part(floating-window) {
  border-color: red;
}

igc-dockmanager::part(pane-header) {
  display: none;
}

igc-dockmanager::part(document-tab-header) {
  display: none;
}

igc-dockmanager::part(tab-strip-area) {
  display: none;
}

igc-dockmanager::part(document-panel) {
  padding: 10px;
}

igc-dockmanager::part(root-docking-indicator) {
  background-color: red;
}

igc-dockmanager::part(docking-indicator) {
  background-color: chocolate;
}

igc-dockmanager::part(docking-preview) {
  background-color: chocolate;
}

igc-dockmanager::part(unpinned-pane-header) {
  background-color: lightgreen;
}

igc-dockmanager::part(pane-header-actions) {
  background-color: lightgreen;
}

igc-dockmanager::part(floating-pane-header-actions) {
  background-color: lightpink;
}

igc-dockmanager::part(floating-window-header-actions) {
  visibility: collapse;
  pointer-events: none;
}

igc-dockmanager::part(tab-strip-actions) {
  visibility: collapse;
  pointer-events: none;
} 

igc-dockmanager::part(tabs-more-button) {
  color: red;
}

igc-dockmanager::part(context-menu-item) {
  background-color: greenyellow;
}

igc-dockmanager::part(context-menu-content) {
  background-color: coral;
  padding: 5px;
}

igc-dockmanager::part(tabs-more-menu-content) {
  background-color: plum;
  padding: 5px;
}

igc-dockmanager::part(tabs-more-menu-item) {
  background-color: cyan;
} 

igc-dockmanager::part(content2) {
  min-width: 350px;
  max-height: 550px;
} 

igc-dockmanager::part(content10) {
  min-width: 350px;
  max-width: 500px;
  min-height: 350px;
}

igc-dockmanager::part(content11) {
  min-width: 350px;
  max-width: 500px;
  min-height: 350px;
}
*/