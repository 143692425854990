import { Component, Element, Event, EventEmitter, Host, Listen, Method, Prop, State, Watch, h } from '@stencil/core';

import { resourceStringsMap } from '../../utils/locale';
import { TwoWayMap } from '../../utils/two-way-map';
import { Utils } from '../../utils/utils';
import { IgcDragMoveEventArguments, IgcDragResizeEventArguments, IgcDragStartEventArguments } from '../drag-drop/drag.service';

import {
  IGC_DEFAULT_PANE_SIZE, IGC_DEFAULT_UNPIN_PANE_SIZE, IgcContextMenuMetadata, IgcContextMenuOrientation, IgcContextMenuPosition, IgcDockManagerComponentBase, IgcDropTargetPaneInfo, IgcPaneNavigatorMetadata, IgcPinBehavior, IgcTabHeadersPosition
} from './dockmanager.interfaces';
import {
  IgcActivePaneEventArgs,
  IgcContentPane, IgcDockManagerLayout, IgcDockManagerPane, IgcDockManagerPaneType, IgcDockManagerPoint, IgcDockManagerResourceStrings, IgcDockingIndicatorPosition, IgcDocumentHost, IgcFloatingPaneResizeEventArgs,
  IgcFloatingPaneResizeMoveEventArgs, IgcPaneCloseEventArgs, IgcPaneDragEndEventArgs, IgcPaneDragOverEventArgs,
  IgcPaneDragStartEventArgs, IgcPaneHeaderConnectionEventArgs, IgcPaneHeaderElement, IgcPanePinnedEventArgs, IgcSplitPane,
  IgcSplitPaneOrientation, IgcTabGroupPane, IgcTabHeaderConnectionEventArgs, IgcTabHeaderElement, IgcUnpinnedLocation, IgcSplitterResizeEventArgs, IgcPaneScrollEventArgs
} from './dockmanager.public-interfaces';
import { IgcDockManagerService } from './dockmanager.service';
import { IgcDockManagerKeyboardService } from './keyboard/keyboard.service';
import { IgcSplitterComponent } from './panes/splitter-component';

enum ActionReason {
  click = 'click',
  drop = 'drop',
  maximizeOrMinimize = 'maximizeOrMinimize'
}

const PANE_HEADER_HEIGHT = 40;

/**
 * @hidden
 */
@Component({
  tag: 'igc-dockmanager',
  styleUrl: 'dockmanager-component.scss',
  shadow: true,
  scoped: false,
})
export class IgcDockManager implements IgcDockManagerComponentBase {
  private _tabHeaderIconClicked = false;
  private service = new IgcDockManagerService(this);
  private keyboardService = new IgcDockManagerKeyboardService(this.service);
  private panesElementMap: TwoWayMap<IgcDockManagerPane, HTMLElement>;
  private contentPanesElementMap: Map<IgcContentPane, HTMLElement>;
  private tabHeadersMap: Map<IgcContentPane, HTMLElement>;
  private unpinnedHeadersMap: Map<IgcContentPane, HTMLElement>;
  private activePaneInternalSet = false;
  private domObserver = new MutationObserver(this.mutationCallback.bind(this));
  private showDockingIndicators: boolean;
  private shouldMovePane = false;
  private scheduledCallbacks: (() => void)[] = [];
  private paneToFocus: IgcContentPane;
  private shouldClearActivePane = true;
  private dockedPanesContainer: HTMLElement;
  private isDragging = false;
  private contentPaneId: string;
  private reason: ActionReason;
  private splitterOffset = 1;
  private loaded = false;
  private resizeObserver = new ResizeObserver(this.restrictFloatingPaneSize.bind(this));
  private proximityDockThreshold = 50;
  private proximityOuterDockThreshold = 25;
  private cursorLocation: IgcDockManagerPoint;
  private splitterRect: any
  private allowOuterProximityDock: boolean
  private templatableComponents = [
    {
      slot: 'paneHeaderCloseButton',
      targetName: 'igc-pane-header-component',
      targetSlot: 'paneHeaderCloseButton',
      targetPart: 'pane-header-close-button'
    },
    {
      slot: 'tabHeaderCloseButton',
      targetName: 'igc-tab-header-component',
      targetSlot: 'tabHeaderCloseButton',
      targetPart: 'tab-header-close-button'
    },
    {
      slot: 'tabHeaderCloseButton',
      targetName: 'igc-context-menu-component',
      targetSlot: 'contextMenuCloseButton',
      targetPart: 'context-menu-close-button'
    },
    {
      slot: 'closeButton',
      targetName: 'igc-pane-header-component',
      targetSlot: 'paneHeaderCloseButton',
      targetPart: 'pane-header-close-button'
    },
    {
      slot: 'closeButton',
      targetName: 'igc-tab-header-component',
      targetSlot: 'tabHeaderCloseButton',
      targetPart: 'tab-header-close-button'
    },
    {
      slot: 'closeButton',
      targetName: 'igc-context-menu-component',
      targetSlot: 'contextMenuCloseButton',
      targetPart: 'context-menu-close-button'
    },
    {
      slot: 'moreTabsButton',
      targetName: 'igc-tabs-component',
      targetSlot: 'tabsMoreButton',
      targetPart: 'tabs-more-button'
    },
    {
      slot: 'maximizeButton',
      targetName: 'igc-pane-header-component',
      targetSlot: 'paneHeaderMaximizeButton',
      targetPart: 'pane-header-maximize-button'
    },
    {
      slot: 'maximizeButton',
      targetName: 'igc-tabs-component',
      targetSlot: 'tabsMaximizeButton',
      targetPart: 'tabs-maximize-button'
    },
    {
      slot: 'minimizeButton',
      targetName: 'igc-pane-header-component',
      targetSlot: 'paneHeaderMinimizeButton',
      targetPart: 'pane-header-minimize-button'
    },
    {
      slot: 'minimizeButton',
      targetName: 'igc-tabs-component',
      targetSlot: 'tabsMinimizeButton',
      targetPart: 'tabs-minimize-button'
    },
    {
      slot: 'pinButton',
      targetName: 'igc-pane-header-component',
      targetSlot: 'paneHeaderPinButton',
      targetPart: 'pane-header-pin-button'
    },
    {
      slot: 'unpinButton',
      targetName: 'igc-pane-header-component',
      targetSlot: 'paneHeaderUnpinButton',
      targetPart: 'pane-header-unpin-button'
    },
    {
      slot: 'unpinButton',
      targetName: 'igc-context-menu-component',
      targetSlot: 'contextMenuUnpinButton',
      targetPart: 'context-menu-unpin-button'
    },
    {
      slot: 'moreOptionsButton',
      targetName: 'igc-tab-header-component',
      targetSlot: 'tabHeaderMoreOptionsButton',
      targetPart: 'tab-header-more-options-button'
    },
    {
      slot: 'splitterHandle',
      targetName: 'igc-splitter-component',
      targetSlot: 'splitterHandle',
      targetPart: 'splitter-handle'
    },

  ];

  documentOnlyDrag: boolean;

  @Element() element: HTMLElement;

  @State() dropTargetPaneInfo: IgcDropTargetPaneInfo;
  @State() flyoutPane: IgcContentPane;

  @Watch('flyoutPane')
  flyoutPaneChanged(newValue: IgcContentPane) {
    if (newValue) {
      this.scheduleAfterRender(() => {
        this.setFocus(newValue);
      });
    }
  }

  @State() floatingPaneZIndicesMap = new Map<IgcSplitPane, number>();
  @State() contextMenuMeta: IgcContextMenuMetadata;
  @State() dropShadowRect: DOMRect;
  @State() templates: Map<string, any> = new Map();
  @State() hasCustomMaximizeButton: boolean;
  @State() hasCustomMinimizeButton: boolean;
  @State() hoveredPane: IgcContentPane;

  @Prop({ mutable: true }) navigationPaneMeta: IgcPaneNavigatorMetadata;
  @Prop() allowMaximize = true;
  @Prop() showPaneHeaders: 'onHoverOnly' | 'always' = 'always';
  @Prop() showHeaderIconOnHover: 'closeOnly' | 'moreOptionsOnly' | 'all';
  @Prop({ mutable: true }) maximizedPane: IgcContentPane | IgcSplitPane | IgcTabGroupPane;
  @Prop({ mutable: true }) resourceStrings: IgcDockManagerResourceStrings;
  @Prop() allowFloatingPanesResize = true;
  @Prop() disableKeyboardNavigation = false;
  @Prop() contextMenuPosition: IgcContextMenuPosition;
  @Prop() containedInBoundaries = false;
  @Prop() allowInnerDock = true;
  @Prop() proximityDock = false;

  @Watch('containedInBoundaries')
  containedInBoundariesChanged() {
    if (this.loaded) {
      this.restrictFloatingPaneSize();
    }
  }
  /**
   * The layout configuration of the Dock Manager.
   */
  @Prop({ mutable: true }) layout: IgcDockManagerLayout;

  /**
   * Gets the direction of the Dock Manager.
   */
  get direction(): string {
    return Utils.getDirection(this.element);
  }

  @Watch('layout')
  layoutChanged() {
    this.service.processLayout();
    if (!this.isDragging) {
      this.layoutChange.emit();
    }
  }

  @Prop({ mutable: true })
  draggedPane: IgcContentPane | IgcSplitPane | IgcTabGroupPane;

  @Watch('draggedPane')
  draggedPaneChanged() {
    if (this.draggedPane) {
      const panes = this.draggedPane.type === IgcDockManagerPaneType.contentPane ?
        [this.draggedPane] :
        this.service.getChildContentPanes(this.draggedPane);

      this.showDockingIndicators = panes.every(p => p.allowDocking !== false);
      this.documentOnlyDrag = panes.some(p => p.documentOnly);
    } else {
      this.showDockingIndicators = false;
      this.documentOnlyDrag = false;
    }
  }

  @Prop() dropPosition: IgcDockManagerPoint;

  @Watch('dropPosition')
  dropPositionChanged() {
    this.paneDragMoved(this.dropPosition?.x, this.dropPosition?.y);
  }

  @Prop({ mutable: true })
  activePane: IgcContentPane = null;

  @Watch('activePane')
  activePaneChange(newValue: IgcContentPane, oldValue: IgcContentPane) {
    const args: IgcActivePaneEventArgs = { newPane: newValue, oldPane: oldValue };

    if (this.flyoutPane && newValue && newValue !== this.flyoutPane) {
      this.service.flyoutPane(this.flyoutPane);
    }

    this.activePaneChanged.emit(args);
    this.contentPaneId = newValue?.contentId || this.contentPaneId;

    if (this.activePaneInternalSet) {
      this.activePaneInternalSet = false;
      return;
    }

    if (newValue) {
      if (newValue.isPinned === false) {
        requestAnimationFrame(() => {
          this.service.flyoutPane(newValue);
        });
        // return because setFocus will be called in flyoutPaneChanged
        this.paneToFocus = newValue;
        return;
      } else {
        const parent = this.service.getParent(newValue);

        if (parent.type === IgcDockManagerPaneType.tabGroupPane && parent.panes.length > 1) {
          const visibleTabs = this.service.getVisibleContentPanes(parent);
          const tabHeaderElement = this.tabHeadersMap.get(newValue);
          const firstTabRect = this.tabHeadersMap
            .get(visibleTabs[0])
            .getBoundingClientRect();
          const headerRect = tabHeaderElement.getBoundingClientRect();

          // return if the selected index is changed because setFocus will be called in handleTabSelectedChanged
          if (headerRect.top !== firstTabRect.top) { // header is hidden
            this.service.selectHiddenTab(parent, newValue);
            this.paneToFocus = newValue;
            return;
          } else {
            const visibleIndex = visibleTabs.indexOf(newValue);
            const selectedIndex = parent.selectedIndex ?? 0;

            if (selectedIndex !== visibleIndex) {
              parent.selectedIndex = visibleIndex;
              this.paneToFocus = newValue;
              return;
            }
          }
        }
      }
    }

    this.scheduleAfterRender(() => {
      this.setFocus(newValue);
    });
  }

  @Event()
  paneHeaderConnected: EventEmitter<IgcPaneHeaderConnectionEventArgs>;

  @Event()
  paneHeaderDisconnected: EventEmitter<IgcPaneHeaderConnectionEventArgs>;

  @Event()
  tabHeaderConnected: EventEmitter<IgcTabHeaderConnectionEventArgs>;

  @Event()
  tabHeaderDisconnected: EventEmitter<IgcTabHeaderConnectionEventArgs>;

  @Event()
  splitterResizeStart: EventEmitter<IgcSplitterResizeEventArgs>;

  @Event()
  splitterResizeEnd: EventEmitter<IgcSplitterResizeEventArgs>;

  @Event()
  paneClose: EventEmitter<IgcPaneCloseEventArgs>;

  @Event()
  paneScroll: EventEmitter<IgcPaneScrollEventArgs>;

  @Event()
  panePinnedToggle: EventEmitter<IgcPanePinnedEventArgs>;

  @Event()
  activePaneChanged: EventEmitter<IgcActivePaneEventArgs>;

  @Event()
  paneDragStart: EventEmitter<IgcPaneDragStartEventArgs>;

  @Event()
  paneDragOver: EventEmitter<IgcPaneDragOverEventArgs>;

  @Event()
  paneDragEnd: EventEmitter<IgcPaneDragEndEventArgs>;

  @Event()
  floatingPaneResizeMove: EventEmitter<IgcFloatingPaneResizeMoveEventArgs>;

  @Event()
  floatingPaneResizeEnd: EventEmitter<IgcFloatingPaneResizeEventArgs>;

  @Event()
  floatingPaneResizeStart: EventEmitter<IgcFloatingPaneResizeEventArgs>;

  @Event()
  layoutChange: EventEmitter;

  @Listen('focusout')
  handleFocusOut(_event: Event) {
    if (this.paneToFocus) {
      return;
    }
    const rootNode = this.getRootNode();

    if (!this._tabHeaderIconClicked && !this.isDragging) {
      requestAnimationFrame(() => {
        if (!this.element.contains(rootNode.activeElement)) {
          this.clearActivePane();
        }
      });
    }

    this._tabHeaderIconClicked = false;
  }

  @Listen('keydown')
  handleKeyDown(event: KeyboardEvent) {
    this.keyboardService.handleKeydown(event);
  }

  @Listen('pointerdown')
  handlePointerDown(event: PointerEvent) {
    const pathHTMLElements = event.composedPath().filter(e => e instanceof HTMLElement).map(e => e as HTMLElement);
    const isMaximizeOrMinimize = pathHTMLElements.filter(e => e.attributes.getNamedItem('part') &&
      (e.attributes.getNamedItem('part').value === 'tabs-minimize-button' || e.attributes.getNamedItem('part').value === 'tabs-maximize-button')).length > 0;
    if (isMaximizeOrMinimize) {
      this.reason = ActionReason.maximizeOrMinimize;
    }
  }

  @Listen('pointerup')
  handlePointerUp() {
    this.reason = null;
  }

  @Method()
  async dropPane(): Promise<boolean> {
    const docked = this.handlePaneDragEnd();
    return Promise.resolve(docked);
  }

  @Method()
  async removePane(pane: IgcDockManagerPane) {
    this.service.removePane(pane);
  }

  @Method()
  async focusPane(contentId: string) {
    const contentPane = this.service.getContent(contentId) as IgcContentPane;
    const parentPane = this.service.getRootParent(contentPane);
    const isFloating = this.service.isFloatingPane(parentPane);
    const isUnpinned = contentPane.isPinned !== undefined && !contentPane.isPinned;

    if (isFloating) {
      this.service.bringFloatingPaneOnTop(parentPane);
      this.activePane = contentPane;
    } else if (isUnpinned) {
      this.handlePaneFlyout(contentPane);
    } else {
      this.activePane = contentPane;
    }
  }

  connectedCallback() {
    this.element.shadowRoot.addEventListener('focusout', this.handleShadowRootFocusOut);
    this.domObserver.observe(this.element, { attributes: true, subtree: true, childList: true });

    const dockManagerChildren = Array.from(this.element.children);
    for (const htmlElem of dockManagerChildren) {
      const elem = htmlElem as HTMLElement;
      elem?.addEventListener('focusin', this.handleCustomContentFocusIn);
    }
  }

  componentWillLoad() {
    this.contextMenuMeta = null;
    this.navigationPaneMeta = null;

    if (this.layout) {
      this.layoutChanged();
    }

    if (!this.resourceStrings) {
      const closestElement = this.element.closest('[lang]') as HTMLElement;
      const lang = closestElement ? closestElement.lang.toLowerCase() : 'en';
      this.resourceStrings = resourceStringsMap.has(lang) ? resourceStringsMap.get(lang) : resourceStringsMap.get('en');
    }
  }

  componentWillRender() {
    const lastMaximizedPane = this.service.getLastMaximizedPane();

    if (lastMaximizedPane && this.maximizedPane !== lastMaximizedPane) {
      const shouldFlyout = 'isPinned' in lastMaximizedPane && !(lastMaximizedPane as IgcContentPane).isPinned;

      if (shouldFlyout) {
        this.handlePaneFlyout(lastMaximizedPane as IgcContentPane);
      }

      this.handleMaximize(lastMaximizedPane);
    }
  }

  componentDidRender() {
    while (this.scheduledCallbacks.length) {
      const callback = this.scheduledCallbacks.shift();
      callback();
    }

    if (this.service.keyboardDockPane) {
      this.setActivePane(this.service.keyboardDockPane);
      this.setFocus(this.service.keyboardDockPane);
      this.service.keyboardDockPane = null;
    }

    this.processTemplates();
  }

  componentDidLoad() {
    this.loaded = true;
    this.resizeObserver.observe(this.element);
  }

  disconnectedCallback() {
    this.element.shadowRoot.removeEventListener('focusout', this.handleShadowRootFocusOut);
    this.removeCustomContentEventListeners();
    this.domObserver.disconnect();
    this.resizeObserver.disconnect();
  }

  focusElement() {
    this.element.focus();
  }

  private handleTabsRendered(pane: IgcTabGroupPane) {
    if (this.reason !== ActionReason.drop) { return; }
    if (pane.panes?.length > 0) {
      const focusablePane = this.findFocusablePane(pane.panes);
      if (focusablePane?.contentId === this.contentPaneId) {
        pane.selectedIndex = pane.panes.indexOf(focusablePane);
        this.setActivePane(focusablePane);
        this.setFocus(focusablePane);
      }
    }
  }

  private handleSplitPaneRendered(panes: IgcDockManagerPane[]) {
    if (this.reason !== ActionReason.drop) { return; }
    const focusablePane = this.findFocusablePane(panes);
    if (focusablePane?.contentId === this.contentPaneId) {
      this.setActivePane(focusablePane);
      this.setFocus(focusablePane);
    }
  }

  private findFocusablePane(panes: IgcDockManagerPane[], candidate?: IgcContentPane): IgcContentPane | undefined {
    const splitPanes = panes.filter(p => p.type === IgcDockManagerPaneType.splitPane);
    for (const pane of splitPanes) {
      if (candidate) {
        return candidate;
      }
      const splitPane = pane as IgcSplitPane;
      candidate = this.findFocusablePane(splitPane.panes);
    }

    const tabGroupPanes = panes.filter(p => p.type === IgcDockManagerPaneType.tabGroupPane);
    for (const pane of tabGroupPanes) {
      if (candidate) {
        return candidate;
      }
      const tabGroupPane = pane as IgcTabGroupPane;
      candidate = tabGroupPane.selectedIndex ? tabGroupPane.panes[tabGroupPane.selectedIndex] : this.findFocusablePane(tabGroupPane.panes);
    }

    const docHost = panes.filter(p => p.type === IgcDockManagerPaneType.documentHost);
    for (const pane of docHost) {
      if (candidate) {
        return candidate;
      }
      const docHostPane = pane as IgcDocumentHost;
      candidate = this.findFocusablePane([docHostPane.rootPane]);
    }

    candidate = panes.find(p => p.type === IgcDockManagerPaneType.contentPane
      && p.contentId === this.contentPaneId) as IgcContentPane || candidate;
    if (candidate) {
      return candidate;
    }

    return panes.find(p => p.type === IgcDockManagerPaneType.contentPane) as IgcContentPane;
  }

  private processTemplates() {
    if (this.templates.size === 0) {
      return;
    }

    this.templatableComponents.forEach(component => {
      const template = this.templates.get(component.slot);
      if (template) {
        template.setAttribute('slot', component.targetSlot);
        template.setAttribute('part', component.targetPart);

        const componentMatches = this.element.shadowRoot.querySelectorAll(component.targetName);
        componentMatches.forEach(match => {
          const slottedClose = match.querySelectorAll('[slot="' + component.targetSlot + '"]');

          if (slottedClose.length === 0) {
            match.appendChild(template.cloneNode(true));
          }
        });
      }
    });
  }

  private updateTemplates = () => {
    if (this.templates.size > 0) {
      return;
    }

    const slotted = this.element.shadowRoot.querySelectorAll('slot');
    const slottedArray = Array.from(slotted);
    const usedTemplates: Map<string, any> = new Map();
    const templatableSlots = this.templatableComponents.map(comp => comp.slot);
    templatableSlots.forEach(slot => {
      const matchedSlots = slottedArray.filter((s: any) => s.name === slot);
      if (!this.templates.get(slot) && matchedSlots.length > 0 && matchedSlots[0].assignedElements().length > 0) {
        usedTemplates.set(slot, matchedSlots[0].assignedElements()[0]);
      }
    });

    this.templates = usedTemplates;
  }

  private getRootNode(): DocumentOrShadowRoot {
    return this.element.getRootNode() as unknown as DocumentOrShadowRoot;
  }

  private scheduleAfterRender(callback: () => void) {
    this.scheduledCallbacks.push(callback);
  }

  private handleTabHeaderFocus(pane: IgcContentPane) {
    if (!this._tabHeaderIconClicked) {
      this.setActivePane(pane);
    }
  }

  private handleContentPaneFocus(pane: IgcContentPane) {
    this.setActivePane(pane);
  }

  private handleUnpinnedHeaderFocus(pane: IgcContentPane) {
    // delay the setting of the active pane, in case the unpinned tab is clicked it should focus the content pane first
    requestAnimationFrame(() => {
      this.setActivePane(pane);
    });
  }

  private setFocus(pane: IgcContentPane) {
    if (!pane) { return; }
    this.paneToFocus = null;

    let elemToFocus = this.contentPanesElementMap.get(pane);

    if (!elemToFocus) {
      elemToFocus = this.tabHeadersMap.get(pane);
    }

    if (!elemToFocus) {
      elemToFocus = this.unpinnedHeadersMap.get(pane);
    }

    if (!elemToFocus) {
      throw new Error('No matching pane!');
    }

    elemToFocus.focus();
  }

  private setActivePane(pane: IgcContentPane) {
    if (this.activePane !== pane) {
      this.activePaneInternalSet = true;
      this.activePane = pane;
    }
  }

  private removeCustomContentEventListeners() {
    const dockManagerChildren = Array.from(this.element.children);
    dockManagerChildren.forEach(child => {
      child.removeEventListener('focusin', this.handleCustomContentFocusIn);
    });
  }

  private handleCustomContentFocusIn = (eventArgs: FocusEvent) => {
    const parent = eventArgs.currentTarget as HTMLElement;
    if (parent) {
      const contentPane = this.service.clientContentPanesMap.get(parent.slot);
      if (!contentPane) { return; }

      this.setActivePane(contentPane);
    }
  }

  private mutationCallback(records: MutationRecord[]) {
    records.filter(rec => rec.type === 'childList').forEach(child => {
      child.addedNodes.forEach(c => {
        c.addEventListener('focusin', this.handleCustomContentFocusIn);
      });

      child.removedNodes.forEach(r => {
        r.removeEventListener('focusin', this.handleCustomContentFocusIn);
      });
    });
  }

  private _isValidDrop = true;

  get isValidDrop() {
    return this._isValidDrop;
  }

  set isValidDrop(value: boolean) {
    if (this._isValidDrop !== value) {
      this._isValidDrop = value;
      document.body.style.cursor = this._isValidDrop ? 'default' : 'not-allowed';
    }
  }

  private handleShadowRootFocusOut = () => {
    if (this.paneToFocus) {
      return;
    }
    const rootNode = this.getRootNode();

    requestAnimationFrame(() => {
      if (!this.element.shadowRoot.activeElement && rootNode.activeElement === this.element && this.reason !== ActionReason.maximizeOrMinimize) {
        this.clearActivePane();
      }
    });
  }

  private restrictFloatingPaneSize() {
    if (this.containedInBoundaries) {
      const dockManagerRect = this.element.getBoundingClientRect();
      const floatingPanes = Array.from(this.element.shadowRoot.querySelectorAll('igc-floating-pane-component'));

      floatingPanes.forEach(floatingPane => {
        const paneElement = floatingPane.querySelector('igc-split-pane-component');
        const pane = this.panesElementMap.getByValue(paneElement) as IgcSplitPane;

        if (floatingPane.getBoundingClientRect().height > dockManagerRect.height) {
          floatingPane.floatingHeight = pane.floatingHeight = dockManagerRect.height;
          floatingPane.style.height = floatingPane.floatingHeight + 'px';
        }

        if (floatingPane.getBoundingClientRect().bottom > dockManagerRect.bottom) {
          floatingPane.floatingLocation.y = pane.floatingLocation.y = dockManagerRect.bottom - floatingPane.getBoundingClientRect().height;
          floatingPane.style.top = floatingPane.floatingLocation.y + 'px';
        }

        if (floatingPane.getBoundingClientRect().y < dockManagerRect.y) {
          floatingPane.floatingLocation.y = dockManagerRect.y;
          floatingPane.style.top = floatingPane.floatingLocation.y + 'px';
        }

        if (floatingPane.getBoundingClientRect().width > dockManagerRect.width) {
          floatingPane.floatingWidth = dockManagerRect.width;
          floatingPane.style.width = floatingPane.floatingWidth + 'px';
        }

        if (floatingPane.getBoundingClientRect().right > dockManagerRect.right) {
          floatingPane.floatingLocation.x = pane.floatingLocation.x = dockManagerRect.right - floatingPane.getBoundingClientRect().width;
          floatingPane.style.left = floatingPane.floatingLocation.x + 'px';
        }

        if (floatingPane.getBoundingClientRect().left < dockManagerRect.left) {
          floatingPane.floatingLocation.x = dockManagerRect.x;
          floatingPane.style.left = floatingPane.floatingLocation.x + 'px';
        }
      });
    }
  }

  private isPaneSizeWithinConstraints(pane: HTMLElement, offset: number, orientation: IgcSplitPaneOrientation, position: 'previous' | 'next' = 'next'): boolean {
    let size, minSize, maxSize: number;

    if (orientation === IgcSplitPaneOrientation.horizontal) {
      size = pane.getBoundingClientRect().width;
      minSize = parseFloat(getComputedStyle(pane).minWidth);
      maxSize = parseFloat(getComputedStyle(pane).maxWidth);
    } else {
      size = pane.getBoundingClientRect().height;
      minSize = parseFloat(getComputedStyle(pane).minHeight);
      maxSize = parseFloat(getComputedStyle(pane).maxHeight);
    }

    if (position === 'previous') {
      return offset < 0 ? ((size + offset) >= minSize || isNaN(minSize)) : ((size + offset) <= maxSize || isNaN(maxSize))
    }

    return offset > 0 ? ((size - offset) >= minSize || isNaN(minSize)) : ((size - offset) <= maxSize || isNaN(maxSize));
  }

  private handleFlyoutSplitterResizeEnd(pane: IgcContentPane, orientation: IgcSplitPaneOrientation, event: CustomEvent<number>) {
    const splitter = event.target as HTMLElement;
    const previousPane = splitter.previousElementSibling as HTMLElement;
    const minSize = orientation === IgcSplitPaneOrientation.horizontal
      ? parseFloat(getComputedStyle(previousPane).minWidth)
      : parseFloat(getComputedStyle(previousPane).minHeight);

    if (!isNaN(minSize) && (minSize > pane.unpinnedSize || minSize > IGC_DEFAULT_UNPIN_PANE_SIZE)) {
      pane.unpinnedSize = minSize;
    }

    // If new pane size does not meet the size constraints, do not resize
    let offset = this.isPaneSizeWithinConstraints(previousPane, event.detail, orientation, 'previous') ? this.splitterOffset * event.detail : 0;

    this.service.resizeFlyoutPane(offset);

    let rect: DOMRect;    
    rect = splitter.previousElementSibling.getBoundingClientRect();
    const paneWidth = orientation === IgcSplitPaneOrientation.horizontal ? rect.width + offset : rect.width;
    const paneHeight = orientation === IgcSplitPaneOrientation.vertical ? rect.height + offset : rect.height;
    this.splitterResizeEnd.emit({ pane, orientation, paneWidth, paneHeight });
  }

  private handleSplitterResizeStart(pane: IgcDockManagerPane, event: { target: IgcSplitterComponent }) {
    const splitter = event.target;
    const orientation = splitter.splitPaneOrientation;
    let rect: DOMRect;
    const splitterEl = event.target as unknown as HTMLElement;
    rect = pane === this.flyoutPane ? splitterEl.previousElementSibling.getBoundingClientRect() : splitterEl.nextElementSibling.getBoundingClientRect();
    const paneWidth = rect.width;
    const paneHeight = rect.height;
    const resizeStartEvent = this.splitterResizeStart.emit({ pane, orientation, paneWidth, paneHeight });

    if (resizeStartEvent.defaultPrevented) {
      this.splitterOffset = 0;
      splitter.showDragGhost = false;
    } else {
      this.splitterOffset = 1;
      splitter.showDragGhost = true;
    }
  }

  private handleSplitterResizeEnd(parentPane: IgcSplitPane, pane: IgcDockManagerPane, event: CustomEvent<number>) {
    const splitter = event.target as HTMLElement;
    const previousPane = splitter.previousElementSibling as HTMLElement;
    const nextPane = splitter.nextElementSibling as HTMLElement;
    const splitPane = splitter.parentElement;
    const splitPaneChildren = Array.from(splitPane.children);
    const paneComponents = splitPaneChildren.filter(c => c.tagName.toLowerCase() !== 'igc-splitter-component');

    const sizeProperty = parentPane.orientation === IgcSplitPaneOrientation.horizontal ? 'offsetWidth' : 'offsetHeight';
    const sizeSum = paneComponents.reduce((s, p: HTMLElement) => p[sizeProperty] + s, 0);

    // If the new size is smaller or bigger that the size constraints, do not resize
    const offset = this.isPaneSizeWithinConstraints(previousPane, event.detail, parentPane.orientation, 'previous') && this.isPaneSizeWithinConstraints(nextPane, event.detail, parentPane.orientation) ? event.detail : 0;
    let offsetPercentage = this.splitterOffset * offset / sizeSum;

    let rtl = false;
    if (this.element.dir !== '') {
      rtl = this.element.dir === 'rtl';
    } else {
      let parent = this.element.parentElement;
      while (parent) {
        if (parent.dir !== '') {
          rtl = parent.dir === 'rtl';
          break;
        }

        parent = parent.parentElement;
      }
    }

    if (rtl && parentPane.orientation === IgcSplitPaneOrientation.horizontal) {
      offsetPercentage *= -1;
    }

    this.service.resizePane(pane, offsetPercentage);
    const orientation = parentPane.orientation;
    let rect: DOMRect;
    rect = splitter.nextElementSibling.getBoundingClientRect();
    const paneWidth = orientation === IgcSplitPaneOrientation.horizontal ? rect.width - offsetPercentage*sizeSum : rect.width;
    const paneHeight = orientation === IgcSplitPaneOrientation.vertical ? rect.height - offsetPercentage*sizeSum : rect.height;
    this.splitterResizeEnd.emit({ pane, orientation, paneWidth, paneHeight });
  }

  private clearActivePane() {
    if (this.activePane && this.shouldClearActivePane && this.reason !== ActionReason.drop) {
      this.setActivePane(null);
    }
  }

  private handlePinToggle(pane: IgcContentPane) {
    const isPinned = this.service.getActualIsPinned(pane);
    const pinBehavior = !isPinned ? IgcPinBehavior.selectedPane : IgcPinBehavior.allPanes;

    this.shouldClearActivePane = false;
    this.service.togglePin(pane, pinBehavior);
    this.scheduleAfterRender(() => {
      this.shouldClearActivePane = true;
      this.setFocus(pane);
    });
  }

  private handlePaneClose(pane: IgcContentPane) {
    this.focusElement();
    this.service.closePane(pane);
  }

  private handleFloatingPaneClose(pane: IgcSplitPane) {
    this.focusElement();
    this.service.closeFloatingPane(pane);
  }

  private handleMaximize(pane: any) {
    this.service.maximizePane(pane);
    this.scheduleAfterRender(() => {
      const paneToFocus = this.findFocusablePane([pane]);
      this.setFocus(paneToFocus);
    });
  }

  private handleUnpinnedTabMouseDown(pane: IgcContentPane, event: MouseEvent) {
    const target = event.currentTarget as HTMLElement;
    const rootNode = this.getRootNode();
    this.contentPaneId = pane.id;
    requestAnimationFrame(() => {
      if (this.element === rootNode.activeElement || !this.element.contains(rootNode.activeElement)) {
        target.focus();
      }

      this.handlePaneFlyout(pane);
    });
  }

  private handlePaneFlyout(pane: IgcContentPane) {
    this.service.flyoutPane(pane);
  }

  private handleUnpinnedTabKeyDown(pane: IgcContentPane, event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.handlePaneFlyout(pane);
    }
  }

  private handlePaneDragStart(pane: IgcSplitPane | IgcContentPane, event: CustomEvent<IgcDragStartEventArguments>) {
    let rect: DOMRect;

    if (pane.type === IgcDockManagerPaneType.contentPane) {
      const header = event.target as HTMLElement;
      const parent = this.service.getParent(pane);

      rect = (pane !== this.flyoutPane && parent.type === IgcDockManagerPaneType.tabGroupPane) ?
        header.closest('igc-tabs-component').getBoundingClientRect() :
        header.parentElement.getBoundingClientRect();
      this.contentPaneId = pane.contentId;
    }

    this.shouldClearActivePane = false;
    this.focusElement();
    const dragStarted = this.service.dragPaneStart(pane, rect, event.detail.clientX, event.detail.clientY);
    this.isDragging = true;
    this.scheduleAfterRender(() => {
      this.shouldClearActivePane = true;
      if (pane.type === IgcDockManagerPaneType.contentPane) {
        this.contentPaneId = pane.contentId;
        return;
      }
      if (pane.type === IgcDockManagerPaneType.splitPane) {
        const targetPane = this.findFocusablePane(pane.panes);
        if (targetPane) {
          this.contentPaneId = targetPane.contentId;
          this.setActivePane(targetPane);
        }
      }
    });

    if (!dragStarted) {
      this.isDragging = false;
      event.detail.cancel = true;
    } else {
      this.service.dockManagerRect = this.element.getBoundingClientRect();
    }
  }

  private handlePaneDragMove(event: CustomEvent<IgcDragMoveEventArguments>) {
    this.paneDragMoved(event.detail.clientX, event.detail.clientY, event);
  }

  private paneDragMoved(clientX: number, clientY: number, event?: CustomEvent<IgcDragMoveEventArguments>) {
    this.handleDropPositionChange(clientX, clientY, event?.target);

    const x = event?.detail.clientX;
    const y = event?.detail.clientY;

    this.cursorLocation = { x, y };

    if (this.proximityDock) {
      this.service.proximityDockPosition = this.getProximityDockPosition();
      this.service.dropTargetParentRect = this.getInnerDropTargetParentRect();
    }

    const dragMoved = this.service.dragPaneMove(x, y);

    if (event && !dragMoved) {
      event.detail.cancel = true;
    }
  }

  private getInnerDropTargetParentRect() {
    if (this.dropTargetPaneInfo && this.service.proximityDockPosition) {
      const targetDocHostParent = this.service.getParent(this.dropTargetPaneInfo.docHost) as IgcSplitPane;

      // In proximityDock, targetDocHostParent can be undefined when elementsFromPoint returns an empty splitPane meaning the dragged pane is over it.
      const isIndicatorVertical = targetDocHostParent && Utils.isDockingIndicatorVertical(this.service.proximityDockPosition as IgcDockingIndicatorPosition);
      const isSplitPaneVertical = targetDocHostParent && Utils.isSplitPaneVertical(targetDocHostParent);
      const isSameSplitPane = ((isIndicatorVertical && isSplitPaneVertical) || (!isIndicatorVertical && !isSplitPaneVertical));

      // If its over splitter or if docHost without splitter -> outer dock
      // For top/bottom outer dock we need the docHost's parent rect
      // For left/right outer dock we need the docHost's rootPane rect
      // If its not over splitter -> innerDock
      const targetPaneParent = this.allowOuterProximityDock && Utils.isDockingIndicatorOuter(this.service.proximityDockPosition as IgcDockingIndicatorPosition)
        ? isSameSplitPane
          ? targetDocHostParent
          : this.dropTargetPaneInfo.docHost.rootPane
        : this.service.getParent(this.dropTargetPaneInfo.pane);

      const targetPaneParentRect = this.panesElementMap.has(targetPaneParent) ? this.panesElementMap.get(targetPaneParent).getBoundingClientRect() : null;
      return targetPaneParentRect;
    }
  }

  private getProximityDockPosition() {
    const dropTarget = this.dropTargetPaneInfo;

    if (!dropTarget) {
      return null;
    }

    const pane = dropTarget.pane;
    const targetPaneRect = dropTarget.targetRect;
    const centerX = (targetPaneRect.left + targetPaneRect.right) / 2;
    const centerY = (targetPaneRect.top + targetPaneRect.bottom) / 2;
    const isRTL = this.direction === 'rtl';

    const targetPane = pane.type === IgcDockManagerPaneType.tabGroupPane && pane.selectedIndex > -1
      ? pane.panes[pane.selectedIndex]
      : pane;

    const acceptsInnerDock = targetPane.type === IgcDockManagerPaneType.contentPane && targetPane.acceptsInnerDock !== undefined && this.allowInnerDock
      ? targetPane.acceptsInnerDock
      : this.allowInnerDock;

    const isOverSplitter = this.splitterRect
      ? this.cursorLocation.x >= this.splitterRect.left
        && this.cursorLocation.x <= this.splitterRect.right
        && this.cursorLocation.y >= this.splitterRect.top
        && this.cursorLocation.y <= this.splitterRect.bottom
      : false;

    // If outer proximity dock is enabled, check if there is splitter and if mouse is within splitter boundaries.
    if (this.allowOuterProximityDock && this.splitterRect) {
      if (this.cursorLocation.x >= this.splitterRect.left - this.proximityOuterDockThreshold
        && this.cursorLocation.x <= this.splitterRect.left) {
          return IgcDockingIndicatorPosition.outerLeft;
      } else if (this.cursorLocation.x <= this.splitterRect.right + this.proximityOuterDockThreshold
        && this.cursorLocation.x >= this.splitterRect.right) {
          return IgcDockingIndicatorPosition.outerRight;
      }
    }

    const allowOuterTopDock = this.allowOuterProximityDock && this.cursorLocation.y < targetPaneRect.top + this.proximityOuterDockThreshold;
    const allowOuterBottomDock = this.allowOuterProximityDock && this.cursorLocation.y > targetPaneRect.bottom - this.proximityOuterDockThreshold;
    const allowOuterLeftDock = this.allowOuterProximityDock && this.cursorLocation.x < targetPaneRect.left + this.proximityOuterDockThreshold;
    const allowOuterRightDock = this.allowOuterProximityDock && this.cursorLocation.x > targetPaneRect.right - this.proximityOuterDockThreshold;

    if (this.cursorLocation.y > targetPaneRect.bottom - this.proximityDockThreshold
      && this.cursorLocation.x - targetPaneRect.left > targetPaneRect.bottom - this.cursorLocation.y
      && targetPaneRect.right - this.cursorLocation.x > targetPaneRect.bottom - this.cursorLocation.y
      && !isOverSplitter ) {
        return allowOuterBottomDock ? IgcDockingIndicatorPosition.outerBottom : IgcDockingIndicatorPosition.bottom;
    } else if (this.cursorLocation.y < targetPaneRect.top + this.proximityDockThreshold
      && this.cursorLocation.x - targetPaneRect.left > this.cursorLocation.y - targetPaneRect.top
      && targetPaneRect.right - this.cursorLocation.x > this.cursorLocation.y - targetPaneRect.top
      && !isOverSplitter) {
        return allowOuterTopDock ? IgcDockingIndicatorPosition.outerTop : IgcDockingIndicatorPosition.top;
    } else if (acceptsInnerDock
      && this.cursorLocation.x > centerX - this.proximityDockThreshold
      && this.cursorLocation.x < centerX + this.proximityDockThreshold
      && this.cursorLocation.y > centerY - this.proximityDockThreshold
      && this.cursorLocation.y < centerY + this.proximityDockThreshold
      && !isOverSplitter ) {
        return IgcDockingIndicatorPosition.center;
    } else if (!isRTL && this.cursorLocation.x < targetPaneRect.left + this.proximityDockThreshold
      && this.cursorLocation.y - targetPaneRect.top > this.cursorLocation.x - targetPaneRect.left
      && targetPaneRect.bottom - this.cursorLocation.y > this.cursorLocation.x - targetPaneRect.left) {
        return allowOuterLeftDock ? IgcDockingIndicatorPosition.outerLeft : IgcDockingIndicatorPosition.left;
    } else if (!isRTL && this.cursorLocation.x > targetPaneRect.right - this.proximityDockThreshold
      && this.cursorLocation.y - targetPaneRect.top > targetPaneRect.right - this.cursorLocation.x
      && targetPaneRect.bottom - this.cursorLocation.y > targetPaneRect.right - this.cursorLocation.x) {
        return allowOuterRightDock ? IgcDockingIndicatorPosition.outerRight : IgcDockingIndicatorPosition.right;
    } else if (isRTL && this.cursorLocation.x > targetPaneRect.right - this.proximityDockThreshold
      && targetPaneRect.bottom - this.cursorLocation.y > this.cursorLocation.x - targetPaneRect.right
      && this.cursorLocation.y - targetPaneRect.top > this.cursorLocation.x - targetPaneRect.right) {
        return allowOuterRightDock ? IgcDockingIndicatorPosition.outerRight : IgcDockingIndicatorPosition.right;
    } else if (isRTL && this.cursorLocation.x < targetPaneRect.left + this.proximityDockThreshold
      && targetPaneRect.bottom - this.cursorLocation.y > targetPaneRect.left - this.cursorLocation.x
      && this.cursorLocation.y - targetPaneRect.top > targetPaneRect.left - this.cursorLocation.x) {
        return allowOuterLeftDock ? IgcDockingIndicatorPosition.outerLeft : IgcDockingIndicatorPosition.left;
    }

    return null;
  }

  private handlePaneDragEnd(): boolean {
    const docked = this.service.dragPaneEnd();
    this.isDragging = false;

    if (docked) {
      const activePane = this.activePane;
      this.shouldClearActivePane = false;
      this.reason = ActionReason.drop;
      this.focusElement();
      if (activePane) {
        this.scheduleAfterRender(() => {
          this.setActivePane(activePane);
        });
      }

      this.scheduleAfterRender(() => {
        this.shouldClearActivePane = true;
      });
    }

    if (this.activePane) {
      this.setFocus(this.activePane);
    }

    return docked;
  }

  private handleTabHeaderDragStart(pane: IgcContentPane, event: CustomEvent<IgcDragStartEventArguments>) {
    this.shouldMovePane = false;

    const path = this.service.getPanePath(pane);
    const rootParent = path[0] as IgcSplitPane;
    if (rootParent !== this.layout.rootPane &&
      path.some(p => p.type === IgcDockManagerPaneType.documentHost)) {
      const childPanes = this.service.getChildContentPanes(rootParent);
      if (childPanes.length === 1) {
        // pane is a single document host tab in a floating pane
        this.shouldMovePane = true;

        return this.handlePaneDragStart(rootParent, event);
      }
    }

    this.shouldClearActivePane = false;
    this.focusElement();
    const dragStarted = this.service.dragTabStart(pane);
    this.isDragging = true;
    this.scheduleAfterRender(() => {
      this.shouldClearActivePane = true;
    });

    if (!dragStarted) {
      this.isDragging = false;
      event.detail.cancel = true;
    }
  }

  private handleTabHeaderDragMove(pane: IgcContentPane, event: CustomEvent<IgcDragMoveEventArguments>) {
    if (this.shouldMovePane) {
      return this.handlePaneDragMove(event);
    }

    const header = event.target as HTMLIgcTabHeaderComponentElement;
    const headerRect = header.getBoundingClientRect();
    const tabs = header.closest('igc-tabs-component');
    const tabsRect = tabs.getBoundingClientRect();
    const tabHeaders = Array.from(tabs.querySelectorAll('igc-tab-header-component'));
    const headerIndex = tabHeaders.indexOf(header);

    const prevHeaderRect = headerIndex > 0 ? tabHeaders[headerIndex - 1].getBoundingClientRect() : null;
    const nextHeaderRect = headerIndex < tabHeaders.length - 1 ? tabHeaders[headerIndex + 1].getBoundingClientRect() : null;
    const lastVisibleHeaderRect = tabHeaders.filter(th => th.getBoundingClientRect().top === headerRect.top).slice(-1)[0].getBoundingClientRect();

    const tabRectsInfo = {
      headerRect,
      prevHeaderRect,
      nextHeaderRect,
      lastVisibleHeaderRect,
      tabsRect
    };

    this.handleDropPositionChange(event.detail.clientX, event.detail.clientY, event?.target);
    const tabMoved = this.service.dragTabMove(pane, event.detail, tabRectsInfo);

    if (event && !tabMoved) {
      event.detail.cancel = true;
    }
  }

  private handleTabHeaderDragEnd(pane: IgcContentPane) {
    const docked = this.handlePaneDragEnd();

    if (!docked) {
      this.activePane = pane;
    }
  }

  private handleFloatingPaneResizeStart(pane: IgcSplitPane, event: CustomEvent<IgcDragResizeEventArguments>) {
    const args = event.detail;

    this.isDragging = true;

    const resizeStarted = this.service.resizeFloatingPaneStart(pane, args.resizerLocation);
    if (!resizeStarted) {
      this.isDragging = false;
      args.dragMoveArgs.cancel = true;
    }
  }

  private handleFloatingPaneResizeMove(pane: IgcSplitPane, event: CustomEvent<IgcDragResizeEventArguments>) {
    this.service.resizeFloatingPane(pane, event.detail);
  }

  private handleFloatingPaneResizeEnd(pane: IgcSplitPane, event: CustomEvent<IgcDragResizeEventArguments>) {
    this.isDragging = false;
    this.service.resizeFloatingPaneEnd(pane, event.detail.resizerLocation);
  }

  private handleDropPositionChange(clientX: number, clientY: number, target?: EventTarget) {
    const elements = clientX && clientY ?
      this.element.shadowRoot.elementsFromPoint(clientX, clientY) :
      null;

    let isOverSplitter = false;
    this.splitterRect = null;

    const splitter = elements ? elements.filter(e => e.tagName.toLowerCase() === 'igc-splitter-component') : [];

    if (splitter.length > 0) {
      const splitterIndex = elements.indexOf(splitter[0]);
      const adjacentElement = elements[splitterIndex - 1];
      isOverSplitter = adjacentElement &&
        adjacentElement.tagName.toLowerCase() === 'igc-floating-pane-component' &&
        (adjacentElement as any).floatingId === this.draggedPane?.id; // if not equal - splitter is below another element, not directly over
    }

    if (this.proximityDock) {
      const docHost = elements ? elements.filter(e => e.tagName.toLowerCase() === 'igc-document-host-component') : [];

      if (docHost.length > 0) {
        const splitter = docHost[0].querySelector('igc-splitter-component');

        if (splitter) {
          const splitterRect = splitter.getBoundingClientRect();
          this.splitterRect = splitterRect;
        }

        this.allowOuterProximityDock = true;
      } else {
        this.allowOuterProximityDock = false;
      }
    }

    if (!elements || !elements.length || (isOverSplitter && this.splitterRect === null)) {
      this.service.dockingIndicator = null;
      this.dropTargetPaneInfo = null;
      return;
    }

    const topElement = elements[0];
    let indicatorTarget = null;
    let dockingIndicatorElement: HTMLElement = topElement.closest('igc-root-docking-indicator-component');

    if (dockingIndicatorElement) {
      this.service.dockingIndicator = {
        position: (dockingIndicatorElement as HTMLIgcRootDockingIndicatorComponentElement).position,
        isRoot: true,
        direction: this.direction
      };
    }

    if (!dockingIndicatorElement) {
      const joystickIcon = topElement.closest('igc-joystick-icon-component');

      if (joystickIcon && !joystickIcon.empty) {
        dockingIndicatorElement = joystickIcon;
        this.service.dockingIndicator = {
          position: joystickIcon.position,
          isRoot: false,
          direction: this.direction
        };
        const joystickIndicator = joystickIcon.closest('igc-joystick-indicator-component');
        indicatorTarget = joystickIndicator.dropTargetPaneInfo;
      }
    }

    if (dockingIndicatorElement &&
      (this.service.dockingIndicator.isRoot || this.dropTargetPaneInfo === indicatorTarget)) {

      this.service.dropTargetParentRect = this.getDropTargetParentRect();
      return;
    }

    this.service.dockingIndicator = null;

    if (target) {
      const draggedHeader = target as HTMLElement;
      const draggedFloatingPane = draggedHeader.closest('igc-floating-pane-component');
      const topFloatingPane = elements.find(e => e.tagName.toLowerCase() === 'igc-floating-pane-component');

      if (topFloatingPane === draggedFloatingPane) {
        const index = elements.lastIndexOf(draggedFloatingPane);
        elements.splice(0, index + 1);
      }
    }

    let paneElement: HTMLElement;

    for (const element of elements) {
      const tagName = element.tagName.toLowerCase();

      if (tagName === 'igc-content-pane-component' || tagName === 'igc-split-pane-component') {
        if (paneElement) {
          break;
        }
        paneElement = element as HTMLElement;
        // Not sure why we dont break after finding split-pane first. This works with proximityDock so its temporarily here.
        // When we are over splitter and proximityDock is true, break after finding the first suitable splitPane.
        if (this.allowOuterProximityDock) {
          break;
        }

      } else if (tagName === 'igc-tabs-component' || tagName === 'igc-document-host-component') {
        paneElement = element as HTMLElement;
        break;
      } else if (tagName === 'igc-floating-pane-component') {
        break;
      }
    }

    if (paneElement) {
      const dropPane = this.panesElementMap.getByValue(paneElement);

      if ((!this.dropTargetPaneInfo || this.dropTargetPaneInfo.pane !== dropPane) && dropPane !== this.draggedPane) {
        const docHost = this.service.getDocHostParent(dropPane);
        const dropPaneRoot = this.service.getRootParent(dropPane);
        const floatingPaneWithoutDocHost = this.layout.floatingPanes
          ? this.layout.floatingPanes.indexOf(dropPaneRoot) !== -1 &&
            !this.service.getChildDocHostRecursive(dropPaneRoot)
          : false;

        if (!this.documentOnlyDrag
          || docHost
          || floatingPaneWithoutDocHost
          || dropPane.type === IgcDockManagerPaneType.documentHost) {
          this.dropTargetPaneInfo = {
            pane: dropPane,
            targetRect: paneElement.getBoundingClientRect(),
            docHost,
            floatingPaneWithoutDocHost
          };
        } else {
          this.dropTargetPaneInfo = null;
        }
      }
    } else {
      this.dropTargetPaneInfo = null;
    }
  }

  private getDropTargetParentRect() {
    const position = this.service.dockingIndicator.position;
    let rect;
    if (this.service.dockingIndicator.isRoot) {
      rect = this.dockedPanesContainer.getBoundingClientRect();
    } else {
      if (Utils.isDockingIndicatorOuter(position as IgcDockingIndicatorPosition)) {
        const targetDocHostParent = this.service.getParent(this.dropTargetPaneInfo.docHost) as IgcSplitPane;
        const isIndicatorVertical = Utils.isDockingIndicatorVertical(position as IgcDockingIndicatorPosition);
        const isSplitPaneVertical = Utils.isSplitPaneVertical(targetDocHostParent);
        const isSameSplitPane = ((isIndicatorVertical && isSplitPaneVertical) || (!isIndicatorVertical && !isSplitPaneVertical));
        const outerParent = isSameSplitPane ? targetDocHostParent : this.dropTargetPaneInfo.docHost.rootPane;
        const outerParentRect = this.panesElementMap.has(outerParent) ? this.panesElementMap.get(outerParent).getBoundingClientRect() : null;
        rect = outerParentRect;
      } else {
        const targetPaneParent = this.service.getParent(this.dropTargetPaneInfo.pane);
        const targetPaneParentRect = this.panesElementMap.has(targetPaneParent) ? this.panesElementMap.get(targetPaneParent).getBoundingClientRect() : null;
        rect = targetPaneParentRect;
      }
    }

    return rect;
  }

  private handlePaneContentMouseDown(pane: IgcContentPane) {
    requestAnimationFrame(() => {
      const rootNode = this.getRootNode();
      if (this.element === rootNode.activeElement || !this.element.contains(rootNode.activeElement)) {
        this.setFocus(pane);
        this.contentPaneId = pane.contentId;
      }
    });
  }

  private handlePaneContentScroll(pane: IgcContentPane, event: CustomEvent<IgcPaneScrollEventArgs>) {
    const contentElement = event.target as HTMLElement;
    const args: IgcPaneScrollEventArgs = {pane, contentElement: contentElement}

    this.paneScroll.emit(args);
  }

  private handlePaneHeaderMouseDown(pane: IgcContentPane) {
    const rootNode = this.getRootNode();
    requestAnimationFrame(() => {
      this.contentPaneId = pane.contentId;
      if (this.element === rootNode.activeElement || !this.element.contains(rootNode.activeElement)) {
        const paneElement = this.contentPanesElementMap.get(pane);
        paneElement.focus();
      } else {
        this.setActivePane(pane);
      }
    });
  }

  private handleTabHeaderMouseDown(pane: IgcContentPane, event: MouseEvent) {
    const target = event.currentTarget as HTMLElement;
    const rootNode = this.getRootNode();

    const pathHTMLElements = event.composedPath().filter(et => et instanceof HTMLElement).map(et => et as HTMLElement);
    this._tabHeaderIconClicked = this.showHeaderIconOnHover &&
      pathHTMLElements.filter(el => el.attributes.getNamedItem('part') && el.attributes.getNamedItem('part').value === 'tab-header-more-options floating').length > 0;

    requestAnimationFrame(() => {
      this.contentPaneId = pane.contentId;
      this.reason = ActionReason.click;
      if (this.element === rootNode.activeElement || !this.element.contains(rootNode.activeElement)) {
        target.focus();
      } else {
        this.setActivePane(pane);
      }
    });
  }

  private handleFloatingPaneMouseDown(pane: IgcSplitPane, event: MouseEvent) {
    const pathHTMLElements = event.composedPath().filter(e => e instanceof HTMLElement).map(e => e as HTMLElement);
    const isContextMenu = pathHTMLElements.filter(e => e.attributes.getNamedItem('part') && e.attributes.getNamedItem('part').value === 'context-menu').length > 0;
    if (isContextMenu) {
      return;
    }

    this.service.bringFloatingPaneOnTop(pane);

    const isTabs = pathHTMLElements.filter(e => e.attributes.getNamedItem('part') && e.attributes.getNamedItem('part').value === 'tabs-container').length > 0;
    if (isTabs) {
      return;
    }

    const isFloatingPaneHeader = pathHTMLElements.filter(e => e.attributes.getNamedItem('part') && e.attributes.getNamedItem('part').value === 'pane-header floating window').length > 0;
    if (isFloatingPaneHeader) {
      let targetPane: IgcContentPane;
      let parent = this.service.getParent(this.activePane);
      while (parent) {
        if (parent === pane) {
          targetPane = this.activePane;
          break;
        }

        parent = this.service.getParent(parent);
      }

      requestAnimationFrame(() => {
        if (!targetPane) {
          targetPane = this.findFocusablePane(pane.panes);
        }

        if (!targetPane) {
          targetPane = pane.panes[0] as IgcContentPane;
        }
        const nodeName = (event.target as HTMLElement).nodeName.toLowerCase();
        if (nodeName === 'igc-dockmanager') {
          this.setActivePane(targetPane);
          this.setFocus(targetPane);
        }

        this.contentPaneId = targetPane.contentId;
      });
    }
  }

  private handleTabIconClick(p: IgcContentPane, position: IgcTabHeadersPosition, isFloating: boolean, event: CustomEvent<MouseEvent>) {
    if (!this.showHeaderIconOnHover) {
      this.focusElement();
    }

    if (!isFloating && position === IgcTabHeadersPosition.bottom) {
      const elem = event.detail.target as HTMLElement;

      const tabHeader = elem.closest('igc-tab-header-component');
      const moreOptionsContainer: HTMLElement = tabHeader ?
        tabHeader.shadowRoot.querySelector('div[part*="tab-header-more-options"]') :
        elem.closest('div[part*="tab-header-more-options"]');

      this.contextMenuMeta = { target: moreOptionsContainer, menuItems: this.service.createContextMenuItems(p), position: this.contextMenuPosition };
    } else {
      this.contextMenuMeta = null;
      this.service.closeTabPane(p);
    }
  }

  private handleContextMenuClosed() {
    this.contextMenuMeta = null;
  }

  private handleTabSelectedIndexChanged(pane: IgcTabGroupPane, event: CustomEvent<number>) {
    pane.selectedIndex = event.detail;
  }

  private handleHiddenTabSelected(pane: IgcTabGroupPane, event: CustomEvent<number>) {
    this.activePane = this.service.getVisibleContentPanes(pane)[event.detail];
  }

  private handleSelectedTabOutOfView(pane: IgcTabGroupPane, event: CustomEvent<number>) {
    const cp = this.service.getVisibleContentPanes(pane)[event.detail];
    this.service.selectHiddenTab(pane, cp);
  }

  private handleTabIconKeyDown(iconName: string, p: IgcContentPane, ev: CustomEvent<KeyboardEvent>) {
    if (iconName === 'arrow_drop_down' && ev.detail.key === 'ArrowDown') {
      const targetElem = ev.detail.target as HTMLElement;
      const tabHeader = targetElem.closest('igc-tab-header-component');
      const moreOptionsContainer: HTMLElement = tabHeader ?
        tabHeader.shadowRoot.querySelector('div[part="tab-header-more-options"]') :
        targetElem.closest('div[part="tab-header-more-options"]');

      this.contextMenuMeta = { target: moreOptionsContainer, menuItems: this.service.createContextMenuItems(p), position: this.contextMenuPosition };
    }
  }

  private handleTabSelectedChanged(pane: IgcContentPane, ev: CustomEvent<boolean>) {
    if (ev.detail && pane === this.paneToFocus) {
      this.setFocus(pane);
    }
  }

  private emitPaneHeaderConnected(pane: IgcContentPane, event: CustomEvent<IgcPaneHeaderElement>) {
    this.paneHeaderConnected.emit({
      pane,
      element: event.detail
    });
  }

  private emitPaneHeaderDisconnected(pane: IgcContentPane, event: CustomEvent<IgcPaneHeaderElement>) {
    this.paneHeaderDisconnected.emit({
      pane,
      element: event.detail
    });
  }

  private emitTabHeaderConnected(pane: IgcContentPane, event: CustomEvent<IgcTabHeaderElement>) {
    this.tabHeaderConnected.emit({
      pane,
      element: event.detail
    });
  }

  private emitTabHeaderDisconnected(pane: IgcContentPane, event: CustomEvent<IgcTabHeaderElement>) {
    this.tabHeaderDisconnected.emit({
      pane,
      element: event.detail
    });
  }

  private resolveAllowMaximize(pane: IgcDockManagerPane): boolean {
    return this.service.resolveAllowMaximize(pane);
  }

  private removeHoveredPane() {
    this.hoveredPane = null;
  }

  private togglePaneHeaderVisibility(pane: IgcContentPane, event: MouseEvent) {
    const paneRect = this.contentPanesElementMap.get(pane).getBoundingClientRect();
    const withinPane = event.clientY >= paneRect.top && event.clientY < paneRect.top + PANE_HEADER_HEIGHT;

    if (withinPane) {
      this.hoveredPane = pane;
    } else {
      this.removeHoveredPane();
    }
  }

  private renderButtonsTemplates() {
    return (
      <template>
        <slot name="paneHeaderCloseButton" onSlotchange={this.updateTemplates}></slot>
        <slot name="tabHeaderCloseButton" onSlotchange={this.updateTemplates}></slot>
        <slot name="closeButton" onSlotchange={this.updateTemplates}></slot>
        <slot name="moreTabsButton" onSlotchange={this.updateTemplates}></slot>
        <slot name="maximizeButton" onSlotchange={this.updateTemplates}></slot>
        <slot name="minimizeButton" onSlotchange={this.updateTemplates}></slot>
        <slot name="pinButton" onSlotchange={this.updateTemplates}></slot>
        <slot name="unpinButton" onSlotchange={this.updateTemplates}></slot>
        <slot name="moreOptionsButton" onSlotchange={this.updateTemplates}></slot>
        <slot name="splitterHandle" onSlotchange={this.updateTemplates}></slot>
      </template>
      );
  }

  private renderContentPane(pane: IgcContentPane, isFloating: boolean, isFlyout: boolean) {
    const parentPane = this.service.getParent(pane);

    let forceDrag = false;
    let isSingleFloatingContentPane = false;
    let floatingPane: IgcSplitPane;

    if (isFloating) {
      floatingPane = this.service.getRootParent(pane);
      const hasHeader = this.service.hasFloatingPaneHeader(floatingPane);

      if (!hasHeader) {
        isSingleFloatingContentPane = true;

        if (this.service.forceDragPane === floatingPane) {
          this.service.forceDragPane = null;
          forceDrag = true;
        }
      }
    }

    const maximized = this.maximizedPane === pane ||
      (parentPane.type === IgcDockManagerPaneType.tabGroupPane && this.maximizedPane === parentPane) ||
      isFloating && isSingleFloatingContentPane && floatingPane === this.maximizedPane;

    return (
      <igc-content-pane-component
        key={pane.id}
        contentId={pane.contentId}
        size={pane.size}
        isFlyout={isFlyout}
        unpinnedSize={pane.unpinnedSize}
        disabled={pane.disabled}
        isSingleFloating={isSingleFloatingContentPane}
        onMouseMove={this.togglePaneHeaderVisibility.bind(this, pane)}
        onMouseLeave={this.removeHoveredPane.bind(this)}
        ref={el => {
          if (el) {
            this.panesElementMap.set(pane, el);
            this.contentPanesElementMap.set(pane, el);
          }
        }}
        class={{
          'maximized': this.maximizedPane === pane
        }}
        header={pane.header}
        onFocusin={this.handleContentPaneFocus.bind(this, pane)}
      >
        <igc-pane-header-component
          slot="header"
          pane={pane}
          isActive={pane === this.activePane}
          disabled={pane.disabled && !isSingleFloatingContentPane}
          pinned={this.service.getActualIsPinned(pane)}
          allowMaximize={this.resolveAllowMaximize(pane)}
          maximized={maximized}
          allowClose={this.service.getActualAllowClose(pane)}
          allowPinning={pane.allowPinning !== false}
          isFloating={isFloating}
          forcedDrag={forceDrag}
          resourceStrings={this.resourceStrings}
          onPinToggle={this.handlePinToggle.bind(this, pane)}
          onMaximize={this.handleMaximize.bind(this, pane)}
          onDragMoved={this.handlePaneDragMove.bind(this)}
          onDragStarted={this.handlePaneDragStart.bind(this, isSingleFloatingContentPane ? floatingPane : pane)}
          onDragEnded={this.handlePaneDragEnd.bind(this)}
          onClose={this.handlePaneClose.bind(this, pane)}
          onElementConnected={this.emitPaneHeaderConnected.bind(this, pane)}
          onElementDisconnected={this.emitPaneHeaderDisconnected.bind(this, pane)}
          onMouseDown={this.handlePaneHeaderMouseDown.bind(this, pane)}
          class={{
            'hidden': this.showPaneHeaders === 'onHoverOnly' && this.hoveredPane !== pane,
            'transitioned': this.showPaneHeaders === 'onHoverOnly' && this.hoveredPane === pane
          }}
        >
          {(isFloating && pane.floatingHeaderId) ? <slot name={pane.floatingHeaderId}></slot> :
            pane.headerId ? <slot name={pane.headerId}></slot> :
              pane.header}
        </igc-pane-header-component>
        <div
          class="content"
          onMouseDown={this.handlePaneContentMouseDown.bind(this, pane)}
          onScroll={this.handlePaneContentScroll.bind(this, pane)}
        >
          <slot name={pane.contentId}></slot>
        </div>
      </igc-content-pane-component>
    );
  }

  private renderSplitter(parentPane: IgcSplitPane, pane: IgcDockManagerPane) {
    return (
      <igc-splitter-component
        splitPaneOrientation={parentPane.orientation}
        onResizeStart={this.handleSplitterResizeStart.bind(this, pane)}
        onResizeEnd={this.handleSplitterResizeEnd.bind(this, parentPane, pane)}
        onFocusin={this.clearActivePane.bind(this)}
      >
      </igc-splitter-component >
    );
  }

  private renderDocumentHost(docHost: IgcDocumentHost) {
    return (
      <igc-document-host-component
        key={docHost.id}
        size={docHost.size}
        ref={el => {
          if (el) {
            this.panesElementMap.set(docHost, el);
          }
        }}
      >
        {this.renderSplitPane(docHost.rootPane, false, true)}
      </igc-document-host-component>
    );
  }

  private renderTabGroup(pane: IgcTabGroupPane | IgcContentPane, isFloating: boolean, isInDocumentHost: boolean) {
    let tabs = pane.type === IgcDockManagerPaneType.tabGroupPane ? pane.panes : [pane];
    tabs = tabs.filter(t => this.service.isContentPaneVisible(t));
    const selectedIndex = pane.type === IgcDockManagerPaneType.tabGroupPane && pane.selectedIndex ?
      pane.selectedIndex : 0;
    const position = isInDocumentHost ? IgcTabHeadersPosition.top : IgcTabHeadersPosition.bottom;
    const isSingleTab = tabs.length === 1 && position === IgcTabHeadersPosition.bottom;
    const allowEmpty = pane.type === IgcDockManagerPaneType.tabGroupPane ? pane.allowEmpty : false;
    const allowMaximize = this.resolveAllowMaximize(pane);

    let contentIds = [];
    if (pane.type === IgcDockManagerPaneType.tabGroupPane) {
      pane.panes.forEach(element => {
        contentIds.push(element.contentId);
      });
    } else {
      contentIds.push(pane.contentId);
    }

    return (allowEmpty || tabs.length > 0) && (
      <igc-tabs-component
        key={pane.id}
        contentIds={contentIds}
        size={pane.size}
        selectedIndex={selectedIndex}
        hasHeaders={!isSingleTab}
        maximized={this.maximizedPane === pane}
        allowMaximize={position === IgcTabHeadersPosition.top && allowMaximize}
        onMaximizeMinimizeFocus={this.clearActivePane.bind(this)}
        onMaximize={this.handleMaximize.bind(this, pane)}
        onSelectedIndexChanged={this.handleTabSelectedIndexChanged.bind(this, pane)}
        onHiddenTabSelected={this.handleHiddenTabSelected.bind(this, pane)}
        onSelectedTabOutOfView={this.handleSelectedTabOutOfView.bind(this, pane)}
        onRendered={this.handleTabsRendered.bind(this, pane)}
        tabHeadersPosition={position}
        resourceStrings={this.resourceStrings}
        ref={el => {
          if (el) {
            this.panesElementMap.set(pane, el);
          }
        }}
        class={{
          'maximized': this.maximizedPane === pane,
        }}
      >
        {isSingleTab ?
          this.renderTabPanel(tabs[0], false, isFloating) :
          tabs.map(p => {
            return [
              this.renderTabHeader(p, position, isFloating),
              this.renderTabPanel(p, isInDocumentHost, isFloating)
            ];
          })}
      </igc-tabs-component>
    );
  }

  private renderTabPanel(pane: IgcContentPane, isInDocumentHost: boolean, isFloating: boolean) {
    return (
      <igc-tab-panel-component
        key={pane.id}
        disabled={pane.disabled}
        onMouseDown={isInDocumentHost ? this.handlePaneContentMouseDown.bind(this, pane) : null}
        onSelectedChanged={this.handleTabSelectedChanged.bind(this, pane)}
        onScroll={isInDocumentHost ? this.handlePaneContentScroll.bind(this, pane) : null}
      >
        {isInDocumentHost ?
          <slot name={pane.contentId}></slot> :
          this.renderContentPane(pane, isFloating, false)}
      </igc-tab-panel-component>);
  }

  private renderTabHeader(pane: IgcContentPane, position: IgcTabHeadersPosition, isFloating: boolean) {
    let forceDrag = false;
    const allowClose = this.service.getActualAllowClose(pane);
    const allowPinning = pane.allowPinning !== false;
    const iconName = isFloating ?
      allowClose ? 'close' : null :
      position === IgcTabHeadersPosition.bottom ?
        allowClose || allowPinning ? 'arrow_drop_down' : null :
        allowClose ? 'close' : null;

    if (this.service.forceDragTabHeader === pane) {
      this.service.forceDragTabHeader = null;
      forceDrag = true;
    }

    return (
      <igc-tab-header-component
        key={pane.id}
        slot="tabHeader"
        isActive={pane === this.activePane}
        disabled={pane.disabled}
        showHeaderIconOnHover={this.showHeaderIconOnHover}
        header={pane.header}
        resourceStrings={this.resourceStrings}
        onDragStarted={this.handleTabHeaderDragStart.bind(this, pane)}
        onDragMoved={this.handleTabHeaderDragMove.bind(this, pane)}
        onDragEnded={this.handleTabHeaderDragEnd.bind(this, pane)}
        onMouseDown={this.handleTabHeaderMouseDown.bind(this, pane)}
        onIconKeyDown={this.handleTabIconKeyDown.bind(this, iconName, pane)}
        position={position}
        onIconClicked={this.handleTabIconClick.bind(this, pane, position, isFloating)}
        iconName={iconName}
        forcedDrag={forceDrag}
        onElementConnected={this.emitTabHeaderConnected.bind(this, pane)}
        onElementDisconnected={this.emitTabHeaderDisconnected.bind(this, pane)}
        ref={el => {
          if (el) {
            this.tabHeadersMap.set(pane, el);
          }
        }}
        onFocusin={this.handleTabHeaderFocus.bind(this, pane)}
      >
        {
          pane.tabHeaderId ?
            <slot name={pane.tabHeaderId}></slot> :
            <span part="header-title" title={pane.header}>{pane.header}</span>
        }
      </igc-tab-header-component>);
  }

  private renderSplitPane(splitPane: IgcSplitPane, isFloating: boolean, isInDocumentHost: boolean) {
    const panes = this.service.getSplitPaneVisibleChildren(splitPane);

    return (splitPane.allowEmpty || panes.length > 0) && (
      <igc-split-pane-component
        key={splitPane.id}
        orientation={splitPane.orientation}
        size={splitPane.size}
        onRendered={this.handleSplitPaneRendered.bind(this, panes)}
        ref={el => {
          if (el) {
            this.panesElementMap.set(splitPane, el);
          }
        }}
      >
        {panes.map((p, i) => {
          let paneComponent;

          if (p.type === IgcDockManagerPaneType.splitPane) {
            paneComponent = this.renderSplitPane(p, isFloating, isInDocumentHost);
          } else if (p.type === IgcDockManagerPaneType.contentPane) {
            paneComponent = isInDocumentHost ?
              this.renderTabGroup(p, isFloating, true) :
              this.renderContentPane(p, isFloating, false);
          } else if (p.type === IgcDockManagerPaneType.documentHost) {
            paneComponent = this.renderDocumentHost(p);
          } else if (p.type === IgcDockManagerPaneType.tabGroupPane) {
            paneComponent = this.renderTabGroup(p, isFloating, isInDocumentHost);
          }

          return i > 0 ?
            [this.renderSplitter(splitPane, p), paneComponent] :
            paneComponent;
        })}
      </igc-split-pane-component>
    );
  }

  private renderUnpinnedTabArea(location: IgcUnpinnedLocation) {
    const panes: IgcContentPane[] = [];
    const isHorizontal = location === IgcUnpinnedLocation.top || location === IgcUnpinnedLocation.bottom;
    const isLeft = location === IgcUnpinnedLocation.left;
    const isRight = location === IgcUnpinnedLocation.right;
    const isBottom = location === IgcUnpinnedLocation.bottom;

    this.service.unpinnedLocationMap.forEach((l, p) => {
      if (l === location && p.hidden !== true) {
        panes.push(p);
      }
    });

    const tabAreaClasses = {
      'unpinned-tab-area': true,
      'unpinned-tab-area--left': isLeft,
      'unpinned-tab-area--right': isRight,
      'unpinned-tab-area--bottom': isBottom,
      'unpinned-tab-area--horizontal': isHorizontal,
      'unpinned-tab-area--hidden': panes.length === 0
    };

    return (
      <div
        class={tabAreaClasses}
        part={Utils.partNameMap(tabAreaClasses)}
        role="tablist"
        aria-orientation={isHorizontal ? 'horizontal' : 'vertical'}
      >
        {panes.map(p => (
          <igc-unpinned-pane-header-component
            location={location}
            isActive={p === this.activePane}
            disabled={p.disabled}
            onMouseDown={this.handleUnpinnedTabMouseDown.bind(this, p)}
            onKeyDown={this.handleUnpinnedTabKeyDown.bind(this, p)}
            onFocus={this.handleUnpinnedHeaderFocus.bind(this, p)}
            ref={el => {
              if (el) {
                const slot = el.querySelector('slot');
                if (slot && p.unpinnedHeaderId) {
                  slot.name = p.unpinnedHeaderId;
                }
                this.unpinnedHeadersMap.set(p, el);
              }
            }}
          >
            {p.unpinnedHeaderId ? <slot name={p.unpinnedHeaderId} /> : p.header}
          </igc-unpinned-pane-header-component>
        ))}
      </div>
    );
  }

  private renderFlyoutPane() {
    const location = this.service.unpinnedLocationMap.get(this.flyoutPane);
    const flyoutClasses = {
      'flyout-pane': true,
      'flyout-pane--right': location === IgcUnpinnedLocation.right,
      'flyout-pane--bottom': location === IgcUnpinnedLocation.bottom,
      'flyout-pane--left': location === IgcUnpinnedLocation.left,
    };

    let splitPaneOrientation = IgcSplitPaneOrientation.vertical;

    if (location === IgcUnpinnedLocation.left || location === IgcUnpinnedLocation.right) {
      splitPaneOrientation = IgcSplitPaneOrientation.horizontal;
    }

    const maximized = this.maximizedPane === this.flyoutPane;

    return this.flyoutPane && (
      <div class={flyoutClasses} style={{ 'z-index': maximized ? '10002' : '2' }}>
        {this.renderContentPane(this.flyoutPane, false, true)}
        <igc-splitter-component
          flyoutLocation={location}
          splitPaneOrientation={splitPaneOrientation}
          onResizeStart={this.handleSplitterResizeStart.bind(this, this.flyoutPane, )}
          onResizeEnd={this.handleFlyoutSplitterResizeEnd.bind(this, this.flyoutPane, splitPaneOrientation)}
        />
      </div>
    );
  }

  private renderFloatingPanes() {
    return (
      <div class="floating-panes" style={{ position: this.maximizedPane ? 'absolute' : 'fixed' }}>
        {this.layout?.floatingPanes?.map(p => {
          const panes = this.service.getSplitPaneVisibleChildren(p);

          if (panes.length === 0) {
            return null;
          }

          const hasHeader = this.service.hasFloatingPaneHeader(p);
          let forceDrag = false;
          if (p === this.service.forceDragPane && hasHeader) {
            this.service.forceDragPane = null;
            forceDrag = true;
          }

          const containsMaximizedPane = this.maximizedPane && this.service.getRootParent(this.maximizedPane) === p;

          return (
            <igc-floating-pane-component
              key={p.id}
              floatingId={p.id}
              style={{ zIndex: this.floatingPaneZIndicesMap.get(p).toString() }}
              floatingLocation={p.floatingLocation ? p.floatingLocation : { x: 0, y: 0 }}
              floatingWidth={p.floatingWidth ? p.floatingWidth : IGC_DEFAULT_PANE_SIZE}
              floatingHeight={p.floatingHeight ? p.floatingHeight : IGC_DEFAULT_PANE_SIZE}
              hasHeader={hasHeader}
              onWndResizeStart={this.handleFloatingPaneResizeStart.bind(this, p)}
              onWndResizeMove={this.handleFloatingPaneResizeMove.bind(this, p)}
              onWndResizeEnd={this.handleFloatingPaneResizeEnd.bind(this, p)}
              onMouseDown={this.handleFloatingPaneMouseDown.bind(this, p)}
              class={{
                'maximized': this.maximizedPane === p
              }}
              maximized={this.maximizedPane === p || containsMaximizedPane}
              allowResize={p.floatingResizable ?? this.allowFloatingPanesResize}
            >
              {hasHeader ?
                <igc-pane-header-component
                  slot="header"
                  isFloating
                  isFloatingPaneHeader
                  forcedDrag={forceDrag}
                  allowMaximize={this.resolveAllowMaximize(p)}
                  maximized={this.maximizedPane === p}
                  resourceStrings={this.resourceStrings}
                  onDragStarted={this.handlePaneDragStart.bind(this, p)}
                  onDragMoved={this.handlePaneDragMove.bind(this)}
                  onDragEnded={this.handlePaneDragEnd.bind(this)}
                  onClose={this.handleFloatingPaneClose.bind(this, p)}
                  onMaximize={this.handleMaximize.bind(this, p)}
                  onFocusin={(this.clearActivePane.bind(this))}
                >
                </igc-pane-header-component> : null
              }
              {this.renderSplitPane(p, true, false)}
            </igc-floating-pane-component>
          );
        })
        }
      </div>
    );
  }

  private renderRootDockingIndicator(position: IgcDockingIndicatorPosition) {
    return (
      <igc-root-docking-indicator-component
        position={position}
      >
      </igc-root-docking-indicator-component>
    );
  }

  private renderDockingIndicators() {
    const startPosition = this.direction !== 'rtl' ? IgcDockingIndicatorPosition.left : IgcDockingIndicatorPosition.right;
    const endPosition = this.direction !== 'rtl' ? IgcDockingIndicatorPosition.right : IgcDockingIndicatorPosition.left;

    return (
      <div
        class="docking-indicators-container"
        style={{
          display: this.showDockingIndicators ? 'flex' : 'none'
        }}
      >
        {!this.documentOnlyDrag && !this.proximityDock && this.renderRootDockingIndicator(IgcDockingIndicatorPosition.top)}
        <div
          style={{
            flexGrow: '1',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          {!this.documentOnlyDrag && !this.proximityDock && this.renderRootDockingIndicator(startPosition)}
          {!this.documentOnlyDrag && !this.proximityDock && this.renderRootDockingIndicator(endPosition)}
        </div>
        {!this.documentOnlyDrag && !this.proximityDock && this.renderRootDockingIndicator(IgcDockingIndicatorPosition.bottom)}

        {this.dropTargetPaneInfo && !this.proximityDock &&
          <igc-joystick-indicator-component
            dropTargetPaneInfo={this.dropTargetPaneInfo}
            documentOnlyDrag={this.documentOnlyDrag}
            allowCenterDock={this.allowInnerDock}
          />}
      </div>);
  }

  private renderContextMenu() {
    return this.contextMenuMeta && (
      <igc-context-menu-component
        onMenuClosed={this.handleContextMenuClosed.bind(this)}
        orientation={IgcContextMenuOrientation.bottom}
        items={this.contextMenuMeta.menuItems}
        target={this.contextMenuMeta.target}
        position={this.contextMenuMeta.position}
      >
      </igc-context-menu-component>);
  }

  private renderDropShadow() {
    return this.dropShadowRect && (
      <div
        part="docking-preview"
        class="drop-shadow"
        style={{
          top: `${this.dropShadowRect?.y}px`,
          left: `${this.dropShadowRect?.x}px`,
          width: `${this.dropShadowRect?.width}px`,
          height: `${this.dropShadowRect?.height}px`,
        }}
      />
    );
  }

  handlePaneNavigatorClosed(ev: CustomEvent<IgcContentPane>) {
    this.service.normalizeMaximizedPane(ev.detail);

    const parent = this.service.getRootParent(ev.detail);
    const isParentFloating = this.service.isFloatingPane(parent);

    if (isParentFloating) {
      this.service.bringFloatingPaneOnTop(parent);
    }

    if (ev.detail && this.activePane !== ev.detail) {
      this.activePane = ev.detail;
    }
    this.navigationPaneMeta = null;
  }

  renderPaneNavigator() {
    return this.navigationPaneMeta &&
      <igc-pane-navigator-component
        onClosed={this.handlePaneNavigatorClosed.bind(this)}
        activePanes={this.navigationPaneMeta.activePanes}
        activeDocuments={this.navigationPaneMeta.activeDocuments}
        selectedIndex={this.navigationPaneMeta.initialIndex}
        previousActivePaneIndex={this.navigationPaneMeta.previousActivePaneIndex}
        resourceStrings={this.resourceStrings}
      >
      </igc-pane-navigator-component>;
  }

  render() {
    this.panesElementMap = new TwoWayMap<IgcDockManagerPane, HTMLElement>();
    this.tabHeadersMap = new Map<IgcContentPane, HTMLElement>();
    this.unpinnedHeadersMap = new Map<IgcContentPane, HTMLElement>();
    this.contentPanesElementMap = new Map<IgcContentPane, HTMLElement>();

    return (
      <Host
        tabindex="0"
        role="group"
      >
        {this.renderButtonsTemplates()}
        {this.renderUnpinnedTabArea(IgcUnpinnedLocation.left)}
        <div class="pane-container--vertical" style={{ position: this.maximizedPane ? 'absolute' : 'relative' }}>
          {this.renderUnpinnedTabArea(IgcUnpinnedLocation.top)}
          <div
            ref={el => this.dockedPanesContainer = el}
            class="pane-container--horizontal"
            style={{ position: this.maximizedPane ? 'absolute' : 'relative' }}
          >
            {this.layout?.rootPane && this.renderSplitPane(this.layout.rootPane, false, false)}
            {this.renderFlyoutPane()}
            {this.renderDockingIndicators()}
          </div>
          {this.renderUnpinnedTabArea(IgcUnpinnedLocation.bottom)}
        </div>
        {this.renderUnpinnedTabArea(IgcUnpinnedLocation.right)}
        {this.renderFloatingPanes()}
        {this.renderContextMenu()}
        {this.renderPaneNavigator()}
                {this.renderDropShadow()}
      </Host>
    );
  }
}
