import { Component, Host, Prop, h } from '@stencil/core';

import { icons } from './icons';

/**
 * @hidden
 */
@Component({
  tag: 'igc-icon-component',
  styleUrl: 'icon-component.scss',
  shadow: false,
  scoped: true
})
export class IgcIconComponent {

  @Prop() name: string;

  render() {
    return (
      <Host innerHTML={icons[this.name]}>
      </Host>
    );
  }
}
