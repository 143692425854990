import { Component, Element, Event, EventEmitter, Host, Listen, Prop, State, h } from '@stencil/core';

import { Utils } from '../../../utils/utils';
import { IgcDragMoveEventArguments, IgcDragService } from '../../drag-drop/drag.service';
import { IGC_DEFAULT_RESIZE, IGC_RESIZING_MIN_SIZE } from '../dockmanager.interfaces';
import { IgcSplitPaneOrientation, IgcUnpinnedLocation } from '../dockmanager.public-interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-splitter-component',
  styleUrl: 'splitter-component.scss',
  shadow: true,
  scoped: false
})
export class IgcSplitterComponent {
  private dragService: IgcDragService;
  private paneSizes: number[];
  private ghostElement: HTMLElement;
  private flyoutMaxSize: number;

  @Element() element: HTMLElement;

  @State() dragOffset: number;
  @State() hasCustomSplitterHandle: boolean;

  @Prop() showDragGhost: boolean;
  @Prop() splitPaneOrientation: IgcSplitPaneOrientation;
  @Prop() flyoutLocation: IgcUnpinnedLocation;

  @Event() resizeStart: EventEmitter;
  @Event() resizeEnd: EventEmitter<number>;

  connectedCallback() {
    this.dragService = new IgcDragService(this.element);
    this.dragService.dragStart = this.dragStart;
    this.dragService.dragEnd = this.dragEnd;
    this.dragService.dragMove = this.dragMove;
  }

  disconnectedCallback() {
    this.dragService.destroy();
    document.documentElement.removeEventListener('mouseup', this.handleMouseUp, false);
  }

  calculateOffset(event: KeyboardEvent): number {
    if (this.isArrowUp(event) || this.isArrowLeft(event)) {
      return -IGC_DEFAULT_RESIZE;
    } else if (this.isArrowDown(event) || this.isArrowRight(event)) {
      return IGC_DEFAULT_RESIZE;
    }
  }

  isArrowUp(event: KeyboardEvent): boolean {
    return event.key === 'ArrowUp';
  }

  isArrowDown(event: KeyboardEvent): boolean {
    return event.key === 'ArrowDown';
  }

  isArrowLeft(event: KeyboardEvent): boolean {
    return event.key === 'ArrowLeft';
  }

  isArrowRight(event: KeyboardEvent): boolean {
    return event.key === 'ArrowRight';
  }

  @Listen('keydown')
  handleKeydownEvent(event: KeyboardEvent) {
    if (this.splitPaneOrientation === IgcSplitPaneOrientation.horizontal &&
      (!this.isArrowLeft(event) && !this.isArrowRight(event))) {
      return;
    }
    if (this.splitPaneOrientation === IgcSplitPaneOrientation.vertical &&
      (!this.isArrowUp(event) && !this.isArrowDown(event))) {
      return;
    }

    this.showDragGhost = false;
    this.dragStartHelper(this.calculateOffset(event));

    if (this.flyoutLocation) {
      this.constrainFlyoutResize();
    } else {
      this.constrainSplitPaneResize();
    }

    this.dragEndHelper();
  }

  @Listen('mousedown')
  handleMouseDown() {
    this.showDragGhost = true;
    document.documentElement.addEventListener('mouseup', this.handleMouseUp, false);
    this.resizeStart.emit();
  }

  private handleMouseUp = () => {
    document.documentElement.removeEventListener('mouseup', this.handleMouseUp, false);
    this.showDragGhost = false;
  }

  private dragStartHelper(dragOffset: number) {
    this.dragOffset = dragOffset;

    const parent = this.element.parentElement;
    const children = Array.from(parent.children) as HTMLElement[];

    if (this.flyoutLocation) {
      const pane = children[0];
      this.paneSizes = [this.splitPaneOrientation === IgcSplitPaneOrientation.horizontal ?
        pane.offsetWidth : pane.offsetHeight];
      this.flyoutMaxSize = this.splitPaneOrientation === IgcSplitPaneOrientation.horizontal ?
        parent.offsetWidth - IGC_RESIZING_MIN_SIZE :
        parent.offsetHeight - IGC_RESIZING_MIN_SIZE;
    } else {
      const index = children.indexOf(this.element);
      const panes = [children[index - 1], children[index + 1]];
      this.paneSizes = panes.map(p => this.splitPaneOrientation === IgcSplitPaneOrientation.horizontal ?
        p.offsetWidth : p.offsetHeight);
    }
  }

  private dragStart = () => {
    this.dragStartHelper(0);
  }

  private dragEndHelper() {
    const dragOffset = this.dragOffset;
    this.showDragGhost = false;
    this.dragOffset = 0;
    this.paneSizes = null;
    this.resizeEnd.emit(dragOffset);
  }

  private dragEnd = () => {
    this.dragEndHelper();
  }

  private dragMove = (args: IgcDragMoveEventArguments) => {
    if (!this.ghostElement) {
      return;
    }

    const rect = this.ghostElement.getBoundingClientRect();
    const isHorizontal = this.splitPaneOrientation === IgcSplitPaneOrientation.horizontal;
    const clientCoordinate = isHorizontal ? args.clientX : args.clientY;
    const offset = isHorizontal ? args.offsetX : args.offsetY;
    const startCoordinate = isHorizontal ? rect.left : rect.top;
    const endCoordinate = isHorizontal ? rect.right : rect.bottom;

    if (offset <= 0 && startCoordinate < clientCoordinate) {
      return;
    }

    if (offset >= 0 && endCoordinate > clientCoordinate) {
      return;
    }

    this.dragOffset += offset;

    if (this.flyoutLocation) {
      this.constrainFlyoutResize();
    } else {
      this.constrainSplitPaneResize();
    }
  }

  private constrainFlyoutResize() {
    const paneSize = this.paneSizes[0];
    const isStartLocation = this.flyoutLocation === IgcUnpinnedLocation.left || this.flyoutLocation === IgcUnpinnedLocation.top;
    const offset = isStartLocation ?
      this.dragOffset :
      -this.dragOffset;

    if (paneSize + offset < IGC_RESIZING_MIN_SIZE) {
      const minOffset = paneSize - IGC_RESIZING_MIN_SIZE;
      this.dragOffset = isStartLocation ? -minOffset : minOffset;
    } else if (paneSize + offset > this.flyoutMaxSize) {
      const maxOffset = this.flyoutMaxSize - paneSize;
      this.dragOffset = isStartLocation ? maxOffset : -maxOffset;
    }
  }

  private constrainSplitPaneResize() {
    let rtl = false;
    const dockmanager = this.closestElement('igc-dockmanager', this.element);
    if (dockmanager.dir !== '') {
      rtl = dockmanager.dir === 'rtl';
    } else {
      let parent = dockmanager.parentElement;
      while (parent) {
        if (parent.dir !== '') {
          rtl = parent.dir === 'rtl';
          break;
        }

        parent = parent.parentElement;
      }
    }

    const isVertical = this.splitPaneOrientation === IgcSplitPaneOrientation.vertical;
    if (this.dragOffset < 0) {
      const referencePaneSize = !rtl || isVertical ? this.paneSizes[0] : this.paneSizes[1];
      if (referencePaneSize > IGC_RESIZING_MIN_SIZE) {
        if (referencePaneSize + this.dragOffset < IGC_RESIZING_MIN_SIZE) {
          this.dragOffset = -(referencePaneSize - IGC_RESIZING_MIN_SIZE);
        }
      } else {
        this.dragOffset = 0;
      }
    } else {
      const referencePaneSize = !rtl || isVertical ? this.paneSizes[1] : this.paneSizes[0];
      if (referencePaneSize > IGC_RESIZING_MIN_SIZE) {
        if (referencePaneSize - this.dragOffset < IGC_RESIZING_MIN_SIZE) {
          this.dragOffset = referencePaneSize - IGC_RESIZING_MIN_SIZE;
        }
      } else {
        this.dragOffset = 0;
      }
    }
  }

  private closestElement(selector: string, el: any): HTMLIgcDockmanagerElement {
    return (
      (el && el.closest(selector)) ||
        this.closestElement(selector, el.getRootNode().host)
    );
  }

  handleSlotChange() {
    const handleSlot = this.element.shadowRoot.querySelector('slot[name="splitterHandle"]') as HTMLSlotElement;
    const isVertical = this.splitPaneOrientation === IgcSplitPaneOrientation.vertical;
    this.hasCustomSplitterHandle = handleSlot.assignedElements().length > 0;

    if (this.hasCustomSplitterHandle) {
      const handle = this.element.querySelector('[part="splitter-handle"]');
      const parts = Utils.partNameMap({
        'splitter-handle': true,
        'vertical': isVertical,
        'horizontal': !isVertical
      });
      handle.setAttribute('part', parts);
    }
  }

  render() {
    const isVertical = this.splitPaneOrientation === IgcSplitPaneOrientation.vertical;
    const splitterClasses = {
      'splitter': true,
      'splitter--vertical': isVertical,
      'splitter--custom-handle': this.hasCustomSplitterHandle
    };

    const ghostClasses = {
      'splitter-ghost': true,
      'splitter-ghost--vertical': isVertical
    };

    return (
      <Host
        part="splitter"
        exportparts="splitter,
        splitter-base,
        splitter-ghost"
      >
        <div part="splitter-base" class={splitterClasses} tabIndex={0} role="separator" aria-orientation={this.splitPaneOrientation.toString()}>
            <slot name="splitterHandle" onSlotchange={this.handleSlotChange.bind(this)}></slot>
        </div>
        {this.showDragGhost &&
          <div
            part="splitter-ghost"
            class={ghostClasses}
            style={{
              left: `${isVertical ? 0 : this.dragOffset}px`,
              top: `${isVertical ? this.dragOffset : 0}px`
            }}
            ref={el => this.ghostElement = el}
          />}
      </Host>);
  }
}
