:host {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 0px;
  min-height: 0px;
}

.tabs {
  display: flex;
  overflow: hidden;
  min-height: $tab-header-height;
  max-height: $tab-header-height;
  background: $dock-background;

  &--top {
    padding-top: $tab-header-padding;

    .tab-headers-container--wrapped {
      flex-wrap: wrap;
    }
  }

  &--bottom {
    padding-bottom: $tab-header-padding;

    .tab-headers-container--wrapped {
      flex-wrap: wrap-reverse;
    }
  }
}

.content {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}

.tab-headers-container {
  width: 100%;
  display: flex;
  overflow: hidden;
}


.tab-header-icon-container {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding-left: 8px;
  padding-right: 8px;
}
