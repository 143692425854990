import { Component, Event, EventEmitter, Host, Listen, Prop, h } from '@stencil/core';

import { Utils } from '../../../utils/utils';
import { IgcContentPane, IgcDockManagerResourceStrings } from '../dockmanager.public-interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-pane-navigator-component',
  styleUrl: 'pane-navigator-component.scss',
  shadow: true
})
export class IgcPaneNavigatorComponent {

  @Prop() activeDocuments: IgcContentPane[];
  @Prop() activePanes: IgcContentPane[];
  @Prop({ mutable: true }) selectedIndex: number;
  @Prop() previousActivePaneIndex: number;
  @Prop() resourceStrings: IgcDockManagerResourceStrings;

  @Event() closed: EventEmitter<IgcContentPane>;

  private allItems: IgcContentPane[];
  private allItemsElement: HTMLElement[];
  private paneNav: HTMLElement;

  scrollItemToView() {
    const selectedItem = this.allItemsElement[this.selectedIndex];
    const selectedItemRect = selectedItem.getBoundingClientRect();
    const itemsContainerRect = selectedItem.parentElement.getBoundingClientRect();

    if (selectedItemRect.top < itemsContainerRect.top) {
      this.allItemsElement[this.selectedIndex].parentElement.scrollTop -= itemsContainerRect.top - selectedItemRect.top;
    }
    if (selectedItemRect.bottom > itemsContainerRect.bottom) {
      this.allItemsElement[this.selectedIndex].parentElement.scrollTop += selectedItemRect.bottom - itemsContainerRect.bottom;
    }
  }

  @Listen('keyup')
  handleKeyUp(event: KeyboardEvent) {
    if (event.code === 'AltLeft' || event.code === 'AltRight' || event.key === 'Control' || event.key === 'OS' || event.key === 'Meta') {
      this.closed.emit(this.allItems[this.selectedIndex]);
    }
  }

  @Listen('keydown')
  handleKeydown(event: KeyboardEvent) {
    event.stopPropagation();
    const isShiftPressed = event.shiftKey;
    let index;

    if (Utils.isControlOrMetaPressed(event) && !event.altKey) {
      switch (event.key) {
        case 'ArrowUp':
          this.decreaseSelectedIndex();
          break;
        case 'ArrowDown':
          this.increaseSelectedIndex();
          break;
        case 'ArrowRight':
        case 'ArrowLeft': {
          if (this.activePanes.length === 0 || this.activeDocuments.length === 0) {
            return;
          }
          if (this.selectedIndex >= 0 && this.selectedIndex < this.activePanes.length) {
            index = this.selectedIndex + this.activePanes.length;
            this.navigateToIndex(index >= this.allItems.length ? this.allItems.length - 1 : index, 'next');
          } else if (this.selectedIndex >= this.activePanes.length && this.selectedIndex < this.allItems.length) {
            index = this.selectedIndex - this.activePanes.length;
            this.navigateToIndex(index > this.activePanes.length - 1 ? this.activePanes.length - 1 : index, 'previous');
          }
        }
      }
    }

    if (event.key === 'F7' || event.key === 'F8') {
      if (isShiftPressed) {
        this.decreaseSelectedIndex();
      } else {
        this.increaseSelectedIndex();
      }
    }

    this.scrollItemToView();
  }

  private navigateToIndex(index: number, direction: 'next' | 'previous') {
    if (index === -1) {
      index = this.allItems.length - 1;
    } else if (index === this.allItems.length) {
      index = 0;
    }

    let counter = 0;

    while (this.allItems[index].disabled && counter <= this.allItems.length) {
      counter++;
      index = index + (direction === 'next' ? 1 : -1);

      if (index === -1) {
        index = this.allItems.length - 1;
      } else if (index === this.allItems.length) {
        index = 0;
      }
    }

    this.selectedIndex = index;
  }

  private increaseSelectedIndex(amount = 1) {
    const newValue = this.selectedIndex + amount;
    this.navigateToIndex(newValue, 'next');
  }

  private decreaseSelectedIndex(amount = 1) {
    const newValue = this.selectedIndex - amount;
    this.navigateToIndex(newValue, 'previous');
  }

  connectedCallback() {
    this.allItems = [...this.activePanes, ...this.activeDocuments];
  }

  componentDidLoad() {
    this.paneNav.focus();
    this.allItemsElement = Array.from<HTMLElement>(this.paneNav.querySelectorAll('[part~=item]'));
    this.navigateToIndex(this.selectedIndex, 'next');
    this.scrollItemToView();
  }

  resolveItemPart(item: IgcContentPane, index: number, isDocHost: boolean) {
    const part = Utils.partNameMap({
      item: true,
      selected: isDocHost ? index === this.selectedIndex - this.activePanes.length : index === this.selectedIndex,
      disabled: item.disabled
    });

    return part;
  }

  selectItem(index: number, isDocHost: boolean): void {
    this.selectedIndex = !isDocHost ? index : this.activePanes.length + index;
  }

  renderItems(title: string, items: IgcContentPane[], isDocHost: boolean) {
    return (
      <article part="group" class="group">
        <h4 part="title" class="title">{title}</h4>
        <section class="items">
          {items?.map((item: any, index) => {
            return (
              <div
                part={this.resolveItemPart(item, index, isDocHost)}
                onMouseDown={() => this.selectItem(index, isDocHost)}
                onMouseUp={() => this.closed.emit(this.allItems[this.selectedIndex])}
              >
                {item.header}
              </div>
            );
          })}
        </section>
      </article>
    );
  }

  render() {
    return (
      <Host
        onClick={() => this.closed.emit(this.allItems[this.previousActivePaneIndex])}
        exportparts="base: pane-navigator, header: pane-navigator-header, body: pane-navigator-body,
                     group: pane-navigator-items-group, title: pane-navigator-items-group-title, item: pane-navigator-item, selected, disabled"
      >
        <article
          part="base"
          class="pane-navigator"
          ref={el => this.paneNav = el}
          tabIndex={0}
          onClick={ev => ev.stopPropagation()}
        >
          <header part="header">
              <h3>{this.allItems[this.selectedIndex]?.header}</h3>
          </header>
          <section part="body" class="body">
              {this.renderItems(this.resourceStrings.panes, this.activePanes, false)}
              {this.renderItems(this.resourceStrings.documents, this.activeDocuments, true)}
          </section>
        </article>
      </Host >
    );
  }
}
