import { Component, Element, Event, EventEmitter, Fragment, Host, Listen, Prop, h } from '@stencil/core';

import { Utils } from '../../utils/utils';
import { IgcContextMenuItem, IgcContextMenuOrientation, IgcContextMenuPosition } from '../dockmanager/dockmanager.interfaces';

/**
 * @hidden
 */
@Component({
  tag: 'igc-context-menu-component',
  styleUrl: 'context-menu-component.scss',
  shadow: true
})
export class IgcContextMenuComponent {
  private menuItemsDiv: HTMLElement;

  @Element() elem: HTMLElement;

  @Prop() orientation: IgcContextMenuOrientation;
  @Prop() position: IgcContextMenuPosition;
  @Prop() target: HTMLElement;
  @Prop() items: IgcContextMenuItem[];

  @Event() menuClosed: EventEmitter;

  @Prop({ mutable: true }) activeIndex = 0;

  @Listen('focusout')
  emitMenuClosed() {
    this.menuClosed.emit();
  }

  connectedCallback() {
    document.defaultView.addEventListener('resize', this.handleDocumentResize, false);
    document.defaultView.addEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  disconnectedCallback() {
    document.defaultView.removeEventListener('resize', this.handleDocumentResize, false);
    document.defaultView.removeEventListener('mousedown', this.handleDocumentMouseDown, false);
  }

  handleDocumentResize = () => {
    this.menuClosed.emit();
  }

  handleDocumentMouseDown = (ev: MouseEvent) => {
    if (ev.composedPath().every(el => el !== this.elem)) {
      // mousedown outside of context menu
      this.menuClosed.emit();
    }
  }

  componentDidLoad() {
    const isTabsMoreButton = this.target.querySelector('slot[name="tabsMoreButton"]') !== null;
    const menuItemsDivRect = this.menuItemsDiv.getBoundingClientRect();
    const hostRect = this.elem.getBoundingClientRect();
    const rootNodeShadowHost = (this.target.getRootNode() as ShadowRoot);
    const isFloating = this.isFloating(rootNodeShadowHost.host);
    const rootHostRect = rootNodeShadowHost.host.getBoundingClientRect();
    const isTabs = rootNodeShadowHost.host.tagName.toLowerCase() === 'igc-tab-header-component';
    const tabsContentHost = isTabs ? rootNodeShadowHost.host.parentElement.shadowRoot : rootNodeShadowHost;
    const tabRect = tabsContentHost.host.getBoundingClientRect();
    const tabsContentRect = tabsContentHost.querySelector('div[part~="tabs-content"]').getBoundingClientRect();
    const isRTL = rootHostRect.right - menuItemsDivRect.right < 1;
    const shouldChangeOpenOrientation = tabRect.bottom + menuItemsDivRect.bottom >= window.innerHeight
      || tabRect.height + menuItemsDivRect.bottom >= window.innerHeight
      || (isTabsMoreButton && tabsContentRect.height- menuItemsDivRect.height > 0);
    const menuToBottom = shouldChangeOpenOrientation
      ? tabsContentRect.top + (tabsContentRect.height - menuItemsDivRect.bottom)
      : rootHostRect.top - menuItemsDivRect.top + rootHostRect.height;

    const menuToTop = tabsContentRect.top - menuItemsDivRect.top;
    const menuToStart = isRTL ? rootHostRect.right - menuItemsDivRect.width : rootHostRect.left;
    const menuToEnd = isRTL && !isFloating ? rootHostRect.left : rootHostRect.right - menuItemsDivRect.right;
    const menuToCenter = rootHostRect.left + (rootHostRect.width - menuItemsDivRect.width) / 2;

    let menuLeft = !isRTL ? menuToStart : menuToEnd;
    let menuTop = this.orientation === IgcContextMenuOrientation.bottom
      ? isTabs
        ? menuToBottom
        : menuToTop
      : menuToBottom;

    const menuBottom = menuTop + menuItemsDivRect.height;

    menuTop = menuTop >= 0 ? menuTop : menuToBottom;

    switch (this.position) {
      case 'start':
        menuLeft = menuToStart;
        break;
      case 'center':
        menuLeft = rootHostRect.width > menuItemsDivRect.width && menuToCenter;
        break;
      case 'stretch':
        this.menuItemsDiv.style.width = rootHostRect.width > menuItemsDivRect.width && `${rootHostRect.width}px`;
        break;
      case 'end':
      default:
        menuLeft = menuToEnd;
    }

    if (this.orientation === IgcContextMenuOrientation.bottom && menuBottom > hostRect.height) {
      menuTop = menuToTop;
    }

    this.menuItemsDiv.style.left = `${menuLeft}px`;
    this.menuItemsDiv.style.top = `${menuTop}px`;
    this.menuItemsDiv.style.visibility = 'visible';

    this.focusItemAndSetActiveIndex();
  }

  private focusItemAndSetActiveIndex() {
    const firstMenuItem = this.menuItemsDiv.querySelector('div[part="menu-item"]');

    (firstMenuItem as HTMLElement)?.focus();
  }

  private isFloating(host: Element): boolean {
    while (host) {
      if (host.tagName && host.tagName.toLowerCase() === 'igc-floating-pane-component') {
        return true;
      }

      host = host.parentNode as Element;
    }

    return false;
  }

  private handleKeyboardEvents(item: IgcContextMenuItem, ev: KeyboardEvent) {
    const menuItemChildren = this.menuItemsDiv.querySelectorAll('div[part="menu-item"]');

    if (ev.key === 'ArrowDown') {
      if (this.activeIndex < menuItemChildren.length - 1) {
        this.activeIndex++;
        (menuItemChildren[this.activeIndex] as HTMLElement).focus();
      }
    } else if (ev.key === 'ArrowUp') {
      if (this.activeIndex > 0) {
        this.activeIndex--;
        (menuItemChildren[this.activeIndex] as HTMLElement).focus();
      }
    } else if (ev.key === 'Enter' || ev.key === ' ') {
      item.clickHandler();
      this.emitMenuClosed();
    } else if (ev.key === 'Escape') {
      this.emitMenuClosed();
    }
  }

  private handleMenuItemClick(item: IgcContextMenuItem) {
    item.clickHandler();
    this.emitMenuClosed();
  }

  private renderItemIcon(item: IgcContextMenuItem) {
    return (
      <Fragment>
        {item.iconName === 'close' && this.renderCloseButton()}
        {item.iconName === 'unpin' && this.renderUnpinButton()}
        {item.iconName !== null && item.iconName !== 'close' && item.iconName !== 'unpin' &&
          <igc-icon-component name={item.iconName} />
        }
      </Fragment>
    );
  }

  private renderCloseButton() {
    return (
      <slot name="contextMenuCloseButton">
        <igc-icon-component part="context-menu-close-button" name="close" />
      </slot>
    );
  }

  private renderUnpinButton() {
    return (
      <slot name="contextMenuUnpinButton">
        <igc-icon-component part="context-menu-unpin-button" name="unpin" />
      </slot>
    );
  }

  render() {
    return (
      <Host
        part="context-menu"
        exportparts="context-menu,
        menu-item: context-menu-item,
        menu-content: context-menu-content,
        context-menu-close-button,
        context-menu-unpin-button"
      >
        <div
          role="menu"
          part="menu-content"
          ref={el => this.menuItemsDiv = el as HTMLElement}
          onMouseDown={ev => {
            ev.preventDefault();
          }}
        >
          {this.items.map(item => {
            const parts = Utils.partNameMap({
              'menu-item': true,
              disabled: item.disabled
            });

            return (
              <div
                role="menuitem"
                part={parts}
                tabindex="-1"
                onKeyDown={this.handleKeyboardEvents.bind(this, item)}
                onClick={this.handleMenuItemClick.bind(this, item)}
              >
                <span
                  style={{
                    flexGrow: '1',
                    userSelect: 'none',
                  }}
                >
                  {item.displayText}
                </span>

                {this.renderItemIcon(item)}
              </div>
            );
          })
          }
        </div>
      </Host>
    );
  }
}
