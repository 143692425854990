import { Component, Host, Prop, h } from '@stencil/core';

import { addResourceStrings } from '../..';
import {
  IgcActivePaneEventArgs, IgcContentPane, IgcDockManagerComponent, IgcDockManagerLayout, IgcDockManagerPane, IgcDockManagerPaneType, IgcDockManagerResourceStrings, IgcDockingIndicatorPosition, IgcFloatingPaneResizeEventArgs, IgcFloatingPaneResizeMoveEventArgs, IgcPaneCloseEventArgs, IgcPaneDragActionType,
  IgcPaneDragOverEventArgs, IgcPanePinnedEventArgs, IgcPaneScrollEventArgs, IgcSplitPaneOrientation, IgcTabGroupPane, IgcUnpinnedLocation
} from '../dockmanager/dockmanager.public-interfaces';

/**
 * @hidden
 */
const customResourceStrings: IgcDockManagerResourceStrings = {
  close: '[Custom]Close',
  pin: '[Custom]Pin',
  unpin: '[Custom]Unpin',
  maximize: '[Custom]Maximize',
  minimize: '[Custom]Minimize',
  moreOptions: '[Custom]More options'
};
addResourceStrings('custom', customResourceStrings);

/**
 * @hidden
 */
@Component({
  tag: 'sample-component',
  styleUrl: 'sample-component.css',
  shadow: false,
  scoped: true
})
export class SampleComponent {
  @Prop({ mutable: true })
  hiddenPanes: IgcContentPane[] = [];

  dockManager: IgcDockManagerComponent;

  private teamExplorerPane: IgcContentPane = {
    type: IgcDockManagerPaneType.contentPane,
    header: 'Team Explorer',
    contentId: 'content7',
    allowMaximize: true
  };

  private unpinnedToolboxPane: IgcContentPane = {
    type: IgcDockManagerPaneType.contentPane,
    header: 'Toolbox',
    contentId: 'content1',
    isPinned: false,
    unpinnedHeaderId: "toolboxHeader"
  };

  private unpinnedTeamExplorerPane: IgcContentPane = {
    type: IgcDockManagerPaneType.contentPane,
    header: 'Team Explorer',
    contentId: 'content14',
    isPinned: false,
    unpinnedHeaderId: "teamExplorerHeader"
  };

  layout1: IgcDockManagerLayout = {
    rootPane: {
      type: IgcDockManagerPaneType.splitPane,
      orientation: IgcSplitPaneOrientation.horizontal,
      panes: [
        this.unpinnedToolboxPane,
        this.unpinnedTeamExplorerPane,
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.vertical,
          size: 300,
          panes: [
            {
              type: IgcDockManagerPaneType.documentHost,
              size: 300,
              rootPane: {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                allowEmpty: true,
                panes: [
                  {
                    type: IgcDockManagerPaneType.tabGroupPane,
                    panes: [
                      {
                        type: IgcDockManagerPaneType.contentPane,
                        header: 'MainWindow.xaml',
                        contentId: 'content2',
                        documentOnly: true,
                        // allowMaximize: false,
                      },
                      {
                        type: IgcDockManagerPaneType.contentPane,
                        header: 'MainWindow.xaml.cs',
                        contentId: 'content6',
                        documentOnly: true,
                        allowFloating: false
                      }
                    ]
                  },
                  {
                    type: IgcDockManagerPaneType.contentPane,
                    header: 'App.xaml',
                    contentId: 'content8',
                    allowMaximize: true,
                    acceptsInnerDock: false
                  }
                ]
              }
            },
            {
              type: IgcDockManagerPaneType.splitPane,
              orientation: IgcSplitPaneOrientation.horizontal,
              allowEmpty: true,
              panes: []
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              header: 'Error List',
              contentId: 'content3',
              allowDocking: false,
              allowFloating: false,
              allowMaximize: false
            }]
        },
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.vertical,
          panes: [
            {
              type: IgcDockManagerPaneType.tabGroupPane,
              size: 200,
              allowEmpty: true,
              panes: [
                {
                  type: IgcDockManagerPaneType.contentPane,
                  header: 'Solution Explorer',
                  headerId: 'header1',
                  tabHeaderId: 'tabHeader1',
                  unpinnedHeaderId: 'unpinnedHeader1',
                  contentId: 'content4',
                  allowMaximize: false
                },
                this.teamExplorerPane
              ]
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              header: 'Properties',
              contentId: 'content5',
              unpinnedLocation: IgcUnpinnedLocation.top,
              allowFloating: false,
              allowMaximize: false
            }
          ]
        }
      ]
    },
    floatingPanes: [
      {
        type: IgcDockManagerPaneType.splitPane,
        orientation: IgcSplitPaneOrientation.horizontal,
        floatingLocation: { x: 50, y: 100 },
        floatingWidth: 200,
        floatingHeight: 100,
        panes: [
          {
            type: IgcDockManagerPaneType.contentPane,
            header: 'Notifications',
            contentId: 'content9',
            allowDocking: false
          }
        ],
        // floatingResizable: true
      },
      {
        type: IgcDockManagerPaneType.splitPane,
        allowEmpty: true,
        orientation: IgcSplitPaneOrientation.horizontal,
        floatingLocation: { x: 250, y: 350 },
        floatingWidth: 300,
        floatingHeight: 200,
        panes: [
          {
            type: IgcDockManagerPaneType.contentPane,
            header: 'Floating 1',
            contentId: 'content10',
            acceptsInnerDock: false
          },
          {
            type: IgcDockManagerPaneType.contentPane,
            header: 'Floating 2',
            contentId: 'content11',
            allowMaximize: false
          }
        ]
      },
      {
        type: IgcDockManagerPaneType.splitPane,
        orientation: IgcSplitPaneOrientation.horizontal,
        floatingLocation: { x: 750, y: 200 },
        floatingWidth: 300,
        floatingHeight: 200,
        panes: [
          {
            type: IgcDockManagerPaneType.tabGroupPane,
            allowEmpty: true,
            panes: [
              {
                type: IgcDockManagerPaneType.contentPane,
                header: 'Floating Tab 1',
                contentId: 'content12'
              },
              {
                type: IgcDockManagerPaneType.contentPane,
                header: 'Floating Tab 2',
                contentId: 'content13'
              }
            ]
          }
        ]
      }
    ]
  };

  layout2: IgcDockManagerLayout = {
    rootPane: {
      type: IgcDockManagerPaneType.splitPane,
      orientation: IgcSplitPaneOrientation.horizontal,
      panes: [
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.vertical,
          panes: [
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content1',
              header: 'Content Pane 1'
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content2',
              header: 'Unpinned Pane 1',
              isPinned: false
            }
          ]
        },
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.vertical,
          size: 200,
          panes: [
            {
              type: IgcDockManagerPaneType.documentHost,
              size: 200,
              rootPane: {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                panes: [
                  {
                    type: IgcDockManagerPaneType.tabGroupPane,
                    panes: [
                      {
                        type: IgcDockManagerPaneType.contentPane,
                        header: 'Document 1',
                        contentId: 'content3'
                      },
                      {
                        type: IgcDockManagerPaneType.contentPane,
                        header: 'Document 2',
                        contentId: 'content4'
                      }
                    ]
                  }
                ]
              }
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content5',
              header: 'Unpinned Pane 2',
              isPinned: false
            }
          ]
        },
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.vertical,
          panes: [
            {
              type: IgcDockManagerPaneType.tabGroupPane,
              size: 200,
              // selectedIndex: 1,
              panes: [
                {
                  type: IgcDockManagerPaneType.contentPane,
                  contentId: 'content6',
                  header: 'Tab 1'
                },
                {
                  type: IgcDockManagerPaneType.contentPane,
                  contentId: 'content7',
                  header: 'Tab 2'
                },
                {
                  type: IgcDockManagerPaneType.contentPane,
                  contentId: 'content8',
                  header: 'Tab 3'
                },
                {
                  type: IgcDockManagerPaneType.contentPane,
                  contentId: 'content9',
                  header: 'Tab 4'
                },
                {
                  type: IgcDockManagerPaneType.contentPane,
                  contentId: 'content10',
                  header: 'Tab 5'
                }

              ]
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content11',
              header: 'Content Pane 2'
            }
          ]
        }
      ]
    },
    floatingPanes: [
      {
        type: IgcDockManagerPaneType.splitPane,
        orientation: IgcSplitPaneOrientation.horizontal,
        floatingHeight: 150,
        floatingWidth: 250,
        floatingLocation: { x: 300, y: 200 },
        panes: [
          {
            type: IgcDockManagerPaneType.contentPane,
            contentId: 'content12',
            header: 'Floating Pane'
          }
        ]
      }
    ]
  };

  layout: IgcDockManagerLayout = this.layout1;

  private hiddenPanesSelect: HTMLSelectElement;
  private hideOnCloseCheckbox: HTMLInputElement;
  private savedLayout: string;
  private logEnabled = false;

  private saveLayout() {
    this.savedLayout = JSON.stringify(this.dockManager.layout);
  }

  private loadLayout() {
    this.dockManager.layout = JSON.parse(this.savedLayout);
  }

  private setActivePane() {
    // this.dockManager.activePane = this.teamExplorerPane;
    this.dockManager.activePane = this.unpinnedToolboxPane;
  }

  private id = 100;
  private newId: string;

  private addPane() {
    this.newId = `content${this.id++}`;
    const newDiv = this.createElement(this.newId, 'input');
    this.dockManager.appendChild(newDiv);

    this.layout.floatingPanes[1].panes.push({
      type: IgcDockManagerPaneType.contentPane,
      header: 'NewPane',
      contentId: this.newId
    });

    this.dockManager.layout = { ...this.layout };
  }

  private addTab() {
    this.findElement(this.layout.rootPane, IgcDockManagerPaneType.tabGroupPane);

    this.newId = `content${this.id++}`;
    const newDiv = this.createElement(this.newId, 'button');
    this.dockManager.appendChild(newDiv);

    const tabGroupPane = this.foundElem[0] as IgcTabGroupPane;
    tabGroupPane.panes.push({
      type: IgcDockManagerPaneType.contentPane,
      header: 'NewTab',
      contentId: this.newId
    });

    this.dockManager.layout = { ...this.layout };
  }

  private disableContentPane() {
    this.findElement(this.layout.rootPane, IgcDockManagerPaneType.tabGroupPane);

    const tabGroupPane = this.foundElem[0] as IgcTabGroupPane;
    tabGroupPane.panes[0].disabled = true;
    this.dockManager.layout = { ...this.layout };
  }

  private toggleProximityDock() {
    this.dockManager.proximityDock = !this.dockManager.proximityDock;
  }

  private focusPane() {
    this.dockManager.focusPane('content12');
  }

  private foundElem: IgcDockManagerPane[] = [];
  private findElement(pane: IgcDockManagerPane, type: IgcDockManagerPaneType) {
    if (!pane) { return; }

    if (pane.type === type) {
      this.foundElem.push(pane);
    }

    if (pane.type === IgcDockManagerPaneType.tabGroupPane || pane.type === IgcDockManagerPaneType.splitPane) {
      for (const c of pane.panes) {
        this.findElement(c, type);
      }
    }

    if (pane.type === IgcDockManagerPaneType.documentHost) {
      this.findElement(pane.rootPane, type);
    }
  }

  private showPane() {
    const index = this.hiddenPanesSelect.selectedIndex;

    if (index >= 0) {
      this.hiddenPanes[index].hidden = false;
      this.hiddenPanes.splice(index, 1);
      this.hiddenPanes = [...this.hiddenPanes];
      this.dockManager.layout = { ...this.dockManager.layout };
    }
  }

  private showAllPanes() {
    if (this.hiddenPanes.length > 0) {
      for (const pane of this.hiddenPanes) {
        pane.hidden = false;
      }
      this.hiddenPanes = [];
      this.dockManager.layout = { ...this.dockManager.layout };
    }
  }

  private toggleInBoundaries() {
    this.dockManager.containedInBoundaries = !this.dockManager.containedInBoundaries;
  }

  private createElement(content: string, typeOfElement: string): HTMLElement {
    const someContent = document.createTextNode(content);
    let elem: HTMLElement;
    switch (typeOfElement) {
      case ('button'):
        elem = document.createElement('button');
        elem.appendChild(someContent);
        break;
      case ('input'):
        elem = document.createElement('input');
        break;
    }

    const divContent = document.createElement('div');
    divContent.appendChild(elem ? elem : someContent);
    divContent.setAttribute('slot', content);
    divContent.setAttribute('style', 'width: 100%; height: 100%;');

    return divContent;
  }

  private handlePaneClose(event: CustomEvent<IgcPaneCloseEventArgs>) {
    this.log(event);

    if (this.hideOnCloseCheckbox.checked) {
      for (const pane of event.detail.panes) {
        pane.hidden = true;
        this.hiddenPanes.splice(0, 0, pane);
        this.hiddenPanes = [...this.hiddenPanes];
      }
      event.preventDefault();
    }

    // const panes = event.detail.panes;
    // const confirmed = confirm(`Are you sure you want to close panes '${panes.map(p => p.header).join(', ')}'?`);
    // // event.detail.panes = [panes[0]];
    // if (confirmed) {
    //   for (const pane of panes) {
    //     const contentChild = this.dockManager.querySelector(`[slot=${pane.contentId}]`);
    //     this.dockManager.removeChild(contentChild);
    //   }
    // } else {
    //   event.preventDefault();
    // }
  }

  private handlePaneScroll(event: CustomEvent<IgcPaneScrollEventArgs>) {
    this.log(event);

    // // check if the pane content is scrolled to bottom
    // let scrolledToBottom = false;
    // const contentEl = event.detail.contentElement;
    // if (contentEl.scrollHeight - contentEl.scrollTop - contentEl.clientHeight < 1)
    // {
    //   scrolledToBottom = true;
    // }
    // this.log("Scrolled to bottom: " + scrolledToBottom);
  }

  private handlePinnedToggle(event: CustomEvent<IgcPanePinnedEventArgs>) {
    this.log(event);
    // if (event.detail.sourcePane.type === IgcDockManagerPaneType.contentPane) {
    //   event.detail.panes = [event.detail.sourcePane];
    // }
    // event.preventDefault();
  }

  private handleActivePaneChanged(event: CustomEvent<IgcActivePaneEventArgs>) {
    this.log(event);
  }

  private handleDragStart(event: CustomEvent) {
    this.log(event);

    // event.preventDefault();
  }

  private handleDragOver(event: CustomEvent<IgcPaneDragOverEventArgs>) {
    const args = event.detail;
    this.log(args.action);

    if (args.action.type === IgcPaneDragActionType.dockPane) {
      if (args.action.dockingIndicator.position === IgcDockingIndicatorPosition.left) {
        // args.isValid = false;
      }
    } else if (args.action.type === IgcPaneDragActionType.floatPane) {
      // args.action.height = Math.min(args.action.height, 500);
      // args.isValid = false;
    } else if (args.action.type === IgcPaneDragActionType.moveFloatingPane) {
      // if (args.action.newLocation.y < 0) {
      //   args.isValid = false;
      // }
    }
  }

  private handleDragEnd(event: CustomEvent) {
    this.log(event);
  }

  private handleSplitterResizeStart(event: CustomEvent) {
    this.log(event, { ...event.detail });
    // event.preventDefault();
  }

  private handleSplitterResizeEnd(event: CustomEvent) {
    this.log(event, { ...event.detail });
  }

  private handleFloatingPaneResizeStart(event: CustomEvent<IgcFloatingPaneResizeEventArgs>) {
    this.log(event, { ...event.detail });
    // event.preventDefault();
  }

  private handleFloatingPaneResizeMove(event: CustomEvent<IgcFloatingPaneResizeMoveEventArgs>) {
    this.log(event, { ...event.detail });
  }

  private handleFloatingPaneResizeEnd(event: CustomEvent<IgcFloatingPaneResizeEventArgs>) {
    this.log(event, { ...event.detail });
  }

  private handleLayoutChange(event: CustomEvent<any>) {
    this.log(event);
  }

  // private getCustomResourceStrings(): IgcDockManagerResourceStrings {
  //   const customResourceStrings: IgcDockManagerResourceStrings = {
  //     close: '[Custom]Close',
  //     pin: '[Custom]Pin',
  //     unpin: '[Custom]Unpin',
  //     maximize: '[Custom]Maximize',
  //     minimize: '[Custom]Minimize',
  //     moreOptions: '[Custom]More options'
  //   };

  //   return customResourceStrings;
  // }

  private log(message?: any, ...optionalParams: any[]) {
    if (this.logEnabled) {
      console.log(message, optionalParams);
    }
  }

  render() {
    return (<Host>
      <div>
        <button onClick={() => this.saveLayout()}>Save Layout</button>
        <button onClick={() => this.loadLayout()}>Load Layout</button>
        <button onClick={() => this.setActivePane()}>Set Active Pane</button>
        <button onClick={() => this.addPane()}>Add Floating Pane</button>
        <button onClick={() => this.addTab()}>Add Tab Pane</button>
        <button onClick={() => this.disableContentPane()}>Disable Tab Pane</button>
        <button onClick={() => this.focusPane()}>Focus Pane</button>
        <button onClick={() => this.toggleProximityDock()}>Toggle Proximity Dock</button>
        <input
          id="hideOnClose"
          type="checkbox"
          style={{ marginLeft: '20px' }}
          ref={el => this.hideOnCloseCheckbox = el}
        />
        <label htmlFor="hideOnClose">Hide on close</label>
        <span style={{ marginLeft: '20px' }}>Hidden Panes:</span>
        <select
          style={{ width: '150px' }}
          ref={el => this.hiddenPanesSelect = el}
        >
          {this.hiddenPanes.map(p => {
            return (
              <option value={p.id}>{p.header}</option>
            );
          })}
        </select>
        <button onClick={() => this.showPane()}>Show Pane</button>
        <button onClick={() => this.showAllPanes()}>Show All Panes</button>
        <button onClick={() => this.toggleInBoundaries()}>Toggle In Boundaries</button>
      </div>
      <igc-dockmanager
        layout={this.layout}
        onPaneClose={this.handlePaneClose.bind(this)}
        onPaneScroll={this.handlePaneScroll.bind(this)}
        onPanePinnedToggle={this.handlePinnedToggle.bind(this)}
        onActivePaneChanged={this.handleActivePaneChanged.bind(this)}
        onPaneDragStart={this.handleDragStart.bind(this)}
        onPaneDragOver={this.handleDragOver.bind(this)}
        onPaneDragEnd={this.handleDragEnd.bind(this)}
        onSplitterResizeStart={this.handleSplitterResizeStart.bind(this)}
        onSplitterResizeEnd={this.handleSplitterResizeEnd.bind(this)}
        onFloatingPaneResizeStart={this.handleFloatingPaneResizeStart.bind(this)}
        onFloatingPaneResizeMove={this.handleFloatingPaneResizeMove.bind(this)}
        onFloatingPaneResizeEnd={this.handleFloatingPaneResizeEnd.bind(this)}
        onLayoutChange={this.handleLayoutChange.bind(this)}
        // resourceStrings={this.getCustomResourceStrings()}
        ref={el => this.dockManager = el}
        // allowFloatingPanesResize={false}
        showHeaderIconOnHover={'closeOnly'}
        // contextMenuPosition='start'
        // allowInnerDock={false}
        // proximityDock={true}
        // showPaneHeaders='onHoverOnly'
      >
        <div slot="header1">
          <span style={{ color: 'red' }}>Solution Explorer</span>
          <button>H</button>
        </div>
        <div slot="tabHeader1">
          <span style={{ color: 'orange' }}>Solution Explorer</span>
          <button>T</button>
        </div>
        <div slot="unpinnedHeader1">
          <span style={{ color: 'blue' }}>Solution Explorer</span>
          <button>U</button>
        </div>

        <button style={{ background: 'pink' }} slot="tabHeaderCloseButton">Y</button>
        {/* <button style={{ background: 'aqua' }} slot="paneHeaderCloseButton">X</button>
        <button style={{ background: 'pink' }} slot="tabHeaderCloseButton">Y</button>
        <button style={{ background: 'lightblue' }} slot="moreTabsButton">...</button>
        <button style={{ background: 'aqua' }} slot="maximizeButton">︿</button>
        <button style={{ background: 'mediumvioletred' }} slot="minimizeButton">﹀</button>
        <button style={{ background: 'lightgreen' }} slot="pinButton">P</button>
         <button style={{ background: 'red' }} slot="unpinButton">U</button>
        <button style={{ background: 'blue' }} slot="moreOptionsButton"><div><span>≣</span></div></button>
        <button style={{ background: 'green' }} slot="splitterHandle">S</button> */}

        <div slot="content1" style={{ width: '100%', height: '100%' }}>Content 1</div>
        <div slot="content2" style={{ width: '100%', height: '100%' }}>
          <button>Tests</button>
        </div>
        <div slot="content3" style={{ width: '100%', height: '100%' }}>Content 3</div>
        <div slot="content4">Content 4</div>
        <div slot="content5" style={{ width: '100%', height: '100%' }}>Content 5</div>
        <div slot="content6" style={{ width: '100%', height: '100%' }}>Content 6</div>
        <div slot="content7" style={{ width: '100%', height: '100%' }}>Content 7</div>
        <div slot="content8">Content 8</div>
        <div slot="content9" style={{ width: '100%', height: '100%' }}>Content 9</div>
        <div slot="content10" style={{ width: '100%', height: '100%' }}>
          <button>Test</button>
        </div>
        <div slot="content11" style={{ width: '100%', height: '100%' }}>
          <input />
        </div>
        <div slot="content12" style={{ width: '100%', height: '100%' }}>
          <p>Content 12 </p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
        </div>
        <div slot="content13" style={{ width: '100%', height: '100%' }}>Content 13</div>
        <div slot="content14" style={{ width: '100%', height: '100%' }}>Content 14</div>

        <div slot="toolboxHeader">
          <span>[U] Toolbox</span>
        </div>
        <div slot="teamExplorerHeader">
          <span>[U] Team Explorer</span>
        </div>

      </igc-dockmanager>
    </Host>);
  }
}
