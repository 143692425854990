:host {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  user-select: none;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 400;
  color: $pane-header-text;
  background: $pane-header-background;
  min-height: $pane-header-height;

  igc-icon-component svg {
    width: 17px;
    height: 17px;
  }
}

:host([part~='window']) {
  border-bottom: 1px solid $border-color;
  min-height: auto;
}

.header-text {
  @include ellipsis();
  flex: 1 1 100%;
  line-height: 2.3;
}

.header-actions {
  display: flex;

  igc-button-component + igc-button-component {
    margin-left: 4px;
  }
}

:host([part~='active']) {
  box-shadow: inset 0 -2px 0 0 $active-color;
  color: $active-color;
}

:host([part~='disabled']) {
	pointer-events: none;
	color: $disabled-color;
}

